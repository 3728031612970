import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Phone from "@material-ui/icons/PhoneInTalk";
import Person from "@material-ui/icons/PersonOutlineOutlined";

function MobileHeader() {
  const [collection, setCollection] = useState([]);
  const [menuSetting, setMenuSetting] = useState([]);
  const tokenNo = localStorage.getItem("tokenNo");

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        fetch(process.env.REACT_APP_API_URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            request: "getCollectionsList",
          }),
        }).then((response) => {
          response.json().then((result) => {
            setCollection(result.data);
          });
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    fetchCollection();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        fetch(process.env.REACT_APP_API_URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            request: "getSettings",
          }),
        }).then((response) => {
          response.json().then((result) => {
            setMenuSetting(result.data.filters.categories);
          });
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    fetchSettings();
  }, []);

  return (
    <>
      <div className="site-menu-mob" id="siteMenu">
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Collections
              <span className="material-icons-outlined"> navigate_next </span>
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              {collection &&
                collection.map((items) => {
                  return (
                    <Link
                      className="dropdown-item"
                      key={items.Id}
                      to={`/collection/${items.Slug}`}
                    >
                      {items.Name}
                    </Link>
                  );
                })}
            </div>
          </li>
          <li className="nav-item dropdown">
            <a
              href="#"
              id="menu"
              data-toggle="dropdown"
              className="nav-link dropdown-toggle"
              data-display="static"
            >
              Jewelry
              <span className="material-icons-outlined"> navigate_next </span>
            </a>
            <ul className="dropdown-menu">
              <li className="dropdown-item dropdown-submenu">
                <a href="#" data-toggle="dropdown" className="dropdown-toggle">
                  Shop by Category
                  <span className="material-icons-outlined">navigate_next</span>
                </a>
                <ul className="dropdown-menu">
                  <Link className="dropdown-item" to={"/products"}>
                    All
                  </Link>
                  {menuSetting &&
                    menuSetting.map((items) => {
                      return (
                        <Link
                          className="dropdown-item show"
                          key={items.value}
                          to={"/products"}
                          state={{
                            categoryValue: items.value,
                          }}
                        >
                          {items.label}
                        </Link>
                      );
                    })}
                </ul>
              </li>
              <li className="dropdown-item dropdown-submenu">
                <a href="#" data-toggle="dropdown" className="dropdown-toggle">
                  Gender
                  <span className="material-icons-outlined">navigate_next</span>
                </a>
                <ul className="dropdown-menu">
                  {/* <a className="dropdown-item" href="#">
                    Women
                  </a>
                  <a className="dropdown-item" href="#">
                    Men
                  </a> */}
                  <Link to="/gender/Female" className="dropdown-item">
                    Women
                  </Link>
                  <Link to="/gender/Male" className="dropdown-item">
                    Men
                  </Link>
                </ul>
              </li>
              <li className="dropdown-item dropdown-submenu">
                <a href="#" data-toggle="dropdown" className="dropdown-toggle">
                  Metal Color
                  <span className="material-icons-outlined">navigate_next</span>
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/color/Yellow" className="dropdown-item">
                      Yellow
                    </Link>
                  </li>
                  <li>
                    <Link to="/color/White" className="dropdown-item">
                      White
                    </Link>
                  </li>
                  <li>
                    <Link to="/color/Rose" className="dropdown-item">
                      Rose
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown-item dropdown-submenu">
                <a href="#" data-toggle="dropdown" className="dropdown-toggle">
                  Price Band
                  <span className="material-icons-outlined">navigate_next</span>
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      to="/price-range/4000-20000"
                      className="dropdown-item"
                    >
                      ₹4K - ₹20K
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/price-range/20000-40000"
                      className="dropdown-item"
                    >
                      ₹20K - ₹40K
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/price-range/40000-60000"
                      className="dropdown-item"
                    >
                      ₹40K - ₹60
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/price-range/60000-80000"
                      className="dropdown-item"
                    >
                      ₹60K - ₹80K
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/price-range/80000-100000"
                      className="dropdown-item"
                    >
                      ₹80K - ₹1L
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              About
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              Blog
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="contact-us.html">
              Contact
            </a>
          </li>
        </ul>
        <div className="phone-no-mob menu-list-mob">
          <a href="tel:1800-270-2636" className="phn-link">
            {/* <img src="assets/images/phone.png" alt="phone" /> */}
            <Phone />
            1800-270-2636
          </a>
        </div>
        <div className="user-icon-mob menu-list-mob">
          {tokenNo ? (
            <Link to="/dashboard-personal-info">
              <Person />
              My Account
            </Link>
          ) : (
            <Link to="/login">
              <Person />
              My Account
            </Link>
          )}
        </div>
        <div className="menu-list-mob btn-wrap-mob">
          <Link to="/signup" className="org-btn-sm">
            Sign Up
          </Link>
          <Link to="/login" className="orgbdr-btn-sm">
            Log In
          </Link>
        </div>
      </div>
    </>
  );
}

export default MobileHeader;
