import React, { useState, useEffect } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Newsletter from './Newsletter';
import { Link } from 'react-router-dom';

function ProductBestSeller() {

    const [getFeatured, setGetFeatured] = useState([]);
    const [getFeaturedLength, setGetFeaturedLength] = useState(null);
    const [getNoFeatured, setNoFeatured] = useState([]);
    const [productLoader, setProductLoader] = useState(false);
    const [showSideCart, setShowSideCart] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [featuredId, setFeaturedId] = useState(null);
    const [featuredProductOverlay, setFeaturedProductOverlay] = useState(null);
    const [loadMore, setLoadMore] = useState(5);

    useEffect(() => {
        fetch(
            process.env.REACT_APP_API_URL,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({
                    "token": "N2E5NTE5YTkzMTI0OGU5NzM2ZTlmZDQ4ZjI2ODZkZWT=",
                    "request": "BestSellers",
                    "featured": "Yes"
                })

            }).then((response) => {
                response.json().then(result => {
                    //console.log(result);
                    setGetFeatured(result.data);
                    setGetFeaturedLength(result.data.length)
                })
            })

    }, [])


    useEffect(() => {
        fetch(
            process.env.REACT_APP_API_URL,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({
                    "token": "N2E5NTE5YTkzMTI0OGU5NzM2ZTlmZDQ4ZjI2ODZkZWT=",
                    "request": "BestSellers",
                    "featured": "No"
                })

            }).then((response) => {
                response.json().then(res => {
                    //console.log(res);
                    setNoFeatured(res.data);
                    setProductLoader(true);
                })
            })

    }, [])



    //console.log('featured', getFeaturedLength);
    //console.log('No featured', getNoFeatured);
    //console.log('featured', getFeatured[3]);

    const chunkSize = 4;
    const groups = getNoFeatured.map((e, i) => {
        return i % chunkSize === 0 ? getNoFeatured.slice(i, i + chunkSize) : null;
    }).filter(e => { return e; });
    //console.log('No featured chunk', groups)

    var li_count = 1;
    var j_mod = 0;
    var f_count = getFeaturedLength;


    const sideOpen = (id) => {
        //alert(id);
        const styleApply = {
            visibility: "visible",
            right: "0",
        }
        const styleOverlay = {
            visibility: "visible"
        }

        setShowSideCart(styleApply);
        setShowOverlay(styleOverlay);

        setFeaturedId(id);

        fetch(
            process.env.REACT_APP_API_URL,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({
                    "token": "N2E5NTE5YTkzMTI0OGU5NzM2ZTlmZDQ4ZjI2ODZkZWT=",
                    "request": "getRelatedProduct",
                    "pid": id
                })

            }).then((response) => {
                response.json().then(res => {
                    //console.log(res); 
                    setFeaturedProductOverlay(res.data);
                })
            })


    }

    //console.log(featuredProductOverlay);

    const sideClose = () => {
        const styleRemove = {
            visibility: "hidden",
            right: "-100%"
        }
        const styleOverlayRemove = {
            visibility: "hidden"
        }
        setShowSideCart(styleRemove);
        setShowOverlay(styleOverlayRemove);
    }


    const handleLoadMore = () => {
        if (loadMore >= groups.length) return
        setLoadMore(() => loadMore + 5)
    }

    return (
        <>
            <div className="overlay" style={{ ...showOverlay }}></div>

            <Header />

            <section className="product-list-wrap ptb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrum-wrap">
                                <ul>
                                    <li>
                                        <Link to="/">HOME</Link>
                                    </li>
                                    <li>
                                        <Link to="/">BEST SELLERS</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="product-list">
                            <div className="col-md-12">
                                <div className="filter-wrap">
                                    <div className="form-group">

                                        <select className="form-control">
                                            <option>Category</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <span className="material-icons-outlined">
                                            arrow_drop_down
                                        </span>
                                    </div>
                                    <div className="form-group">

                                        <select className="form-control">
                                            <option>Metal Color</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <span className="material-icons-outlined">
                                            arrow_drop_down
                                        </span>
                                    </div>
                                    <div className="form-group">

                                        <select className="form-control">
                                            <option>Weight</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <span className="material-icons-outlined">
                                            arrow_drop_down
                                        </span>
                                    </div>
                                    <div className="form-group">

                                        <select className="form-control">
                                            <option>Price</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <span className="material-icons-outlined">
                                            arrow_drop_down
                                        </span>
                                    </div>
                                    <div className="form-group">
                                        <label>Sort by:</label>
                                        <select className="form-control">
                                            <option>Recommended</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <span className="material-icons-outlined">
                                            arrow_drop_down
                                        </span>
                                    </div>
                                </div>
                            </div>


                            {!productLoader ? (
                                <div className="preloaderBg" id="preloader">
                                    <div className="preloader"></div>
                                    <div className="preloader2"></div>
                                </div>
                            ) : ''}

                            <div className="col-md-12">
                                <div className="product-no-wrap">
                                    <p>
                                        Showing 1-20 of 500
                                    </p>
                                </div>
                            </div>

                            <ul>

                                {getNoFeatured ? groups.map((item, i) => {
                                    //console.log(item);

                                    if (i + 1 > loadMore) return

                                    li_count++;

                                    if (li_count % 3) {
                                        return (
                                            <li key={i}>
                                                {item.map((prdt, k) => {
                                                    return (
                                                        <div className="col-md-3 product-li" key={k}>
                                                            <a href="#" className="productlist-img">
                                                                <img src={prdt.Image} alt="img" className="img-fluid" />
                                                            </a>
                                                            <div className="imgslider">
                                                                <div id={`imgSliderCarousal-${prdt.Id}`} className="carousel slide imgSliderCarousal" data-ride="carousel">
                                                                    <Link to="/login" className="whislist-img">
                                                                        <span className="material-icons-outlined wishlist-grey">
                                                                            favorite
                                                                        </span>
                                                                    </Link>
                                                                    <div className="carousel-inner">
                                                                        {prdt.ProductGallery.length > 0 ? prdt.ProductGallery.map((galImg, g) => {
                                                                            if (galImg.media_type === "image") {
                                                                                return (
                                                                                    <div className={`carousel-item ${g === 0 ? 'active' : ''}`} key={g} >
                                                                                        <img className="d-block w-100" src={galImg.img_thumb} alt="" />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }) :

                                                                            <div className={`carousel-item active`}>
                                                                                <img className="d-block w-100" src={prdt.Image} alt="" />
                                                                            </div>

                                                                        }
                                                                    </div>
                                                                    <ol className="carousel-indicators">
                                                                        {prdt.ProductGallery.map((galImg, g) => {
                                                                            if (galImg.media_type === "image") {
                                                                                return (
                                                                                    <li data-target={`#imgSliderCarousal-${prdt.Id}`} data-slide-to={g} className={g === 0 ? 'active' : ''} key={g}></li>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </ol>
                                                                    <div className="imgslider-content">
                                                                        <p><Link to={`/product/${prdt.Id}`} className='product-link'>{prdt.Name}</Link></p>
                                                                        <div className="d-flex align-items-center justify-content-between mt-5">
                                                                            <p className="price">₹{prdt.Price}</p>
                                                                            <Link to={`/product/${prdt.Id}`}>
                                                                                <img src="../assets/images/add-cart.svg" alt="" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </li>
                                        )
                                    } else if (f_count > 0) {

                                        j_mod++;
                                        f_count--;

                                        if (j_mod % 2) {

                                            let idVal = getFeatured[f_count].Id;

                                            return (
                                                <li key={i}>
                                                    <div className="col-md-6">
                                                        <a href="#" className="productlist-img">
                                                            <img src={getFeatured[f_count].Image} alt="img" className="img-fluid" />
                                                            <button type='button' className="add-cart-btn" onClick={() => sideOpen(idVal)}>
                                                                <img src="http://youlry.bizdevtechnologies.in/html/assets/images/add-cart-white.svg" alt="img" className="img-fluid" />
                                                                <p>&nbsp; Shop the Image </p>
                                                            </button>
                                                        </a>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            {item.map((prdt, k) => {
                                                                return (
                                                                    <div className="col-md-6 product-li" key={k}>
                                                                        <a href="#" className="productlist-img">
                                                                            <img src={prdt.Image} alt="img" className="img-fluid" />
                                                                        </a>
                                                                        <div className="imgslider">
                                                                            <div id={`imgSliderCarousal-${prdt.Id}`} className="carousel slide imgSliderCarousal" data-ride="carousel">
                                                                                <Link to="/login" className="whislist-img">
                                                                                    <span className="material-icons-outlined wishlist-grey">
                                                                                        favorite
                                                                                    </span>
                                                                                </Link>
                                                                                <div className="carousel-inner">
                                                                                    {prdt.ProductGallery.length > 0 ? prdt.ProductGallery.map((galImg, g) => {
                                                                                        if (galImg.media_type === "image") {
                                                                                            return (
                                                                                                <div className={`carousel-item ${g === 0 ? 'active' : ''}`} key={g} >
                                                                                                    <img className="d-block w-100" src={galImg.img_thumb} alt="" />
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    }) :

                                                                                        <div className={`carousel-item active`}>
                                                                                            <img className="d-block w-100" src={prdt.Image} alt="" />
                                                                                        </div>

                                                                                    }
                                                                                </div>
                                                                                <ol className="carousel-indicators">
                                                                                    {prdt.ProductGallery.map((galImg, g) => {
                                                                                        if (galImg.media_type === "image") {
                                                                                            return (
                                                                                                <li data-target={`#imgSliderCarousal-${prdt.Id}`} data-slide-to={g} className={g === 0 ? 'active' : ''} key={g}></li>
                                                                                            )
                                                                                        }
                                                                                    })}
                                                                                </ol>
                                                                                <div className="imgslider-content">
                                                                                    <p><Link to={`/product/${prdt.Id}`} className='product-link'>{prdt.Name}</Link></p>
                                                                                    <div className="d-flex align-items-center justify-content-between mt-5">
                                                                                        <p className="price">₹{prdt.Price}</p>
                                                                                        <Link to={`/product/${prdt.Id}`}>
                                                                                            <img src="../assets/images/add-cart.svg" alt="" />
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </li>
                                            )

                                        } else {

                                            let idVal2 = getFeatured[f_count].Id;

                                            return (
                                                <li key={i}>
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            {item.map((prdt, k) => {
                                                                return (
                                                                    <div className="col-md-6 product-li" key={k}>
                                                                        <a href="#" className="productlist-img">
                                                                            <img src={prdt.Image} alt="img" className="img-fluid" />
                                                                        </a>
                                                                        <div className="imgslider">
                                                                            <div id={`imgSliderCarousal-${prdt.Id}`} className="carousel slide imgSliderCarousal" data-ride="carousel">
                                                                                <Link to="/login" className="whislist-img">
                                                                                    <span className="material-icons-outlined wishlist-grey">
                                                                                        favorite
                                                                                    </span>
                                                                                </Link>
                                                                                <div className="carousel-inner">
                                                                                    {prdt.ProductGallery.length > 0 ? prdt.ProductGallery.map((galImg, g) => {
                                                                                        if (galImg.media_type === "image") {
                                                                                            return (
                                                                                                <div className={`carousel-item ${g === "0" ? 'active' : ''}`} key={g} >
                                                                                                    <img className="d-block w-100" src={galImg.img_thumb} alt="" />
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    }) :

                                                                                        <div className={`carousel-item active`}>
                                                                                            <img className="d-block w-100" src={prdt.Image} alt="" />
                                                                                        </div>

                                                                                    }
                                                                                </div>
                                                                                <ol className="carousel-indicators">
                                                                                    {prdt.ProductGallery.map((galImg, g) => {
                                                                                        if (galImg.media_type === "image") {
                                                                                            return (
                                                                                                <li data-target={`#imgSliderCarousal-${prdt.Id}`} data-slide-to={g} className={g === 0 ? 'active' : ''} key={g}></li>
                                                                                            )
                                                                                        }
                                                                                    })}
                                                                                </ol>
                                                                                <div className="imgslider-content">
                                                                                    <p><Link to={`/product/${prdt.Id}`} className='product-link'>{prdt.Name}</Link></p>
                                                                                    <div className="d-flex align-items-center justify-content-between mt-5">
                                                                                        <p className="price">₹{prdt.Price}</p>
                                                                                        <Link to={`/product/${prdt.Id}`}>
                                                                                            <img src="../assets/images/add-cart.svg" alt="" />
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <a href="#" className="productlist-img">
                                                            <img src={getFeatured[f_count].Image} alt="img" className="img-fluid" />
                                                            <button className="add-cart-btn" onClick={() => sideOpen(idVal2)}>
                                                                <img src="http://youlry.bizdevtechnologies.in/html/assets/images/add-cart-white.svg" alt="img" className="img-fluid" />
                                                                <p>&nbsp;Shop the Image</p>
                                                            </button>
                                                        </a>
                                                    </div>
                                                </li>
                                            )
                                        }
                                    } else {
                                        return (
                                            <li key={i}>
                                                {item.map((prdt, k) => {
                                                    return (
                                                        <div className="col-md-3 product-li" key={k}>
                                                            <a href="#" className="productlist-img">
                                                                <img src={prdt.Image} alt="img" className="img-fluid" />
                                                            </a>
                                                            <div className="imgslider">
                                                                <div id={`imgSliderCarousal-${prdt.Id}`} className="carousel slide imgSliderCarousal" data-ride="carousel">
                                                                    <Link to="/login" className="whislist-img">
                                                                        <span className="material-icons-outlined wishlist-grey">
                                                                            favorite
                                                                        </span>
                                                                    </Link>
                                                                    <div className="carousel-inner">
                                                                        {prdt.ProductGallery.length > 0 ? prdt.ProductGallery.map((galImg, g) => {
                                                                            if (galImg.media_type === "image") {
                                                                                return (
                                                                                    <div className={`carousel-item ${g === "0" ? 'active' : ''}`} key={g} >
                                                                                        <img className="d-block w-100" src={galImg.img_thumb} alt="" />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }) :

                                                                            <div className={`carousel-item active`}>
                                                                                <img className="d-block w-100" src={prdt.Image} alt="" />
                                                                            </div>

                                                                        }
                                                                    </div>
                                                                    <ol className="carousel-indicators">
                                                                        {prdt.ProductGallery.map((galImg, g) => {
                                                                            if (galImg.media_type === "image") {
                                                                                return (
                                                                                    <li data-target={`#imgSliderCarousal-${prdt.Id}`} data-slide-to={g} className={g === 0 ? 'active' : ''} key={g}></li>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </ol>
                                                                    <div className="imgslider-content">
                                                                        <p><Link to={`/product/${prdt.Id}`} className='product-link'>{prdt.Name}</Link></p>
                                                                        <div className="d-flex align-items-center justify-content-between mt-5">
                                                                            <p className="price">₹{prdt.Price}</p>
                                                                            <Link to={`/product/${prdt.Id}`}>
                                                                                <img src="../assets/images/add-cart.svg" alt="" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </li>
                                        )
                                    }

                                }) :


                                    <div className="spinner">
                                        <div className="spinner spinner2">
                                            <div className="spinner spinner3"></div>
                                        </div>
                                    </div>
                                }

                            </ul>
                            <div className="btn-wrap col-auto pt-5">
                                <button type="button" onClick={handleLoadMore} className="btn white-btn">VIEW MORE</button>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Newsletter />
            <Footer />

            <div className="side-cart-wrap" style={{ ...showSideCart ? showSideCart : '' }}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="close-btn-wrap">
                            <button className="close-btn" onClick={sideClose}>
                                <span className="material-icons-round">
                                    close
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <h2 className="heading-black pb-3">
                            Featured Products
                        </h2>
                    </div>

                    {featuredProductOverlay ? featuredProductOverlay.map((prdtItems, i) => (

                        <div className="col-6 col-md-6" key={i}>
                            <div className="item">
                                <div className="item-img">
                                    <figure>
                                        <img src={prdtItems.Image} alt={prdtItems.Name} />
                                    </figure>
                                    <Link to="/login" className="whislist-img">
                                        <span className="material-icons-outlined wishlist-grey">
                                            favorite
                                        </span>
                                    </Link>
                                </div>
                                <div className="slider-content">
                                    <h2 className="item-title">
                                        <Link to={`/product/${prdtItems.Id}`} className='product-link'>{prdtItems.Name}</Link>
                                    </h2>
                                    <h3 className="item-price">
                                        ₹{prdtItems.Price}
                                    </h3>
                                </div>
                            </div>
                        </div>

                    )) : ''}
                </div>
            </div>
        </>
    )
}

export default ProductBestSeller