import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Newsletter from "./Newsletter";
import { Link, useNavigate, Navigate } from "react-router-dom";
import "../../src/pages/paytm-button/pay-tm.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Delivery(props) {
  const tokenNo = localStorage.getItem("tokenNo");
  const CartId = localStorage.getItem("CartId");
  const CouponCode = localStorage.getItem("coupon");
  const CartAmt = localStorage.getItem("cartAmt");

  const estimated = localStorage.getItem("estimated");
  const pin_code = localStorage.getItem("pin_code");

  const navigate = useNavigate();

  const shippingInitialValues = {
    email_address: "",
    mobile_no: "",
    whatsapp_no: "",
    shipping_full_name: "",
    shipping_apart_house_no: "",
    shipping_area_name: "",
    shipping_state: "",
    shipping_city: "",
    shipping_pincode: "",
    shipping_landmark: "",
  };

  const billingInitialValues = {
    billing_full_name: "",
    billing_apart_house_no: "",
    billing_area_name: "",
    billing_state: "",
    billing_city: "",
    billing_pincode: "",
    billing_landmark: "",
  };

  const [shippingFormValues, setShippingFormValues] = useState(
    shippingInitialValues
  );
  const [billingFormValues, setBillingFormValues] =
    useState(billingInitialValues);
  const [shippingFormErrors, setShippingFormErrors] = useState({});
  const [billingFormErrors, setBillingFormErrors] = useState({});
  const [giftMessage, setGiftMessage] = useState(null);
  const [showBilling, setShowBilling] = useState(false);
  const [cartInfo, setCartInfo] = useState([]);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [isSubmit, setISSubmit] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [latestUpdate, setLatestUpdate] = useState(null);
  const [youlryStory, setYoulryStory] = useState(null);
  const [sameAsShipping, setSameAsShipping] = useState(true);
  const [userInfo, setUserInfo] = useState([]);
  const [userAddress, setUserAddress] = useState([]);
  const [addressValue, setAddressValue] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigateToLogin = () => {
    navigate("/login", { state: { component: window.location.pathname } });
  };

  const radioHandler = () => {
    setShowBilling(true);
    const ele = document.querySelector(
      'input[name="address_selector"]:checked'
    ).value;
    console.log(ele);
    if (ele === "diff-shipping") {
      setShowBilling(true);
    } else {
      setShowBilling(false);
    }
  };

  const handleLoggedIn = () => {
    if (buttonStatus == false && tokenNo == null) {
      handleShow();
    } else {
    }
  };

  useEffect(() => {
    handleLoggedIn();
  }, []);

  const handleChange = (e) => {
    console.log(e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    setShippingFormValues({ ...shippingFormValues, [name]: value });
  };

  const billingHandleChange = (e) => {
    console.log(e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    setBillingFormValues({ ...billingFormValues, [name]: value });
  };

  const shippingValidate = (values) => {
    const errors = {};
    const regex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

    if (!values.email_address) {
      errors.email_address = "This Field is Required";
    } else if (!regex.test(values.email_address)) {
      errors.email_address = "Email Format is Wrong";
    }

    if (!values.mobile_no) {
      errors.mobile_no = "This Field is Required";
    }

    if (!values.shipping_full_name) {
      errors.shipping_full_name = "This Field is Required";
    }
    if (!values.shipping_apart_house_no) {
      errors.shipping_apart_house_no = "This Field is Required";
    }
    if (!values.shipping_area_name) {
      errors.shipping_area_name = "This Field is Required";
    }
    if (!values.shipping_state) {
      errors.shipping_state = "This Field is Required";
    }
    if (!values.shipping_city) {
      errors.shipping_city = "This Field is Required";
    }
    if (!values.shipping_pincode) {
      errors.shipping_pincode = "This Field is Required";
    }
    return errors;
  };

  const billingValidate = (values) => {
    const errors = {};
    const regex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

    if (!values.billing_full_name) {
      errors.billing_full_name = "This Field is Required";
    }
    if (!values.billing_apart_house_no) {
      errors.billing_apart_house_no = "This Field is Required";
    }
    if (!values.billing_area_name) {
      errors.billing_area_name = "This Field is Required";
    }
    if (!values.billing_state) {
      errors.billing_state = "This Field is Required";
    }
    if (!values.billing_city) {
      errors.billing_city = "This Field is Required";
    }
    if (!values.billing_pincode) {
      errors.billing_pincode = "This Field is Required";
    }
    return errors;
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "GetCartDetails",
        token: tokenNo ? tokenNo : "",
        cart_id: CartId,
      }),
    }).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        if (result.success === true) {
          setCartInfo(result.cart_data);
          setButtonStatus(true);
        } else {
          navigate("/login", {
            state: { component: window.location.pathname },
          });
        }
      });
    });
  }, [CartId]);

  const cartTotal = cartInfo.reduce(
    (total, currentItem) =>
      (total =
        parseFloat(total) +
        parseFloat(currentItem.price * currentItem.quantity)),
    0
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setShippingFormErrors(shippingValidate(shippingFormValues));

    const radioInput = document.querySelector(
      'input[name="address_selector"]:checked'
    ).value;

    // console.log();
    // console.log();

    if (radioInput === "diff-shipping") {
      setBillingFormErrors(billingValidate(billingFormValues));
      setSameAsShipping(flase);
    }

    if (radioInput === "same-shipping") {
      var billingInputElements = document.querySelectorAll(
        "#shipping-address input"
      );
      var deliveryInputElements = document.querySelectorAll(
        "#billing_address input"
      );
      setSameAsShipping(true);
    }

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "checkout",
        token: tokenNo ? tokenNo : "",
        order_data: {
          order_from: "youlry_website",
          delivery_address_id: "",
          shipping_address: {
            address:
              shippingFormValues.shipping_full_name +
              " " +
              shippingFormValues.shipping_apart_house_no +
              " " +
              shippingFormValues.shipping_area_name,
            land_mark: shippingFormValues.shipping_landmark,
            city: shippingFormValues.shipping_city,
            state: shippingFormValues.shipping_state,
            pin: shippingFormValues.shipping_pincode,
          },
          billing_address: {
            same_as_shipping: sameAsShipping,
            address:
              billingFormValues.billing_full_name +
              " " +
              billingFormValues.billing_apart_house_no +
              " " +
              billingFormValues.billing_area_name,
            land_mark: billingFormValues.billing_landmark,
            city: billingFormValues.billing_city,
            state: billingFormValues.billing_state,
            pin: billingFormValues.billing_pincode,
          },
          customer_comment: giftMessage,
          contact_no: shippingFormValues.mobile_no,
          email: shippingFormValues.email_address,
          cart_total: cartTotal,
          total_amount: cartTotal,
          payment_mode: "Online",
          cart_session_id: CartId,
          letest_update: latestUpdate,
          youlry_story: youlryStory,
          coupon_code: CouponCode,
        },
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((resp) => {
      resp.json().then((res) => {
        // console.log('checkout response ', res);
        if (res.success === true) {
          localStorage.setItem("orderId", res.order_no);
          //localStorage.setItem('cartAmt', cartTotal);
          setISSubmit(true);
        } else {
          navigate("/login", {
            state: { component: window.location.pathname },
          });
        }
      });
    });
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        token: tokenNo,
        request: "userAddressList",
      }),
    }).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          setUserAddress(result.data);
        }
      });
    });
  }, [tokenNo]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        token: tokenNo,
        request: "getUserInfo",
      }),
    }).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          setUserInfo(result.data);
          shippingFormValues.email_address = result.data.user_email ?? "";
          shippingFormValues.mobile_no = result.data.primary_contact;
          shippingFormValues.shipping_full_name = result.data.display_name;
          shippingFormValues.shipping_state = result.data.state;
          shippingFormValues.shipping_city = result.data.city;
          shippingFormValues.shipping_apart_house_no = result.data.address;
          shippingFormValues.shipping_pincode = result.data.zip;
        }
      });
    });
  }, [tokenNo]);

  if (isSubmit) {
    return <Navigate to="/payment" />;
  }

  const loginModal = () => {
    setLoginModalOpen(true);
  };

  const loginModalRemove = () => {
    setLoginModalOpen(false);
  };

  if (!CartId) {
    return <Navigate to="/cart" />;
  }

  // useEffect(() => {
  //   console.log(userAddress);
  //   console.log(addressValue);
  // }, [addressValue]);

  return (
    <>
      <Header />

      <div id="cart_wrap" className="deliver">
        <div className="stepper-custom">
          <ul>
            <li className="checked">
              <p>
                <span className="material-icons">check_circle</span>
                Cart
              </p>
            </li>
            <li className="active">
              <p>
                <span className="icon">
                  <i className="fa fa-circle-thin" aria-hidden="true"></i>
                </span>
                Delivery
              </p>
            </li>
            <li>
              <p>
                <span className="icon">
                  <i className="fa fa-circle-thin" aria-hidden="true"></i>
                </span>
                Payment
              </p>
            </li>
          </ul>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3>Address Details</h3>
                  <p>where you want us to deliver</p>

                  {/* <div>
                    {userAddress.map((option, i) => (
                      <div className="w-100 form-group" key={i}>
                        <input
                          type="radio"
                          name="address"
                          checked={i === i}
                          value={option.id}
                          onChange={(e) => setAddressValue(e.target.value)}
                        />
                        &nbsp;
                        <label>{option.address_type} &nbsp; Address</label>
                      </div>
                    ))}
                  </div> */}
                </div>
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="w-100 form-group">
                      <input
                        className="w-100 form-control"
                        type="email"
                        name="email_address"
                        id=""
                        placeholder="Email Addresss"
                        onChange={handleChange}
                        defaultValue={userInfo.user_email ?? ""}
                      />
                      <span className="input_errors">
                        {shippingFormErrors.email_address}
                      </span>
                    </div>
                    <div className="w-100 form-group">
                      <input
                        className="w-100 form-control"
                        type="number"
                        name="mobile_no"
                        id=""
                        placeholder="Mobile Number"
                        onChange={handleChange}
                        defaultValue={userInfo.primary_contact ?? ""}
                      />
                      <span className="input_errors">
                        {shippingFormErrors.mobile_no}
                      </span>
                    </div>
                  </div>
                  <div className="whatsapp-number w-100 form-group">
                    <label htmlFor="">
                      Would you like to recieve notification on Whatsapp?
                    </label>
                    <input
                      className="w-100 form-control"
                      type="number"
                      name="whatsapp_no"
                      id=""
                      placeholder="Whatsapp Number (Optional)"
                      onChange={handleChange}
                    />
                  </div>
                  <div id="shipping-address" className="shipping-address">
                    <h3>Shipping Address</h3>
                    <div id="shipAddress">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="shipping_full_name"
                          placeholder="Full Name"
                          onChange={handleChange}
                          defaultValue={userInfo.display_name ?? ""}
                        />
                        <span className="input_errors">
                          {shippingFormErrors.shipping_full_name}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100 form-group">
                          <input
                            className="w-100 form-control"
                            type="text"
                            name="shipping_apart_house_no"
                            id=""
                            placeholder="Appartment/house/Flat No."
                            onChange={handleChange}
                            defaultValue={userInfo.address ?? ""}
                          />
                          <span className="input_errors">
                            {shippingFormErrors.shipping_apart_house_no}
                          </span>
                        </div>
                        <div className="w-100 form-group">
                          <input
                            className="w-100 form-control"
                            type="text"
                            name="shipping_area_name"
                            id=""
                            placeholder="Street/Colony/Area Name"
                            onChange={handleChange}
                          />
                          <span className="input_errors">
                            {shippingFormErrors.shipping_area_name}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100 form-group">
                          <input
                            className="w-100 form-control"
                            type="text"
                            name="shipping_state"
                            id=""
                            placeholder="State"
                            onChange={handleChange}
                            defaultValue={userInfo.state ?? ""}
                          />
                          <span className="input_errors">
                            {shippingFormErrors.shipping_state}
                          </span>
                        </div>
                        <div className="w-100 form-group">
                          <input
                            className="w-100 form-control"
                            type="text"
                            name="shipping_city"
                            id=""
                            placeholder="City"
                            onChange={handleChange}
                            defaultValue={userInfo.city ?? ""}
                          />
                          <span className="input_errors">
                            {shippingFormErrors.shipping_city}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100 form-group">
                          <input
                            className="w-100 form-control"
                            type="number"
                            name="shipping_pincode"
                            id=""
                            placeholder="Pincode"
                            onChange={handleChange}
                            defaultValue={userInfo.zip ?? ""}
                          />
                          <span className="input_errors">
                            {shippingFormErrors.shipping_pincode}
                          </span>
                        </div>
                        <div className="w-100 form-group">
                          <input
                            className="w-100 form-control"
                            type="text"
                            name="shipping_landmark"
                            id=""
                            placeholder=" Landmark (Optional)"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="billing-address">
                    <h3>Billing Address</h3>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="w-100 d-flex align-items-center form-group">
                        <input
                          className="form-control"
                          type="radio"
                          name="address_selector"
                          id="ship-address"
                          value="same-shipping"
                          onClick={() => radioHandler()}
                          defaultChecked
                        />
                        <label htmlFor="ship-address"></label>
                        <span>Same as shipping address</span>
                      </div>
                      <div className="w-100 d-flex align-items-center form-group">
                        <input
                          className="form-control"
                          type="radio"
                          name="address_selector"
                          id="bill-address"
                          value="diff-shipping"
                          onClick={() => radioHandler()}
                        />
                        <label htmlFor="bill-address"></label>
                        <span>Use a different addresss</span>
                      </div>
                    </div>
                  </div>

                  {showBilling && (
                    <div id="billing_address">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="billing_full_name"
                          placeholder="Full Name"
                          onChange={billingHandleChange}
                        />
                        <span className="input_errors">
                          {billingFormErrors.billing_full_name}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100 form-group">
                          <input
                            className="w-100 form-control"
                            type="text"
                            name="billing_apart_house_no"
                            id=""
                            placeholder="Appartment/house/Flat No."
                            onChange={billingHandleChange}
                          />
                          <span className="input_errors">
                            {billingFormErrors.billing_apart_house_no}
                          </span>
                        </div>
                        <div className="w-100 form-group">
                          <input
                            className="w-100 form-control"
                            type="text"
                            name="billing_area_name"
                            id=""
                            placeholder="Street/Colony/Area Name"
                            onChange={billingHandleChange}
                          />
                          <span className="input_errors">
                            {billingFormErrors.billing_area_name}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100 form-group">
                          <input
                            className="w-100 form-control"
                            type="text"
                            name="billing_state"
                            id=""
                            placeholder="State"
                            onChange={billingHandleChange}
                          />
                          <span className="input_errors">
                            {billingFormErrors.billing_state}
                          </span>
                        </div>
                        <div className="w-100 form-group">
                          <input
                            className="w-100 form-control"
                            type="text"
                            name="billing_city"
                            id=""
                            placeholder="City"
                            onChange={billingHandleChange}
                          />
                          <span className="input_errors">
                            {billingFormErrors.billing_city}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100 form-group">
                          <input
                            className="w-100 form-control"
                            type="number"
                            name="billing_pincode"
                            id=""
                            placeholder="Pincode"
                            onChange={billingHandleChange}
                          />
                          <span className="input_errors">
                            {billingFormErrors.billing_pincode}
                          </span>
                        </div>
                        <div className="w-100 form-group">
                          <input
                            className="w-100 form-control"
                            type="text"
                            name="billing_landmark"
                            id=""
                            placeholder=" Landmark (Optional)"
                            onChange={billingHandleChange}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-12">
              <div className="card">
                <div className="cart-summary">
                  <h3>CART SUMMARY</h3>
                  <div className="cart-summary-prdct-list">
                    {cartInfo.length > 0 ? (
                      <ul>
                        {cartInfo.map((cartItem, i) => (
                          <li className="d-flex" key={i}>
                            <Link to={`/product/${cartItem.pdt_id}`}>
                              <img
                                style={{ width: "100%" }}
                                className="img-fluid"
                                src={cartItem.pdt_image}
                                alt=""
                              />
                            </Link>
                            <div className="w-100 ml-3">
                              <p className="my-0 w-100 d-flex align-items-center justify-content-between">
                                <strong>{cartItem.pdt_name} </strong>
                                <strong>
                                  ₹{" "}
                                  {parseFloat(
                                    cartItem.price * cartItem.quantity
                                  ).toFixed(2)}
                                </strong>
                              </p>
                              <p className="mb-0 mt-2">
                                <span>Product Code: {cartItem.pdt_code}</span>
                              </p>
                              <p className="mb-0 mt-2">
                                <span>Quantity: {cartItem.quantity} </span>
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <section>
                        <span className="loader-11"> </span>
                      </section>
                    )}
                  </div>
                  <div className="my-3 mt-5 d-flex align-items-center justify-content-between">
                    <p>Product total</p>
                    <p>₹ {parseFloat(cartTotal).toFixed(2)}</p>
                  </div>
                  <div className=" my-3 d-flex align-items-center justify-content-between">
                    <p>Delivery Charges</p>
                    <p>Free</p>
                  </div>
                  <div className="my-3 d-flex align-items-center justify-content-between">
                    <strong>Total Amounts</strong>
                    <strong>₹ {parseFloat(cartTotal).toFixed(2)}</strong>
                  </div>
                  <p className="mt-2">(Inclusive of all Taxes)</p>
                </div>
                <div className="giftcoupon-form giftFormWrp">
                  <div
                    className="gift_form"
                    style={{
                      border: "1px solid #C0C0C0",
                      marginTop: "15px",
                      padding: "15px",
                    }}
                  >
                    <span className="material-icons">
                      <img
                        src="../assets/images/giftpng.png"
                        alt="img"
                        className="cardGiftPng"
                      />
                    </span>
                    <input
                      type="text"
                      placeholder="Gift message or gift wrap"
                      onChange={(e) => setGiftMessage(e.target.value)}
                    />
                    {/* <button type="submit">ADD</button> */}
                  </div>
                </div>

                <div className="updateSectionCard">
                  <div className="checkboRow">
                    <label className="checkbox-container">
                      Send me the latest updates
                      <input
                        type="checkbox"
                        value="Yes"
                        defaultChecked={true}
                        onChange={(e) => setLatestUpdate(e.target.checked)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="checkboRow">
                    <label className="checkbox-container">
                      Sign me up for the Youlry story
                      <input
                        type="checkbox"
                        value="Yes"
                        defaultChecked={true}
                        onChange={(e) => setYoulryStory(e.target.checked)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>

                {buttonStatus && (
                  <>
                    {tokenNo ? (
                      <button className="btn btn-org-lg" onClick={handleSubmit}>
                        PROCEED TO PAYMENT
                      </button>
                    ) : (
                      <button className="btn btn-org-lg" onClick={handleShow}>
                        PROCEED TO PAYMENT
                      </button>
                    )}
                  </>
                )}

                <div className="d-flex align-items-center justify-content-around cardPageLinks">
                  <Link to="/return-policy">Return Policy</Link>
                  <Link to="/shipping-policy">Shipping Policy</Link>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="youlry-promise-wrap ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="promise-content">
                <h2 className="heading-black">Youlry’s Promise</h2>
                <div className="promise-list">
                  <ul>
                    <li>
                      <img src="assets/images/gold.svg" alt="img" />
                      <p className="promise-text">
                        CERTIFIED GOLD
                        <br />
                        AND DIAMONDS
                      </p>
                    </li>
                    <li>
                      <img src="assets/images/return.svg" alt="img" />
                      <p className="promise-text">
                        15-DAY, NO QUESTIONS
                        <br />
                        ASKED RETURNS
                      </p>
                    </li>
                    <li>
                      <img src="assets/images/diamond.svg" alt="img" />
                      <p className="promise-text">
                        PRECIOUS SWAROVSKI
                        <br />
                        STONES
                      </p>
                    </li>
                    <li>
                      <img src="assets/images/lifetime.svg" alt="img" />
                      <p className="promise-text">LIFETIME EXCHANGE</p>
                    </li>
                    <li>
                      <img src="assets/images/shipping.svg" alt="img" />
                      <p className="promise-text">
                        INSURED AND FREE
                        <br />
                        SHIPPING
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
      <Footer />
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please login first to place your order!</Modal.Body>
        <Modal.Footer>
          <button onClick={navigateToLogin} className="btn-org-lg pull-left">
            Go To Login
          </button>
          <button onClick={handleClose} className="btn-org-lg pull-right mb-30">
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* <div className={`wishModal ${loginModalOpen && "is-visible"}`}>
        <div className="wishModal-overlay wishModal-toggle"></div>
        <div className="wishModal-wrapper wishModal-transition">
          <div className="wishModal-header">
            <button className="wishModal-close wishModal-toggle">
             <span aria-hidden="true">&times;</span> 
            </button>
            <h2 className="wishModal-heading">Notice</h2>
          </div>

          <div className="wishModal-body">
            <div className="wishModal-content">
              <p className="text-center">
                Please login first to place your order
              </p>
              <Link to="/login" className="btn-org-lg pull-left">
                Go To Login
              </Link>
              <button
                className="btn-org-lg pull-right mb-30"
                onClick={loginModalRemove}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Delivery;
