import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function PersonalInfoImage() {
  const [getInfo, setGetInfo] = useState("");

  const tokenNo = localStorage.getItem("tokenNo");
  const userName = localStorage.getItem("userName");
  const navigate = useNavigate();

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        token: tokenNo,
        request: "getUserInfo",
      }),
    }).then((response) => {
      response.json().then((result) => {
        if (result.error) {
          localStorage.removeItem("tokenNo");
        } else {
          setGetInfo(result.data);
        }
      });
    });
  }, []);

  if (tokenNo == null) {
    return navigate("/login");
  }

  return (
    <>
      <div className="user-details">
        <div className="user-img">
          {getInfo ? (
            <img src={getInfo.profile_image} alt="img" />
          ) : (
            <section>
              <span className="loader-11"> </span>
            </section>
          )}
        </div>
        <h2 className="user-name">{userName}</h2>
        <p className="user-email">{getInfo.user_email}</p>
      </div>
    </>
  );
}

export default PersonalInfoImage;
