import React, { useState, useEffect, useContext, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Newsletter from "./Newsletter";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify";
import Slider from "react-slick";
import "../pages/SubShare/CSS/react-slick.css";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ButtonBase from "@material-ui/core/ButtonBase";
import { CartContext } from "../Global/CartContext";
import SubShare from "../pages/SubShare/index";
import ProductDetailsConstant from "../components/Constant/ProductDetailsConstant";
import YoulryPromise from "../components/Constant/YoulryPromise";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../components/ProductDetailSlider/DetailSlider.css";
import RelatedProducts from "../components/Carusal/RelatedProducts";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const relatedProductOptions = {
  // loop: true,
  // slidesToShow: 4,
  // margin: 15,
  // dots: false,
  // nav: true,
  // responsive: [
  //   {
  //     breakpoint: 600,
  //     settings: {
  //       initialSlide: 2,
  //     },
  //   },
  //   {
  //     breakpoint: 300,
  //     settings: {
  //       initialSlide: 3,
  //     },
  //   },
  // ],
  loop: true,
  margin: 10,
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
      nav: true,
    },
    600: {
      items: 3,
      nav: false,
    },
    1000: {
      items: 4,
      nav: true,
      loop: false,
      slideBy: 4,
      dots: false,
    },
  },
};

const settingsMain = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: false,
};

var settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: ".slider-for",
  dots: false,
  centerMode: true,
  swipeToSlide: true,
  focusOnSelect: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: true,
        dots: false,
      },
    },
    {
      breakpoint: 980, // tablet breakpoint
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  nextArrow: (
    <img
      src="../assets/images/next.svg"
      className="icon-right"
      alt=""
      id="next-img"
    />
  ),
  prevArrow: (
    <img
      src="../assets/images/prev.svg"
      className="icon-left"
      alt=""
      id="prev-img"
    />
  ),
};

// const settings = {
//   asNavFor: ".slider-for",
//   dots: false,
//   arrows: true,
//   centerMode: true,
//   swipeToSlide: true,
//   focusOnSelect: true,
//   centerPadding: "10px",
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 3,
//         slidesToScroll: 1,
//         infinite: true,
//         dots: false,
//       },
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 2,
//         initialSlide: 2,
//       },
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//   ],
//   nextArrow: (
//     <img
//       src="../assets/images/next.svg"
//       className="icon-right"
//       alt=""
//       id="next-img"
//     />
//   ),
//   prevArrow: (
//     <img
//       src="../assets/images/prev.svg"
//       className="icon-left"
//       alt=""
//       id="prev-img"
//     />
//   ),
// };

function ProductDetail() {
  const { id } = useParams();

  const estimated = localStorage.getItem("estimated");
  const pin_code = localStorage.getItem("pin_code");

  const location = useLocation();

  var prtdId = 0;

  const navigate = useNavigate();
  const tokenNo = localStorage.getItem("tokenNo");

  const CartId = localStorage.getItem("CartId");

  const initialValues = { your_name: "", your_email: "", question_content: "" };
  const ratingInitialValues = {
    quality: "",
    price: "",
    pdt_value: "",
    product_review: "",
  };

  const [quesFormValues, setQuesFormValues] = useState(initialValues);
  const [isSubmit, setISSubmit] = useState(false);

  const [ratingFormValues, setRatingFormValues] = useState(ratingInitialValues);
  const [isRatingSubmit, setISRatingSubmit] = useState(false);

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [isToggle, setIsToggle] = useState(false);
  const [isSocialToggle, setIsSocialToggle] = useState(null);
  const [productInfo, setProductInfo] = useState([]);
  const [productId, setProductId] = useState(id);
  const [productLoader, setProductLoader] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedProductStatus, setRelatedProductsStatus] = useState(false);
  const [deliveryPinode, setDeliveryPincode] = useState(null);
  const [deliveryPin, setDeliveryPin] = useState(null);
  const [DeliveryState, setDeliveryState] = useState(false);
  const [deliveryError, setDeliveryError] = useState(false);
  const [deliverySuccess, setDeliverySuccess] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [wishModalOpen, setWishModalOpen] = useState(false);
  const [addWishModalOpen, setAddWishModalOpen] = useState(false);
  const [wishBadge, setWishBadge] = useState(false);
  const [pinCheckMsg, setPinCheckMsg] = useState(false);
  const [cartId, setCartId] = useState(null);
  const [buttonText, setButtonText] = useState(false);
  const [catId, setCatId] = useState("");
  const [colorVariant, setColorVariant] = useState([]);
  const [userPin, setUserPin] = useState("");

  const askSectionToggle = () => {
    setIsToggle(true);
  };

  const socialShareToggle = () => {
    let classElements = document
      .getElementById("n-5")
      .classList.contains("active");
    // console.log(classElements);
    if (classElements === true) {
      setIsSocialToggle("");
    } else {
      setIsSocialToggle("active");
    }
  };

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getProductDetails",
        token: tokenNo ? tokenNo : "",
        pid: id,
      }),
    }).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        if (result.success === true) {
          setProductInfo(result.data);
          setCatId(result.data.pdtInfo.cat_id);
          setColorVariant(result.data.variant);
          setProductLoader(true);
        } else {
          setProductInfo(null);
          setProductLoader(false);
        }
      });
    });
  }, [id]);

  const handleChange = (e) => {
    //console.log(e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    setQuesFormValues({ ...quesFormValues, [name]: value });
    //console.log(quesFormValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "submitProductQuestion",
        token: tokenNo ? tokenNo : "",
        pdt_id: productInfo.pdtInfo.pdt_id,
        name: quesFormValues.your_name,
        email: quesFormValues.your_email,
        question: quesFormValues.question_content,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((resp) => {
      resp.json().then((res) => {
        console.log(res);
        if (res.success === true) {
          setISSubmit(true);
          e.target.reset();
        } else {
          setISSubmit(false);
        }
      });
    });
  };

  const starHandle = (e) => {
    //console.log(e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    setRatingFormValues({ ...ratingFormValues, [name]: value });
    //console.log(ratingFormValues);
  };

  const reviewSubmit = (e) => {
    e.preventDefault();

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "submitProductReview",
        token: tokenNo ? tokenNo : "",
        pdt_id: productInfo.pdtInfo.pdt_id,
        quality: ratingFormValues.quality,
        price: ratingFormValues.price,
        value: ratingFormValues.pdt_value,
        review: ratingFormValues.product_review,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((resp) => {
      resp.json().then((res) => {
        console.log(res);
        if (res.success === true) {
          setISRatingSubmit(true);
          e.target.reset();
        } else {
          setISRatingSubmit(false);
        }
      });
    });
  };

  const wishModal = () => {
    setWishModalOpen(true);
  };

  const wishModalRemove = () => {
    setWishModalOpen(false);
  };

  const addWishlistModal = () => {
    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "addToWishlist",
        token: tokenNo,
        pid: productInfo.pdtInfo.pdt_id,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((resp) => {
      resp.json().then((res) => {
        console.log(res);
        if (res.success === true) {
          setWishBadge(true);
          setAddWishModalOpen(true);
        } else {
          setWishBadge(false);
        }
      });
    });
  };

  const addWishModalRemove = () => {
    setAddWishModalOpen(false);
  };

  useEffect(() => {
    const userPin = localStorage.getItem("pin_code");
    const pinCode = userPin;
    if (pinCode) {
      setUserPin(pinCode);
    }
  }, []);

  const checkDelivery = (e) => {
    e.preventDefault();
    const pincode = userPin === "" ? deliveryPinode : userPin;
    const product_id = productInfo.pdtInfo.pdt_id;

    fetch(
      "https://bepixel.youlry.com/api/checkpincode?pincode=" +
        pincode +
        "&product_id=" +
        product_id
    ).then((respo) => {
      respo.json().then((resu) => {
        //console.log(resu);
        if (resu.statusCode === 3) {
          setDeliveryPin(resu.message);
          setDeliveryState(true);
          setDeliveryError(true);
          setDeliverySuccess(false);
          setPinCheckMsg(false);
        } else {
          setDeliveryPin(resu.delivery_days);
          setDeliveryState(true);
          setDeliveryError(false);
          setDeliverySuccess(true);
          setPinCheckMsg(false);
          window.localStorage.setItem("pin_code", pincode);
        }
      });
    });

    //console.log(deliveryPinode);
  };

  let CurrentDate = new Date();
  CurrentDate.setDate(CurrentDate.getDate() + 7);
  const currentDay = CurrentDate.getDate();
  const currentMonth = CurrentDate.toLocaleString("en-us", { month: "short" });
  const currentYear = CurrentDate.getFullYear();
  const currentFullDate = `${currentMonth} ${currentDay}, ${currentYear}`;

  let FutureDate = new Date(CurrentDate);
  FutureDate.setDate(FutureDate.getDate() + deliveryPin);

  const futureDay = FutureDate.getDate();
  const futureMonth = FutureDate.toLocaleString("en-us", { month: "short" });
  const futureYear = FutureDate.getFullYear();
  const futureFullDate = `${futureMonth} ${futureDay}, ${futureYear}`;

  useEffect(() => {
    localStorage.setItem(
      "estimated",
      `${currentFullDate} and ${futureFullDate}`
    );
  }, [currentFullDate, futureFullDate]);

  const handleAddCart = (pid) => {
    let pincodeVal = document.getElementById("pincode").value;

    if (pincodeVal == "") {
      //setPinCheckMsg(true);
      toast.error("Please fill delivery area pin code First. !", {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
      //console.log('blank');
    } else {
      //console.log(deliverySuccess);

      if (deliverySuccess === true) {
        const request = {
          method: "POST",
          headers: {
            //'Accept': 'application/json',
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          mode: "cors",
          body: JSON.stringify({
            request: "addToCart",
            token: tokenNo ? tokenNo : "",
            cart_id: CartId ? CartId : "",
            pdt_id: pid,
            quantity: "1",
            price: productInfo.pdtInfo.price,
            preference: "",
            est_delivery_date: estimated ? estimated : "",
            delivery_pin: pincodeVal,
          }),
        };

        fetch(process.env.REACT_APP_API_URL, request).then((resp) => {
          resp.json().then((res) => {
            //console.log(res);
            if (res.success === true) {
              setCartId(res.cart_id);
              setButtonText(true);
              setPinCheckMsg(false);
              localStorage.setItem("CartId", res.cart_id);
              let dataId = localStorage.getItem("dataid");
              let obj = [];
              if (dataId) {
                obj = JSON.parse(dataId);
              }
              obj.push({ id: pid });
              localStorage.setItem("dataid", JSON.stringify(obj));
              document.getElementById("hiddenBtn").click();
              navigate(`/cart`);
            } else {
              //setWishBadge(false)
            }
          });
        });
      } else {
        toast.error("You need to check for delivery service in your area!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  let datas = JSON.parse(localStorage.getItem("dataid"));

  const extractFirstLetters = function (name) {
    let Name = name.split(" ")[0];
    let firstLetters = Name[0];
    return firstLetters;
  };

  const navigateToLogin = () => {
    navigate("/login", { state: { component: window.location.pathname } });
  };

  return (
    <>
      <Header />

      {!productLoader ? (
        <div className="preloaderBg" id="preloader">
          <div className="preloader"></div>
          <div className="preloader2"></div>
        </div>
      ) : (
        <div>
          <section className="product-detail-wrap ptb-60">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="breadcrum-wrap">
                    <ul>
                      <li>
                        <Link to="/">HOME</Link>
                      </li>
                      <li>
                        <Link
                          to={`/category/${productInfo.pdtInfo.category_slug}`}
                        >
                          {productInfo.pdtInfo.category_name.toUpperCase()}
                        </Link>
                      </li>
                      <li>{productInfo.pdtInfo.pdt_name.toUpperCase()}</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 pb-4">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="review-star mr-4">
                      {productInfo.pdtInfo.ratings === "5" ? (
                        <img src="../assets/images/rating5.png"></img>
                      ) : productInfo.pdtInfo.ratings === "4" ? (
                        <img src="../assets/images/rating4.png"></img>
                      ) : productInfo.pdtInfo.ratings === "3" ? (
                        <img src="../assets/images/rating3.png"></img>
                      ) : productInfo.pdtInfo.ratings === "2" ? (
                        <img src="../assets/images/rating2.png"></img>
                      ) : productInfo.pdtInfo.ratings === "1" ? (
                        <img src="../assets/images/rating1.png"></img>
                      ) : productInfo.pdtInfo.ratings === "0" ? (
                        <img src="../assets/images/rating0.png"></img>
                      ) : (
                        ""
                      )}
                    </div>
                    <p className="black-title-sm">
                      {productInfo.pdtReview.length} Reviews
                    </p>
                  </div>
                  {productInfo.pdtReview.length === 0 ? (
                    <p
                      className="black-title-sm review-modal"
                      data-toggle="modal"
                      data-backdrop="static"
                      data-keyboard="false"
                      data-target="#reviewModal"
                    >
                      Be the first to review this product
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 dd">
                  <div className="product-gallery-wrap">
                    {/* product gallery start */}
                    <div className="product__wrp">
                      <Slider
                        {...settingsMain}
                        ref={(slider) => setSlider1(slider)}
                      >
                        {productInfo.pdtImages.map((slide) => {
                          if (slide.media_type === "video") {
                            return (
                              <div className="vide__wrp" key={slide.img_id}>
                                <video
                                  width="100%"
                                  height="100%"
                                  controls
                                  autoPlay
                                  muted
                                  loop
                                >
                                  <source
                                    src={slide.media_url}
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                            );
                          } else {
                            return (
                              <div className="img_zoom_container">
                                <div
                                  style={{ opacity: "100 !important" }}
                                  key={slide.img_id}
                                >
                                  {/* <img className="slick-slide-image" src={slide.img_medium} /> */}
                                  <div className="slick-slide-image">
                                    <ReactImageMagnify
                                      {...{
                                        smallImage: {
                                          alt: "Yourly...",
                                          isFluidWidth: true,
                                          src: `${slide.img_medium}`,
                                          key: `${slide.img_id}`,
                                          className: "slick-slide-image",
                                        },
                                        largeImage: {
                                          alt: "",
                                          src: `${slide.media_url}`,
                                          key: `${slide.img_id}`,
                                          width: 2000,
                                          height: 2000,
                                        },
                                        enlargedImagePortalId: "right-zoom",
                                        shouldUsePositiveSpaceLens: true,
                                        enlargedImageContainerDimensions: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </Slider>

                      <div className="thumbnail-slider-wrap">
                        <Slider
                          {...settings}
                          asNavFor={nav1}
                          ref={(slider) => setSlider2(slider)}
                        >
                          {productInfo.pdtImages.map((slide) => {
                            if (slide.media_type === "video") {
                              return (
                                <div className="slick-slide" key={slide.img_id}>
                                  <img
                                    className="slick-slide-image"
                                    src={slide.media_thumb}
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div className="slick-slide" key={slide.img_id}>
                                  <img
                                    className="slick-slide-image"
                                    style={{
                                      width: "200",
                                      height: "200",
                                    }}
                                    src={slide.img_thumb}
                                  />
                                </div>
                              );
                            }
                          })}
                        </Slider>
                      </div>
                    </div>

                    {/* product gallery end */}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: "100%",
                      zIndex: "4",
                    }}
                    id="right-zoom"
                    className="portal"
                  />
                </div>

                <div className="col-md-6 dds">
                  <div className="product-gallery-detail">
                    <div className="d-flex align-items-center justify-content-between">
                      <h2 className="product-title">
                        {productInfo.pdtInfo.pdt_name}
                      </h2>
                      <div className="product-action">
                        {tokenNo && productInfo.pdtInfo.InWishlist === true ? (
                          <a href="javascript:void(0)">
                            <span
                              className={`material-icons-outlined wishlist-red`}
                            >
                              favorite
                            </span>
                          </a>
                        ) : tokenNo &&
                          productInfo.pdtInfo.InWishlist === false ? (
                          <a
                            href="javascript:void(0)"
                            onClick={addWishlistModal}
                          >
                            <span
                              className={`material-icons-outlined wishlist-grey`}
                            >
                              favorite
                            </span>
                          </a>
                        ) : (
                          <a href="javascript:void(0)" onClick={wishModal}>
                            <span className="material-icons-outlined wishlist-grey">
                              favorite
                            </span>
                          </a>
                        )}

                        <div
                          className="share-button sharer"
                          style={{ display: "block" }}
                        >
                          <button
                            type="button"
                            className="share-btn"
                            onClick={socialShareToggle}
                          >
                            <span className="material-icons share-black">
                              share
                            </span>
                          </button>
                          <div
                            className={`social top center networks-5 ${isSocialToggle}`}
                            id="n-5"
                          >
                            <SubShare />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="product-desc">
                      {productInfo.pdtInfo.pdt_short_desc}
                    </p>
                    <h3 className="product-price">
                      ₹{productInfo.pdtInfo.price}{" "}
                      <span
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        <s>
                          {" "}
                          {productInfo.pdtInfo.mrp > 0
                            ? `  ₹ ${productInfo.pdtInfo.mrp}`
                            : " "}
                        </s>
                      </span>
                    </h3>
                    <div className="product-form">
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label>Karat :</label>
                          <select
                            className="form-control"
                            value={productInfo.pdtInfo.karat}
                          >
                            <option value={productInfo.variant.karat[0]}>
                              {productInfo.variant.karat[0]}
                            </option>
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label>Metal Color :</label>
                          {/* <select
                            className="form-control"
                            value={productInfo.pdtInfo.color}
                          >
                            <option value={productInfo.variant.metal_color[0]}>
                              {productInfo.variant.metal_color[0]}
                            </option>
                            <option value={productInfo.variant.metal_color[1]}>
                              {productInfo.variant.metal_color[1]}
                            </option>
                            <option value={productInfo.variant.metal_color[2]}>
                              {productInfo.variant.metal_color[2]}
                            </option>
                          </select> */}
                          <select className="form-control">
                            {colorVariant.metal_color.map((item, i) => (
                              <option key={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-md-12">
                          <label>
                            Size :
                            {productInfo.pdtInfo.size !== "NA" && (
                              <a href={productInfo.size_guide} target="_blank">
                                <span className="text-org-sm">Size Guide</span>
                              </a>
                            )}
                          </label>

                          <select className="form-control">
                            {productInfo.variant.size.map((item, i) => (
                              <option key={item}>{item}</option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group col-md-12">
                          <label>
                            Check delivery service in your area{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="pincode-wrap">
                            <span className="material-icons">fmd_good</span>
                            <input
                              type="text"
                              className="form-control"
                              id="pincode"
                              placeholder="Pincode"
                              onChange={(e) =>
                                setDeliveryPincode(e.target.value)
                              }
                              defaultValue={userPin || ""}
                            />
                            <Link
                              to="#"
                              onClick={checkDelivery}
                              className="text-org-sm"
                            >
                              Check
                            </Link>
                            {/* <button type='submit' className="text-org-sm">Check</button> */}
                          </div>
                        </div>
                        <ToastContainer
                          position="top-center"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="colored"
                        />
                      </div>

                      {DeliveryState && (
                        <div className="row">
                          <div className="col-md-12">
                            {deliveryError && (
                              <p className="delivery-text-error">
                                <span className="material-icons">
                                  wrong_location
                                </span>
                                {deliveryPin}
                              </p>
                            )}

                            {deliverySuccess && (
                              <p className="delivery-text">
                                <span className="material-icons">done</span>
                                Standard Delivery between {
                                  currentFullDate
                                } and {futureFullDate}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                      <ToastContainer />
                      {/* {pinCheckMsg && (
                        <p className="delivery-text" style={{ color: "red" }}>
                          Please fill Delivery area pin code First.
                        </p>
                      )} */}

                      <div className="row  mt-5">
                        <div className="form-group col-md-12">
                          {datas &&
                          datas.some(
                            (e) => e.id === productInfo.pdtInfo.pdt_id
                          ) === true ? (
                            <button
                              type="button"
                              className="btn-org-lg"
                              disabled={true}
                            >
                              ADDED IN BAG
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn-org-lg"
                              onClick={() =>
                                handleAddCart(productInfo.pdtInfo.pdt_id)
                              }
                            >
                              {buttonText ? "ADDED IN BAG" : "ADD TO BAG"}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="product-detail-tabs-wrap">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <ul className="nav nav-tabs" id="product-tab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="product-detail-tab"
                        data-toggle="tab"
                        href="#product-detail"
                        role="tab"
                        aria-controls="product-detail"
                        aria-selected="true"
                      >
                        Product Detail
                      </a>
                    </li>
                    {productInfo.pdtInfo.erp_mrp > 0 ? (
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="dimensions-tab"
                          data-toggle="tab"
                          href="#dimensions"
                          role="tab"
                          aria-controls="dimensions"
                          aria-selected="false"
                        >
                          Dimensions
                        </a>
                      </li>
                    ) : (
                      ""
                    )}

                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="inspiration-tab"
                        data-toggle="tab"
                        href="#inspiration"
                        role="tab"
                        aria-controls="inspiration"
                        aria-selected="false"
                      >
                        Inspiration
                      </a>
                    </li>
                    {productInfo.pdtInfo.erp_mrp <= 0 ? (
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="price-tab"
                          data-toggle="tab"
                          href="#price"
                          role="tab"
                          aria-controls="price"
                          aria-selected="false"
                        >
                          Price Breakup
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                  <div className="tab-content" id="product-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="product-detail"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="product-detail-list">
                        <ul>
                          <li>
                            <p>
                              Product Code :{" "}
                              {productInfo.pdtInfo.syn_pdt_desc.ITEM_NAME}
                            </p>
                          </li>
                          <li>
                            <p>
                              Item Code :{" "}
                              {productInfo.pdtInfo.syn_pdt_desc.VARIANT_ID}
                            </p>
                          </li>
                          <li>
                            <p>
                              Purity :{" "}
                              {productInfo.pdtInfo.syn_pdt_desc.KARAT_COLOR}
                            </p>
                          </li>
                          <li>
                            <p>
                              Metal Color :{" "}
                              {productInfo.pdtInfo.syn_pdt_desc.BOM_CLR}
                            </p>
                          </li>
                          <li>
                            <p>
                              Metal Weight :{" "}
                              {productInfo.pdtInfo.syn_pdt_desc.NET_WT}
                            </p>
                          </li>
                          <li>
                            <p>
                              Size : {productInfo.pdtInfo.syn_pdt_desc.SIZE_}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="inspiration"
                      role="tabpanel"
                      aria-labelledby="inspiration-tab"
                    >
                      <div className="inspiration-content">
                        <p>{productInfo.pdtInfo.pdt_desc}</p>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="dimensions"
                      role="tabpanel"
                      aria-labelledby="dimensions-tab"
                    >
                      <div className="inspiration-content">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: productInfo.pdtInfo.dimension,
                          }}
                        ></p>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="price"
                      role="tabpanel"
                      aria-labelledby="price-tab"
                    >
                      <div className="price-breakup-table table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Component</th>
                              <th scope="col">Rate</th>
                              <th scope="col">Approx Wt.</th>
                              <th scope="col">Discount</th>
                              <th scope="col">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productInfo.pdtInfo.price_breakup.component.map(
                              (compItem, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <b>{compItem.metal_name}</b>
                                    </td>
                                    <td>₹{compItem.rate}</td>
                                    <td>{compItem.weight} gm</td>
                                    <td>₹{compItem.discount}</td>
                                    <td>₹{compItem.value}</td>
                                  </tr>
                                );
                              }
                            )}

                            <tr>
                              <td>Total Value</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>
                                <b>Precious stone</b>
                              </td>
                              <td>
                                {
                                  productInfo.pdtInfo.price_breakup
                                    .precious_stone.rate
                                }
                              </td>
                              <td>
                                {
                                  productInfo.pdtInfo.price_breakup
                                    .precious_stone.weight
                                }
                              </td>
                              <td>
                                {
                                  productInfo.pdtInfo.price_breakup
                                    .precious_stone.discount
                                }
                              </td>
                              <td>
                                {
                                  productInfo.pdtInfo.price_breakup
                                    .precious_stone.value
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Total Precious Stone Value</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Making Charges</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                ₹
                                {
                                  productInfo.pdtInfo.price_breakup
                                    .making_charges
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Subtotal</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                ₹{productInfo.pdtInfo.price_breakup.Subtotal}
                              </td>
                            </tr>
                            <tr>
                              <td>GST</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>₹{productInfo.pdtInfo.price_breakup.gst}</td>
                            </tr>
                            <tr>
                              <td>Grand Total</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                ₹{productInfo.pdtInfo.price_breakup.grand_total}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="careInsSec ptb-60">
            <ProductDetailsConstant />
          </section>

          <section className="youlry-promise-wrap ptb-60">
            <YoulryPromise />
          </section>
          <section class="seller-wrap ptb-100">
            <div class="container">
              <div class="row">
                <div class="col-md-12 p-30">
                  <div class="collection-content text-center pb-60">
                    <h2 class="heading-black">You May Also Like</h2>
                  </div>
                  <RelatedProducts categoryId={catId} count="10" />
                </div>
                <div class="col-md-12"></div>
              </div>
            </div>
          </section>

          <section className="review-wrap">
            <div className="container">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                  <div className="review-content text-center pb-60">
                    <h2 className="heading-black">Customer Reviews</h2>
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>
              <div className="ratingSection">
                <div className="row ">
                  <div className="col-md-1"></div>
                  <div className="col-md-3">
                    <div className="based-rating-box">
                      <figure className="rating-img-wrp">
                        {productInfo.pdtInfo.ratings === "5" ? (
                          <img src="../assets/images/rating5.png"></img>
                        ) : productInfo.pdtInfo.ratings === "4" ? (
                          <img src="../assets/images/rating4.png"></img>
                        ) : productInfo.pdtInfo.ratings === "3" ? (
                          <img src="../assets/images/rating3.png"></img>
                        ) : productInfo.pdtInfo.ratings === "2" ? (
                          <img src="../assets/images/rating2.png"></img>
                        ) : productInfo.pdtInfo.ratings === "1" ? (
                          <img src="../assets/images/rating1.png"></img>
                        ) : productInfo.pdtInfo.ratings === "0" ? (
                          <img src="../assets/images/rating0.png"></img>
                        ) : (
                          ""
                        )}
                        <span className="black-title-sm">
                          {" "}
                          ({productInfo.pdtReview.length} Reviews)
                        </span>
                      </figure>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="review-rating-wrap pt-0">
                      <ul className="review-rating-list">
                        <li>
                          <div className="d-flex">
                            <h2 className="heading-bold-sm">Quality</h2>
                            <div className="review-star">
                              <figure className="rating-img-wrp">
                                {productInfo.pdtInfo.rating_quality === "5" ? (
                                  <img src="../assets/images/rating5.png"></img>
                                ) : productInfo.pdtInfo.rating_quality ===
                                  "4" ? (
                                  <img src="../assets/images/rating4.png"></img>
                                ) : productInfo.pdtInfo.rating_quality ===
                                  "3" ? (
                                  <img src="../assets/images/rating3.png"></img>
                                ) : productInfo.pdtInfo.rating_quality ===
                                  "2" ? (
                                  <img src="../assets/images/rating2.png"></img>
                                ) : productInfo.pdtInfo.rating_quality ===
                                  "1" ? (
                                  <img src="../assets/images/rating1.png"></img>
                                ) : productInfo.pdtInfo.ratings === "0" ? (
                                  <img src="../assets/images/rating0.png"></img>
                                ) : (
                                  ""
                                )}
                              </figure>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex">
                            <h2 className="heading-bold-sm">Price</h2>
                            <div className="review-star">
                              <figure className="rating-img-wrp">
                                {productInfo.pdtInfo.rating_price === "5" ? (
                                  <img src="../assets/images/rating5.png"></img>
                                ) : productInfo.pdtInfo.rating_price === "4" ? (
                                  <img src="../assets/images/rating4.png"></img>
                                ) : productInfo.pdtInfo.rating_price === "3" ? (
                                  <img src="../assets/images/rating3.png"></img>
                                ) : productInfo.pdtInfo.rating_price === "2" ? (
                                  <img src="../assets/images/rating2.png"></img>
                                ) : productInfo.pdtInfo.rating_price === "1" ? (
                                  <img src="../assets/images/rating1.png"></img>
                                ) : productInfo.pdtInfo.ratings === "0" ? (
                                  <img src="../assets/images/rating0.png"></img>
                                ) : (
                                  ""
                                )}
                              </figure>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex">
                            <h2 className="heading-bold-sm">Value</h2>
                            <div className="review-star">
                              <figure className="rating-img-wrp">
                                {productInfo.pdtInfo.rating_value === "5" ? (
                                  <img src="../assets/images/rating5.png"></img>
                                ) : productInfo.pdtInfo.rating_value === "4" ? (
                                  <img src="../assets/images/rating4.png"></img>
                                ) : productInfo.pdtInfo.rating_value === "3" ? (
                                  <img src="../assets/images/rating3.png"></img>
                                ) : productInfo.pdtInfo.rating_value === "2" ? (
                                  <img src="../assets/images/rating2.png"></img>
                                ) : productInfo.pdtInfo.rating_value === "1" ? (
                                  <img src="../assets/images/rating1.png"></img>
                                ) : productInfo.pdtInfo.ratings === "0" ? (
                                  <img src="../assets/images/rating0.png"></img>
                                ) : (
                                  ""
                                )}
                              </figure>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <button
                      type="button"
                      id="AskQuestion"
                      className="btn white-btn pull-right mt-4"
                      onClick={askSectionToggle}
                    >
                      Ask A Question
                    </button>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <div
                      className={`AskQuestionForm ${
                        isToggle ? "showForm" : ""
                      }`}
                      id="askQuestionBox"
                    >
                      {isSubmit && (
                        <div className="alert alert-success" id="success-alert">
                          <strong>Success! </strong> Record Submitted
                          Successfully.
                        </div>
                      )}
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="yotpo-header-title">
                              ASK A QUESTION
                            </h2>
                            <div className="yotpo-mandatory-explain">
                              {" "}
                              <span className="yotpo-mandatory-mark">
                                *{" "}
                              </span>{" "}
                              Indicates a required field{" "}
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="x-element">
                                <label className="y-label">
                                  <span className="yotpo-mandatory-mark">
                                    *{" "}
                                  </span>
                                  Question:
                                </label>
                                <textarea
                                  id="yotpo_input_question_content"
                                  className="y-input yotpo-text-box"
                                  name="question_content"
                                  maxLength="1000"
                                  aria-required="true"
                                  placeholder=""
                                  onChange={handleChange}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label className="y-label">
                                <span className="yotpo-mandatory-mark">* </span>
                                Use your name:
                              </label>
                              <div className="input-field">
                                <input
                                  type="text"
                                  name="your_name"
                                  placeholder=""
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label className="y-label">
                                <span className="yotpo-mandatory-mark">* </span>
                                Email:
                              </label>
                              <div className="input-field">
                                <input
                                  type="email"
                                  name="your_email"
                                  placeholder=""
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="post-question">
                            <button type="submit" className="postbtn">
                              Post
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div id="exTab1" className="reviewQesSection">
                      <ul className="nav nav-pills">
                        <li>
                          <a
                            className="active show"
                            href="#1a"
                            data-toggle="tab"
                          >
                            Reviews ({productInfo.pdtReview.length}){" "}
                          </a>
                        </li>
                        <li>
                          <a href="#2a" data-toggle="tab">
                            Questions ({productInfo.pdtQuestion.length})
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content clearfix ptb-60">
                        <div className="tab-pane active" id="1a">
                          <div className="top-review-wrap ">
                            <div className="topuser-list">
                              <ul>
                                {productInfo.pdtReview
                                  ? productInfo.pdtReview.map((reviewItem) => {
                                      //console.log(reviewItem.rating_info);
                                      return (
                                        <li key={reviewItem.rwid}>
                                          <div className="top-user">
                                            <div className="d-flex align-items-center">
                                              <div className="topuser-img">
                                                <img
                                                  style={{
                                                    borderRadius: "30px",
                                                    borderColor:
                                                      "1px solid red",
                                                  }}
                                                  src={reviewItem.review_image}
                                                  alt="user"
                                                  onError={(e) =>
                                                    (e.target.src =
                                                      "http://youlry.bizdevtechnologies.in/uploads/collection/no_image.jpg")
                                                  }
                                                />
                                              </div>
                                              <p className="topuser-name">
                                                {reviewItem.display_name}
                                              </p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                              <div className="review-star">
                                                <figure className="rating-img-wrp">
                                                  {reviewItem.ratings ===
                                                  "5" ? (
                                                    <img src="../assets/images/rating5.png"></img>
                                                  ) : reviewItem.ratings ===
                                                    "4" ? (
                                                    <img src="../assets/images/rating4.png"></img>
                                                  ) : reviewItem.ratings ===
                                                    "3" ? (
                                                    <img src="../assets/images/rating3.png"></img>
                                                  ) : reviewItem.ratings ===
                                                    "2" ? (
                                                    <img src="../assets/images/rating2.png"></img>
                                                  ) : reviewItem.ratings ===
                                                    "1" ? (
                                                    <img src="../assets/images/rating1.png"></img>
                                                  ) : (
                                                    ""
                                                  )}
                                                </figure>
                                              </div>
                                              <p className="black-title-sm">
                                                Reviwed on{" "}
                                                {reviewItem.reviwed_on}
                                              </p>
                                            </div>
                                            <p className="black-title-sm">
                                              Size: {reviewItem.review_size} I
                                              Metal color:{" "}
                                              {reviewItem.review_metal_color}
                                            </p>
                                            <p className="black-title-sm">
                                              {reviewItem.review}
                                            </p>
                                          </div>
                                        </li>
                                      );
                                    })
                                  : ""}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="2a">
                          <div className="questionArea">
                            {productInfo.pdtQuestion
                              ? productInfo.pdtQuestion.map((questionItem) => {
                                  //console.log(reviewItem.rating_info);
                                  return (
                                    <div
                                      className="yotpo-question"
                                      key={questionItem.qid}
                                    >
                                      <div className="yotpo-user-name">
                                        <h3>{questionItem.display_name}</h3>
                                      </div>
                                      <div className="yotpo-main">
                                        <div className="content-question-top">
                                          {" "}
                                          Q: {questionItem.question}{" "}
                                        </div>
                                      </div>
                                      <div className="yotpo-comments-box">
                                        <div className="yotpo-comment-box yotpo-comment">
                                          <div className="yotpo-header yotpo-store-owner">
                                            <div className="yotpo-header-element">
                                              <span className="y-label yotpo-user-name">
                                                Youlry
                                              </span>
                                            </div>
                                            <div className="yotpo-header-element yotpo-header-actions">
                                              <span className="y-label question-date">
                                                {questionItem.reply_on}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="yotpo-main">
                                            <div className="content-question">
                                              <p>A: {questionItem.reply}</p>
                                              <div className="yotpo-header-element yotpo-header-actions d-none">
                                                <span className="y-label question-date">
                                                  {questionItem.reply_on}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                  <div className="row">
                    <div className="col-md-10"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}

      <Newsletter />
      <Footer />

      <div
        className="modal fade"
        id="reviewModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="reviewModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {isRatingSubmit && (
                <div className="alert alert-success" id="success-alert">
                  <strong>Success! </strong> Record Submitted Successfully.
                </div>
              )}

              <form onSubmit={reviewSubmit}>
                <div className="text-center">
                  <h5 className="modal-heading">Florid Open End Ring</h5>
                  <p className="modal-title">
                    Tell us about your Youlry experience
                  </p>
                </div>
                <div className="review-list">
                  <ul>
                    <li>
                      <h2 className="heading-black-bold">
                        YOUR RATING <span>*</span>
                      </h2>
                      <p className="title-black">
                        Tap on the stars to rate your experience
                      </p>
                    </li>
                    <li>
                      <h2 className="heading-black-thin">QUALITY</h2>
                      <div className="review-star">
                        <fieldset className="rating">
                          <input
                            type="radio"
                            id="star5_quality"
                            name="quality"
                            value="5"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star5_quality"
                            title="Awesome - 5 stars"
                          ></label>
                          <input
                            type="radio"
                            id="star4_quality"
                            name="quality"
                            value="4"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star4_quality"
                            title="Pretty good - 4 stars"
                          ></label>
                          <input
                            type="radio"
                            id="star3_quality"
                            name="quality"
                            value="3"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star3_quality"
                            title="Meh - 3 stars"
                          ></label>
                          <input
                            type="radio"
                            id="star2_quality"
                            name="quality"
                            value="2"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star2_quality"
                            title="Kinda bad - 2 stars"
                          ></label>
                          <input
                            type="radio"
                            id="star1_quality"
                            name="quality"
                            value="1"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star1_quality"
                            title="Sucks big time - 1 star"
                          ></label>
                        </fieldset>
                      </div>
                    </li>
                    <li>
                      <h2 className="heading-black-thin">PRICE</h2>
                      <div className="review-star">
                        <fieldset className="rating">
                          <input
                            type="radio"
                            id="star5_price"
                            name="price"
                            value="5"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star5_price"
                            title="Awesome - 5 stars"
                          ></label>
                          <input
                            type="radio"
                            id="star4_price"
                            name="price"
                            value="4"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star4_price"
                            title="Pretty good - 4 stars"
                          ></label>
                          <input
                            type="radio"
                            id="star3_price"
                            name="price"
                            value="3"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star3_price"
                            title="Meh - 3 stars"
                          ></label>
                          <input
                            type="radio"
                            id="star2_price"
                            name="price"
                            value="2"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star2_price"
                            title="Kinda bad - 2 stars"
                          ></label>
                          <input
                            type="radio"
                            id="star1_price"
                            name="price"
                            value="1"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star1_price"
                            title="Sucks big time - 1 star"
                          ></label>
                        </fieldset>
                      </div>
                    </li>
                    <li>
                      <h2 className="heading-black-thin">VALUE</h2>
                      <div className="review-star">
                        <fieldset className="rating">
                          <input
                            type="radio"
                            id="star5_pdt_value"
                            name="pdt_value"
                            value="5"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star5_pdt_value"
                            title="Awesome - 5 stars"
                          ></label>
                          <input
                            type="radio"
                            id="star4_pdt_value"
                            name="pdt_value"
                            value="4"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star4_pdt_value"
                            title="Pretty good - 4 stars"
                          ></label>
                          <input
                            type="radio"
                            id="star3_pdt_value"
                            name="pdt_value"
                            value="3"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star3_pdt_value"
                            title="Meh - 3 stars"
                          ></label>
                          <input
                            type="radio"
                            id="star2_pdt_value"
                            name="pdt_value"
                            value="2"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star2_pdt_value"
                            title="Kinda bad - 2 stars"
                          ></label>
                          <input
                            type="radio"
                            id="star1_pdt_value"
                            name="pdt_value"
                            value="1"
                            onChange={starHandle}
                          />
                          <label
                            className="full"
                            htmlFor="star1_pdt_value"
                            title="Sucks big time - 1 star"
                          ></label>
                        </fieldset>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="textarea-wrap">
                  <textarea
                    rows="5"
                    name="product_review"
                    placeholder="Our designers would love to hear your feedback"
                    onChange={starHandle}
                  ></textarea>
                </div>
                <div className="btn-wrap">
                  <button type="submit" className="btn-org-lg">
                    Post Review
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className={`wishModal ${wishModalOpen && "is-visible"}`}>
        <div className="wishModal-overlay wishModal-toggle"></div>
        <div className="wishModal-wrapper wishModal-transition">
          <div className="wishModal-header">
            <button className="wishModal-close wishModal-toggle">
              {/* <span aria-hidden="true">&times;</span> */}
            </button>
            <h2 className="wishModal-heading">Notice</h2>
          </div>

          <div className="wishModal-body">
            <div className="wishModal-content">
              <p className="text-center">
                Please login first to add this product in your wishlist
              </p>
              <button
                onClick={navigateToLogin}
                className="btn-org-lg pull-left"
              >
                Go To Login
              </button>
              <button
                className="btn-org-lg pull-right mb-30"
                onClick={wishModalRemove}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`wishModal ${addWishModalOpen && "is-visible"}`}>
        <div className="wishModal-overlay wishModal-toggle"></div>
        <div className="wishModal-wrapper wishModal-transition">
          <div className="wishModal-header">
            <button className="wishModal-close wishModal-toggle">
              {/* <span aria-hidden="true">&times;</span> */}
            </button>
            <h2 className="wishModal-heading">Notice</h2>
          </div>

          <div className="wishModal-body">
            <div className="wishModal-content">
              <p className="text-center">
                This product is added in your wishlist
              </p>
              <Link to="/dashboard-wishlist" className="btn-org-lg pull-left">
                Go To Wishlist
              </Link>
              <button
                className="btn-org-lg pull-right mb-30"
                onClick={addWishModalRemove}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetail;
