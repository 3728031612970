import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Header from '../components/Header';
import MyAccountLinks from './MyAccountLinks';
import PersonalInfoImage from './PersonalInfoImage';

function MyAddress() {

    const initialValues = { 
        address_type  : "", 
        full_name     : "", 
        mobile_no     : "", 
        address       : "",
        address_line1 : "", 
        land_mark     : "", 
        state         : "", 
        city          : "",
        zip           : ""
    } 
    const [formValues, setFormValues] = useState(initialValues);
    //const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setISSubmit] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const [getValue, setGetValue] = useState([]);

    const token = localStorage.getItem('tokenNo');

    useEffect(() => {
        fetch(
            process.env.REACT_APP_API_URL, 
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin' : '*'
                },
                body: JSON.stringify({
                    "token": token,
                    "request":"userAddressList"
                })

           }).then( (response)  => {
              response.json().then( result => {
                  setGetValue(result.data);
              })             
           })
         
      }, [])

  
     // console.log(getValue)

    const handleChange = (e) => {
        //console.log(e.target.value);
        const name = e.target.name;
        const value = e.target.value;
        setFormValues({...formValues, [name] : value});
        //console.log(formValues);
    }


    const handleSubmit =  (e) => {
        e.preventDefault();
       //setFormErrors(validate(formValues));
        
        const request =  {
            method : "POST",
            headers: {
                //'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin' : '*'
            },
            mode : "cors",
            body: JSON.stringify({
                "request"       : "addUserAddress",
                "token"         : token,
                "address_type"  : formValues.address_type,
                "full_name"     : formValues.full_name,
                "mobile_no"     : formValues.mobile_no,
                "address"       : formValues.address,                 
                "address_line1" : formValues.address_line1, 
                "land_mark"     : formValues.land_mark, 
                "state"         : formValues.state, 
                "city"          : formValues.city,
                "zip"           : formValues.zip
            }),
        }

      fetch(
            process.env.REACT_APP_API_URL, 
            request
        ).then((response) => {
            response.json().then( (result) => {
                console.log(result);
                if(result.success === true){
                    setISSubmit(true);
                    setIsError(false);
                    e.target.reset();
                }else if(result.error === true){
                    setIsError(true);
                    setErrorMsg(result.error_message);
                }
            })
        });
    };
    

    if(token == null){
        return <Navigate to="/login"/>
    }

    return (
        <>
            <Header />  
            <section className="my-address-wrap ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <PersonalInfoImage/>
                            <div className="sidebar-tabs">
                                <ul>
                                    <MyAccountLinks/>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="bdr-box">

                                {getValue.map((item, i) => ( 

                                    <div className="myaddress-content" key={i}>
                                        <h2 className="heading-black-sm">
                                            {item.address_type}
                                        </h2>
                                        <div className="myaddress-box">
                                            <div className="d-flex align-items-start">
                                                <div className="custom-radios">
                                                    <div>
                                                    <input type="radio" id="color-1" name="color" value="color-1" checked="" />
                                                    <label htmlFor="color-1">
                                                        <span>
                                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg" alt="Checked Icon" />
                                                        </span>
                                                    </label>
                                                    </div>
                                                </div>
                                                <p className="text-grey">
                                                    {item.address}, {item.address_line1} {item.land_mark}, <br />
                                                    {item.city} {item.state} {item.zip}
                                                </p>
                                            </div>
                                            <div className="address-action d-flex align-items-center">
                                                {/* <Link to="/">
                                                    <span className="material-icons-outlined">
                                                        edit
                                                    </span>
                                                </Link> */}
                                                <Link to="/">
                                                    <span className="material-icons-outlined">
                                                        delete
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                ))} 
                                
                                <div className="add-address">
                                    <button className="btn-bdrorg-lg" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#addressModal">+  ADD NEW ADDRESS</button>
                                </div>  
                                
                            </div>
                        </div> 
                    </div>
                </div>
            </section>

            
            <div className="modal fade" id="addressModal" tabIndex="-1" role="dialog" aria-labelledby="addressModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        { isSubmit && (
                            <div className="alert alert-success" id="success-alert">
                                <strong>Success! </strong> Address Added Successfully.
                            </div>
                        )}     
                          
                        { isError && (
                            <div className="alert alert-danger" id="">
                                <strong> Error ! </strong> {errorMsg}
                            </div>
                        )}                
                        <div className="text-center">
                            <h5 className="modal-heading">My Address</h5>                            
                        </div>

                        <form onSubmit={handleSubmit}>

                            <div className="review-list">
                                <div className="form-row">
                                    <div className="form-group col-md-5">
                                        <label>Type of Address </label>
                                        <div className="form__radio-group">
                                            <input 
                                                type="radio" 
                                                className="form__radio-input" 
                                                id="small" 
                                                name="address_type" 
                                                value="Home"
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="small" className="form__radio-label">
                                                <span className="form__radio-botton"></span>
                                                Home
                                            </label>
                                        </div>
                                        <div className="form__radio-group">
                                            <input 
                                                type="radio" 
                                                className="form__radio-input" 
                                                id="large"
                                                name="address_type" 
                                                value="Office" 
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="large" className="form__radio-label">
                                                <span className="form__radio-botton"></span>
                                                Office
                                            </label>  
                                        </div>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Full Name</label>
                                        <input 
                                            type="text"                                                    
                                            className="form-control"
                                            name='full_name'
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Mobile No.</label>
                                        <input 
                                            type="text"                                                    
                                            className="form-control"
                                            name='mobile_no'
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>House No., Building Name </label>
                                        <input 
                                            type="text"                                                    
                                            className="form-control"
                                            name='address'
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Road Name., Area, Colony </label>
                                        <input 
                                            type="text"                                                    
                                            className="form-control"
                                            name='address_line1'
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Landmark </label>
                                        <input 
                                            type="text"                                                    
                                            className="form-control"
                                            name='land_mark'
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label>State</label>
                                        <input 
                                            type="text"                                                    
                                            className="form-control"
                                            name='state'
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>City </label>
                                        <input 
                                            type="text"                                                    
                                            className="form-control"
                                            name='city'
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Pincode </label>
                                        <input 
                                            type="text"                                                    
                                            className="form-control"
                                            name='zip'
                                            onChange={handleChange}
                                        />
                                    </div>                                
                                </div>                            
                            </div>                        
                            <div className="btn-wrap">
                                <button className="btn-org-lg" type='submit'>Submit</button>
                            </div>

                        </form>

                    </div>
                </div>
                </div>
            </div>

        </>
    )
}

export default MyAddress;