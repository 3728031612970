import React, { useState } from "react";
import Select from "react-select";

export const MobileFilter = ({
  filterByCategory,
  setFilterByCategory,
  filterByColor,
  setFilterByColor,
  filterByWeight,
  setFilterByWeight,
  filterByPrice,
  setFilterByPrice,
  filterBySort,
  setFilterBySort,
}) => {
  const tokenNo = localStorage.getItem("tokenNo");
  const [loading, setLoading] = React.useState(true);
  const [Categories, setCategories] = React.useState("All");
  const [Color, setColor] = React.useState("All");
  const [Price, setPrice] = React.useState("All");
  const [Weight, setWeight] = React.useState("All");

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      token: tokenNo,
      request: "getSettings",
    }),
  };
  React.useEffect(() => {
    async function getFilters() {
      const response = await fetch(
        process.env.REACT_APP_API_URL,
        requestOptions
      );
      const body = await response.json();
      setCategories(body.data.filters.categories);
      setColor(body.data.filters.color);
      setPrice(body.data.filters.price);
      setWeight(body.data.filters.weight);
      setLoading(false);
    }
    getFilters();
  }, []);

  const handleFilterByCategoryChange = (e) => {
    setFilterByCategory(e.value);
  };

  const handleFilterByColorChange = (e) => {
    setFilterByColor(e.value);
  };

  const handleFilterByWeightChange = (e) => {
    setFilterByWeight(e.value);
  };

  const handleFilterByPriceChange = (e) => {
    setFilterByPrice(e.value);
  };

  const handleFilterBySortingChange = (e) => {
    setFilterByPrice(e.value);
  };

  const SortingOption = [
    { value: "yes", label: "Price: High to Low" },
    { value: "no", label: "Price: Low to High" },
  ];

  return (
    <>
      <div className="filter-wrap">
        <div className="form-group">
          <Select
            defaultValue={filterByCategory}
            onChange={handleFilterByCategoryChange}
            disabled={loading}
            options={Categories}
            placeholder="Category"
          />
        </div>
        <div className="form-group">
          <Select
            defaultValue={filterByColor}
            onChange={handleFilterByColorChange}
            disabled={loading}
            options={Color}
            placeholder="Metal Color"
          />
        </div>
        <div className="form-group">
          <Select
            defaultValue={filterByPrice}
            onChange={handleFilterByPriceChange}
            disabled={loading}
            options={Price}
            placeholder="Price"
          />
        </div>
        <div className="form-group">
          <Select
            defaultValue={filterByWeight}
            onChange={handleFilterByWeightChange}
            disabled={loading}
            options={Weight}
            placeholder="Weight"
          />
        </div>
        <div className="form-group">
          <Select
            defaultValue={filterBySort}
            onChange={handleFilterBySortingChange}
            options={SortingOption}
            placeholder="Sort by: Recommended"
          />
        </div>
      </div>
    </>
  );
};
