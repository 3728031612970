import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";

const options = {
  loop: true,
  nav: false,
  dots: true,
  animateOut: "fadeOut",
  animateIn: "fadeIn",
  autoplay: true,
  autoplayTimeout: 10000,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const Banner = () => {
  const [getValue, setValue] = useState([]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        fetch(process.env.REACT_APP_API_URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            request: "getSettings",
          }),
        }).then((response) => {
          response.json().then((result) => {
            setValue(result.data);
          });
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    fetchSettings();
  }, []);

  return (
    <div>
      <section className="banner-wrap">
        <div className="banner-slider">
          {getValue.home_banner && (
            <OwlCarousel className="owl-carousel banner-carousal" {...options}>
              {getValue.home_banner.map((item) => {
                const {
                  id,
                  content_sm,
                  content_lg,
                  thumb,
                  content_md,
                  content_last,
                } = item;
                return (
                  <div
                    key={id}
                    className="item"
                    style={{
                      backgroundImage: `url(${thumb})`,
                    }}
                  >
                    <div className="banner-content">
                      <h2 className="banner-heading-white">
                        <span className="sm">{content_sm}</span>
                        <span className="lg">{content_lg}</span>
                        <span className="md">{content_md}</span>
                        <span className="md">{content_last}</span>
                      </h2>
                      <div className="banner-btn pt-3">
                        <Link to="/products" className="grey-btn">
                          SHOP NOW
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          )}
        </div>
      </section>
    </div>
  );
};

export default Banner;
