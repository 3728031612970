import React, {useState, useEffect, useRef} from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../components/Header';
import { useAuth } from './context/auth.context';



const INITIAL_COUNT = 10
const twoDigits = (num) => String(num).padStart(2, '0')

function SignupOtp() {

  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT)  
  const secondsToDisplay = secondsRemaining % 60
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60
  const minutesToDisplay = minutesRemaining % 60
  //const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60

  const {userDetails} = useAuth();
  //console.log(userDetails);

 
  const initialValues = { signup_otp : ""} 
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const [isSubmit, setISSubmit] = useState(false);
  const [isError, setISError] = useState(false);
  const [isErrorMsg, setISErrorMsg] = useState("");

  

  const [alert, setAlert] = useState(true);
      
  useEffect(() => {
    // when the component is mounted, the alert is displayed for 3 seconds
    setTimeout(() => {
      setAlert(false);
    }, 3000);
  }, []);  

  const handleChange = (e) => {
    //console.log(e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    setFormValues({...formValues, [name] : value});
    //console.log(formValues);
 } 

 const validate = (values) => {
    const errors = {};   
    if(!values.signup_otp){
        errors.signup_otp = "This Field is Required";
    }    
    return errors;
}


const handleSubmit = (e) => {
  e.preventDefault();
  setFormErrors(validate(formValues));

  //console.log(formValues.signup_otp);

    const request =  {
      method : "POST",
      headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
      },
      mode : "cors",
      body: JSON.stringify({
          "request" :  "otp_login",
          "username" :  userDetails.phone,
          "otp" : formValues.signup_otp,
      }),
    }

    fetch(
        process.env.REACT_APP_API_URL, 
        request
    ).then((response) => {
        response.json().then( (res) => {
            console.log(res);
            if(res.success === true){
                localStorage.setItem('tokenNo', res.user.token);
                localStorage.setItem('userName', res.user.display_name);
                setISSubmit(true);
            }else if(res.error === true){
                setISError(true);
                setISErrorMsg(res.error_message)
            }
        })
    });

}


  const handleStart = () => {
    setStatus(STATUS.STARTED)

    const request =  {
      method : "POST",
      headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
      },
      mode : "cors",
      body: JSON.stringify({
          "request" :  "sendOtp",
          "mobile" :  userDetails.phone,
      }),
    }

    fetch(
        process.env.REACT_APP_API_URL, 
        request
    ).then((response) => {
        response.json().then( (result) => {
            console.log(result);
            if(result.success === true){
              localStorage.setItem('tokenNo', result.user.token);
              localStorage.setItem('userName', result.user.display_name);
              setISSubmit(true);
            }else if(result.error === true){
              setISError(true);
              setISErrorMsg(result.error_message)
            }
        })
    });
  }

  const STATUS = {
    STOPPED: <a type='button' onClick={handleStart} className="resend-link" style={{ cursor: "pointer"}}>RESEND OTP</a>,
  }

  const [status, setStatus] = useState(STATUS.STOPPED)

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1)
      } else {
        setStatus(STATUS.STOPPED)
      }
    },
    status === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
  )

  function useInterval(callback, delay) {
    const savedCallback = useRef()
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  if(!userDetails){
    return <Navigate to="/signup"/>
  }

  if(isSubmit){
    return <Navigate to="/dashboard-personal-info"/>
  }

  const tokenValue = localStorage.getItem('tokenNo');

    if(tokenValue != null){
        return <Navigate to="/"/>
    }

  return (
        <>
            <Header />
            <section className="signup-wrap">
                <div className="flex-container">
                    <div className="flex-item-left" style={{ backgroundImage: `url("../assets/images/login-bg.png")`}}>
                        <div className="login-content-left">
                            <p className="login-text">
                                You are at the heart of everything we do.<br />
                                Design to delivery. Experience to queries. <br />
                                Our teams are always there for you.
                            </p>
                        </div>
                    </div>
                    <div className="flex-item-right">
                        <div className="login-content-right">
                            {isError && (
                              <div className="alert alert-danger" id="danger-alert">
                                <strong>{isErrorMsg}</strong>
                              </div> 
                            )}
                            {alert && (
                              <div className="alert alert-success" id="">
                                <strong>OTP </strong>has beed sent to your mobile
                              </div> 
                            )}
                            <div className="login-form">
                                <form>  
                                    <div className="form-group">
                                        <label>OTP</label>
                                        <input type="text" name='signup_otp' className="form-control" onChange={handleChange}/>
                                    </div>
                                    <span className='input_errors'>{formErrors.signup_otp}</span>
                                    <div className="form-row align-items-center justify-content-between">

                                    {status === STATUS.STARTED ? 

                                        <div style={{ color:"#ff0000"}}>
                                            Resend OTP in : {twoDigits(minutesToDisplay)}:{twoDigits(secondsToDisplay)}
                                        </div>
                                    : 
                                    
                                    status
                                    
                                    }

                                        {/* <a href='' className="resend-link">RESEND OTP</a> */}
                                        <button className="btn btn-org" onClick={handleSubmit}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>                
                </div>
            </section>
        </>
    );
}

export default SignupOtp;