import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import Header from "../components/Header";
import MyAccountLinks from "./MyAccountLinks";
import PersonalInfoImage from "./PersonalInfoImage";

function MyOrder() {
  const token = localStorage.getItem("tokenNo");

  const [orderList, setOrderList] = useState([]);
  const [orderStatus, setOrderStatus] = useState(false);

  useEffect(() => {
    //console.log("get id");
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getOrdersList",
        token: token,
      }),
    }).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        setOrderList(result);
        setOrderStatus(true);
      });
    });
  }, []);

  if (token == null) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Header />
      <section className="my-order-wrap ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <PersonalInfoImage />
              <div className="sidebar-tabs">
                <ul>
                  <MyAccountLinks />
                </ul>
              </div>
            </div>
            <div className="col-md-8">
              <div className="bdr-box">
                <div className="myorder-content">
                  {/* <div className="myorder-selectbox">
                                        <div className="form-group">
                                            <select className="form-control">
                                                <option>All Orders</option>
                                                <option>Order 1</option>
                                                <option>Order 2</option>
                                                <option>Order 3</option>
                                                <option>Order 4</option>
                                            </select>
                                        </div>
                                    </div> */}
                  <div className="myorder-list">
                    {orderStatus ? (
                      <ul>
                        {orderList.data.length > 0 ? (
                          orderList.data.map((orderItem, index) => {
                            //console.log(orderItem.cart_info)

                            return (
                              <li key={index}>
                                <h2 className="order-item-id">
                                  Order id- {orderItem.order_info.order_no}/{" "}
                                  {orderItem.order_info.order_date}
                                </h2>

                                {orderItem.cart_info.length > 0
                                  ? orderItem.cart_info
                                      .slice(0, 1)
                                      .map((orderList, index) => {
                                        return (
                                          <div
                                            className="order-item d-flex"
                                            key={index}
                                          >
                                            <div className="order-img">
                                              <img
                                                src={orderList.pdt_image}
                                                alt="img"
                                              />
                                            </div>
                                            <div className="order-detail">
                                              <h3 className="order-item-name">
                                                {orderList.pdt_name}
                                              </h3>
                                              <p className="order-item-code">
                                                Product Code-{" "}
                                                {orderList.pdt_code}
                                              </p>
                                              <p className="order-item-status">
                                                Status-{" "}
                                                <span className="green-text">
                                                  {
                                                    orderItem.order_info
                                                      .order_status
                                                  }
                                                </span>
                                              </p>
                                              <div className="order-btn">
                                                {orderItem.order_info
                                                  .shipping_tracking != null ? (
                                                  <a
                                                    href={
                                                      orderItem.order_info
                                                        .shipping_tracking
                                                    }
                                                    target="_blank"
                                                    className="grey-bdr-btn"
                                                  >
                                                    TRACK
                                                  </a>
                                                ) : (
                                                  ""
                                                )}
                                                {/* <Link
                                                  to="/OrderTracking"
                                                  state={{
                                                    OrderID:
                                                      orderItem.order_info
                                                        .order_no,
                                                  }}
                                                  className="grey-bdr-btn"
                                                >
                                                  TRACK
                                                </Link> */}

                                                {/* <button className="grey-bdr-btn">TRACK</button> */}
                                                {orderItem.order_info
                                                  .order_status ===
                                                "Delivered" ? (
                                                  <button className="grey-bdr-btn">
                                                    RETURN
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                                {orderItem.order_info
                                                  .order_status === "New" ||
                                                orderItem.order_info
                                                  .order_status ===
                                                  "Pending" ? (
                                                  <button className="grey-bdr-btn">
                                                    CANCEL
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })
                                  : ""}
                              </li>
                            );
                          })
                        ) : (
                          <li>No order</li>
                        )}
                      </ul>
                    ) : (
                      <section>
                        <span className="loader-11"> </span>
                      </section>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MyOrder;
