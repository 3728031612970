import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import MyAccountLinks from "./MyAccountLinks";
import PersonalInfoImage from "./PersonalInfoImage";

function Wishlist() {
  const token = localStorage.getItem("tokenNo");

  const [wishlistProducts, setWishlistProducts] = useState([]);
  const [wishlistProductsStatus, setWishlistProductsStatus] = useState(false);
  const [wishlistRemove, setWishlistRemove] = useState(false);

  useEffect(() => {
    //console.log("get id");
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getWishList",
        token: token,
      }),
    }).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        setWishlistProducts(result.data);
        setWishlistProductsStatus(true);
      });
    });
  }, []);

  const delWishItem = (id) => {
    //console.log(id);

    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        token: token,
        request: "removeFromWishlist",
        pid: id,
      }),
    }).then((response) => {
      response.json().then((res) => {
        console.log(res);
        if (res.success === true) {
          setWishlistRemove(true);
        }
      });
    });
  };

  //console.log(wishlistProducts);

  if (token == null) {
    const navigate = useNavigate();
    return navigate("/login", {
      state: { component: window.location.pathname },
    });
  }

  return (
    <>
      <Header />
      <section className="wishlist-wrap ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <PersonalInfoImage />
              <div className="sidebar-tabs">
                <ul>
                  <MyAccountLinks />
                </ul>
              </div>
            </div>
            <div className="col-md-8">
              <div className="bdr-box">
                <div className="wishlist-content">
                  {wishlistRemove && (
                    <div className="alert alert-success" id="success-alert">
                      <strong>Success! </strong> Record Deleted Successfully.
                      Please Refresh page
                    </div>
                  )}
                  <div className="wishlist-table table-responsive">
                    {wishlistProductsStatus ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Price</th>
                            <th scope="col">Stock Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {wishlistProducts.length > 0 ? (
                            wishlistProducts.map((wishItem, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <div className="order-item d-flex align-items-center">
                                      <div className="order-img">
                                        <Link to={`/product/${wishItem.Id}`}>
                                          <img src={wishItem.Image} alt="img" />
                                        </Link>
                                      </div>
                                      <div className="order-detail">
                                        <Link to={`/product/${wishItem.Id}`}>
                                          <h3 className="order-item-name">
                                            {wishItem.Name}
                                          </h3>
                                        </Link>
                                        <p className="order-item-code">
                                          <span>
                                            Product Code: {wishItem.pdt_code}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>₹{wishItem.Price}</td>
                                  <td>{wishItem.Available}</td>
                                  <td>
                                    <div className="wishlist-action d-flex align-items-center">
                                      <a href="javascript:void(0)">
                                        <span className="material-icons-outlined">
                                          add_shopping_cart
                                        </span>
                                      </a>
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() => delWishItem(wishItem.Id)}
                                      >
                                        <span className="material-icons-outlined">
                                          close
                                        </span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={4} align={"center"}>
                                No Product in your wishlist
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <section>
                        <span className="loader-11"> </span>
                      </section>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Wishlist;
