import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function OrderStatus() {
    return (
        <>
            <Header />
            <section className="common-banner" style={{ backgroundImage: `url("../assets/images/Terms-of-use.jpg")` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                        </div>
                    </div>
                </div>
            </section>
            <section className="shipping-section ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="shipping-content-wrap">
                                <div className="shipping-content">
                                    <h2 className="heading-red">
                                        ORDER STATUS
                                    </h2>
                                    <p className="desc-grey">
                                        This website is operated by KD Gold and Diamonds Ltd. Throughout the site, the terms “we”, “us” and “our” refer to KD Gold and Diamonds Ltd. KD Gold and Diamonds Ltd. offers this website, including all information, tools and Services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
                                    </p>                                    
                                </div>                             
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default OrderStatus;