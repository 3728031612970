import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import Header from "../components/Header";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

const Login = (props) => {
  const [loginYes, setLoginYes] = useState(true);
  const initialValues = { login_username: "", login_otp: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setISSubmit] = useState(false);
  const [isError, setISError] = useState(false);
  const [isErrorMsg, setISErrorMsg] = useState("");
  const [alert, setAlert] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [pageLocation, setPageLocation] = useState("");
  const cartItems = localStorage.getItem("dataid");
  const navigate = useNavigate();
  useEffect(() => {
    // when the component is mounted, the alert is displayed for 3 seconds
    setTimeout(() => {
      setAlert(false);
      setISError(false);
    }, 7000);
  }, []);

  const location = useLocation();
  // if (location.state.component) {
  //   setPageLocation(location.state.component);
  // }
  // const { state } = props.history.location.state;

  const handleChange = (e) => {
    //console.log(e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    setFormValues({ ...formValues, [name]: value });
    //console.log(formValues);
  };

  const otpHandleChange = (e) => {
    //console.log(e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    setFormValues({ ...formValues, [name]: value });
    //console.log(formValues);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.login_username) {
      errors.login_username = "This Field is Required";
    }
    if (!values.login_otp) {
      errors.login_otp = "This Field is Required";
    }
    return errors;
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));

    console.log(formValues);

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "sendOtp",
        mobile: formValues.login_username,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        if (result.success === true) {
          setLoginYes(false);
        } else {
          setLoginYes(true);
        }
      });
    });
  };

  setTimeout(() => {
    setIsAlertVisible(false);
  }, 5000);

  const loginOtpSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "otp_login",
        username: formValues.login_username,
        otp: formValues.login_otp,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((response) => {
      response.json().then((res) => {
        //console.log(res);
        if (res.success === true) {
          localStorage.setItem("tokenNo", res.user.token);
          localStorage.setItem("userName", res.user.display_name);
          setISSubmit(true);
        } else if (res.error === true) {
          setISError(true);
          setIsAlertVisible(true);
          setISErrorMsg(res.error_message);
        }
      });
    });
  };

  if (isSubmit) {
    if (
      location.state.component !== undefined ||
      location.state.component !== ""
    ) {
      return <Navigate to={location.state.component} />;
    }
  }

  const token = localStorage.getItem("tokenNo");

  if (token != null) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Header />
      <section className="login-wrap">
        <div className="flex-container">
          <div
            className="flex-item-left"
            style={{ backgroundImage: `url("../assets/images/login-bg.png")` }}
          >
            <div className="login-content-left">
              <p className="login-text">
                You are at the heart of everything we do.
                <br />
                Design to delivery. Experience to queries. <br />
                Our teams are always there for you.
              </p>
            </div>
          </div>
          <div className="flex-item-right">
            <div className="login-content-right">
              <div className="signup-link">
                <p>
                  New to Youlry ? <Link to="/signup">Sign Up</Link>
                </p>
              </div>
              <h2 className="login-heading">LOGIN</h2>
              <div className="login-ways">
                <div className="d-flex">
                  <FacebookLogin
                    appId="610284856927619"
                    autoLoad={false}
                    render={(renderProps) => (
                      <button
                        className="login-via-bg"
                        onClick={renderProps.onClick}
                      >
                        <img src="assets/images/facebook-blue.png" alt="img" />{" "}
                        Login With Facebook
                      </button>
                    )}
                  />
                  <GoogleLogin
                    style={{ opacity: "0" }}
                    clientId="1063048031200-3fs662j73monci6d228h80vimnaeaqpv.apps.googleusercontent.com"
                    buttonText="Login With Google"
                    cookiePolicy={"single_host_origin"}
                    className="login-via-bg"
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <a href="#" className="login-via-bg">
                    <img src="assets/images/apple.svg" alt="img" /> Login With
                    Apple
                  </a>
                </div>
              </div>
              <div className="seprator">Or</div>
              <div className="login-form">
                {isAlertVisible && (
                  <div className="alert alert-danger" id="">
                    {isErrorMsg}
                  </div>
                )}

                <form>
                  {loginYes ? (
                    <>
                      <div className="form-group">
                        <label>
                          Please enter your phone number or email address below.
                          We will send you the OTP.
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="login_username"
                          placeholder="Email or Phone Number"
                          onChange={handleChange}
                        />
                        <span className="input_errors">
                          {formErrors.login_username}
                        </span>
                      </div>
                      <div className="form-row align-items-center justify-content-end">
                        <button className="btn btn-org" onClick={loginSubmit}>
                          Submit
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {alert && (
                        <div className="alert alert-success" id="">
                          <strong>OTP </strong>has beed sent to your mobile
                        </div>
                      )}
                      <div className="form-group">
                        <label>OTP</label>
                        <input
                          type="text"
                          name="login_otp"
                          className="form-control"
                          onChange={otpHandleChange}
                        />
                      </div>
                      <div className="form-row align-items-center justify-content-between">
                        <Link
                          to=""
                          className="resend-link"
                          style={{ cursor: "pointer" }}
                          onClick={loginSubmit}
                        >
                          RESEND OTP
                        </Link>
                        <button
                          className="btn btn-org"
                          onClick={loginOtpSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
