import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Carusal/owl.css";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
let slidesToShow = 4;
const tokenNo = localStorage.getItem("tokenNo");
const PreviousBtn = (props) => {
  console.log(props);
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <ArrowBackIos style={{ color: "black", fontSize: "20px" }} />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  console.log(props);
  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <div className={className} onClick={onClick}>
          <ArrowForwardIos style={{ color: "black", fontSize: "20px" }} />
        </div>
      )}
    </>
  );
};

const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: true,
  // slidesToScroll={3}
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};

const RelatedProducts = (props) => {
  const tokenNo = localStorage.getItem("tokenNo");
  const [width, setWidth] = useState(window.innerWidth);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [wishModalOpen, setWishModalOpen] = useState(false);
  const [addWishModalOpen, setAddWishModalOpen] = useState(false);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    const fetchProductList = async () => {
      try {
        fetch(process.env.REACT_APP_API_URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            request: "getProductList",
            cat_id: props.categoryId,
            featured: "No",
            count: props.count,
          }),
        }).then((response) => {
          response.json().then((result) => {
            setRelatedProducts(result.data);
          });
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    fetchProductList();
  }, []);

  const addWishlistModal = (productId) => {
    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "addToWishlist",
        token: tokenNo,
        pid: productId,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((resp) => {
      resp.json().then((res) => {
        if (res.success === true) {
          setAddWishModalOpen(true);
        } else {
        }
      });
    });
  };

  const addWishModalRemove = () => {
    setAddWishModalOpen(false);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 3;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 4;
  } else {
    slidesToShow = 5;
  }

  return (
    <div>
      <Slider {...carouselProperties}>
        {relatedProducts.map((item) => (
          <MyCard item={item} />
        ))}
      </Slider>
    </div>
  );
};

const WishListItem = ({ item }) => {
  if (tokenNo && item.InWishlist === false) {
    return (
      <a
        key={item.Id}
        href="javascript:void(0)"
        onClick={() => addWishlistModal(item.Id)}
        className="whislist-img"
      >
        <span className="material-icons-outlined wishlist-grey">favorite</span>
      </a>
    );
  } else {
    return (
      <a href="javascript:void(0)" className="whislist-img">
        <span className="material-icons-outlined wishlist-red">favorite</span>
      </a>
    );
  }
};

const MyCard = ({ item }) => {
  return (
    <>
      <div class="seller-slider" key={item.Id}>
        <div class="owl-carousel owl-theme">
          <div class="item" style={{ marginRight: "15px" }}>
            <div class="item-img">
              <Link to={`/product/${item.Id}`} className="product-link">
                <figure>
                  <img src={item.Image} alt="img" />
                </figure>
              </Link>
              <WishListItem item={item} />
            </div>
            <div class="slider-content">
              <h2 class="item-title">{item.Name}</h2>
              <h3 class="item-price">₹{item.Price}</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RelatedProducts;
