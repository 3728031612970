import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Newsletter from './Newsletter';
import { Link ,  useSearchParams } from 'react-router-dom';

function SearchResult() {

   

    const [searchParams, setSearchParams] = useSearchParams();

    const q = searchParams.get('q')

    //console.log(q);


  return (
    <>
        <Header />
        search result show here { q }
        <Newsletter />
        <Footer />
    </>
  )
}

export default SearchResult