import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Newsletter(props) {
  const initialValues = { subscriber_email: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setISSubmit] = useState("");
  const [genSetting, setgenSetting] = useState(null);

  const token = localStorage.getItem("tokenNo");

  const handleChange = (e) => {
    //console.log(e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    setFormValues({ ...formValues, [name]: value });
    //console.log(formValues);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

    if (!values.subscriber_email) {
      errors.subscriber_email = "This Field is Required";
    } else if (!regex.test(values.subscriber_email)) {
      errors.subscriber_email = "Email Format is Wrong";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "SubscribeNewsletter",
        token: token,
        email: formValues.subscriber_email,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          setISSubmit(result);
          e.target.reset();
        } else {
          setISSubmit(result);
        }
      });
    });
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getSettings",
      }),
    }).then((resp) => {
      resp.json().then((res) => {
        if (res.success === true) {
          setgenSetting(res.data);
        } else {
          setgenSetting(null);
        }
      });
    });
  }, []);

  return (
    <>
      <section className="story-wrap ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="story-content text-center">
                <h2 className="heading-black">JOIN THE YOULRY STORY</h2>
              </div>

              {/* {isSubmit && (
                <div className="alert alert-success" id="success-alert">
                  Subscribed Successfully.
                </div>
              )} */}

              <div
                style={{
                  marginTop: "50px",
                  width: "65%",
                  paddingLeft: "16.5%",
                }}
              >
                {(() => {
                  if (isSubmit.success === true) {
                    return (
                      <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <strong>Thank you!</strong> You have been successfully
                        subscribed.
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    );
                  } else if (isSubmit.error === true) {
                    return (
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        {isSubmit.error_message}
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    );
                  } else {
                    return <p></p>;
                  }
                })()}
              </div>

              <form className="story-form" onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="col-9 col-md-8">
                    <div className="form-group ptb-80-40">
                      <input
                        type="email"
                        name="subscriber_email"
                        className="form-control"
                        placeholder="Your Email"
                        onChange={handleChange}
                      />
                    </div>
                    <span className="input_errors">
                      {formErrors.subscriber_email}
                    </span>
                  </div>
                </div>
                <div className="btn-wrap col-auto">
                  <button type="submit" className="btn white-btn">
                    Submit
                  </button>
                </div>
              </form>
              <div className="social-icons ptb-100-30">
                <ul>
                  <li>
                    {genSetting && (
                      <a
                        href={genSetting.fb_url}
                        target="_blank"
                        data-aos="fade-down"
                        data-aos-offset="-200"
                        data-aos-mirror="true"
                        data-aos-anchor-placement="top-center"
                        data-aos-duration="13000"
                        className="aos-init aos-animate"
                      >
                        <img src="../assets/images/fb.png" alt="fb" />
                      </a>
                    )}
                  </li>
                  <li>
                    {genSetting && (
                      <a
                        href={genSetting.insta_url}
                        target="_blank"
                        data-aos="fade-down"
                        data-aos-offset="-100"
                        data-aos-mirror="true"
                        data-aos-anchor-placement="top-center"
                        data-aos-duration="13000"
                        className="aos-init aos-animate"
                      >
                        <img src="../assets/images/insta.png" alt="ig" />
                      </a>
                    )}
                  </li>
                  <li>
                    {genSetting && (
                      <a
                        href={genSetting.linkedin}
                        target="_blank"
                        data-aos="fade-down"
                        data-aos-offset="-100"
                        data-aos-mirror="true"
                        data-aos-anchor-placement="top-center"
                        data-aos-duration="13000"
                        className="aos-init aos-animate"
                      >
                        <img src="../assets/images/link.png" alt="ig" />
                      </a>
                    )}
                  </li>
                  <li>
                    {genSetting && (
                      <a
                        href={genSetting.yt_chanel}
                        target="_blank"
                        data-aos="fade-down"
                        data-aos-offset="-100"
                        data-aos-mirror="true"
                        data-aos-anchor-placement="top-center"
                        data-aos-duration="13000"
                        className="aos-init aos-animate"
                      >
                        <img src="../assets/images/youtube.png" alt="ig" />
                      </a>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Newsletter;
