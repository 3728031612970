import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Circle from "./Circle";
import "../assets/css/OderConfirmation.css";
import $ from "jquery";

const OrderTracking = (props) => {
  const token = localStorage.getItem("tokenNo");
  const [orderDetails, setOrderDetails] = useState([]);
  const [active, setActive] = useState(0);
  const [circle] = useState(4);
  const [width, setWidth] = useState(0);
  const arr = [];
  const statusArr = [
    "New",
    "Processing",
    "Shipped",
    "Out for delivery",
    "Delivered",
    "Canceled",
    "Reject",
    "On Hold",
    "Refunded",
  ];

  for (let i = 0; i < circle; i++) {
    arr.push(
      <Circle classname={i < active + 1 ? "circle active" : "circle"} key={i}>
        {i}
      </Circle>
    );
  }

  const location = useLocation();
  var OrderID = null;
  if (location.state) {
    if (location.state.OrderID) {
      OrderID = location.state.OrderID;
    }
  }

  useEffect(() => {
    setWidth((100 / (circle - 1)) * active);
  }, [circle, active]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getOrderDetails",
        token: token,
        order_no: OrderID,
      }),
    }).then((response) => {
      response.json().then((result) => {
        setOrderDetails(result.data);
        if (result.data.order_status == "New") {
          $("#step-1:not(.active):first").addClass("active");
          $("#step-2:not(.active):first").removeClass("active");
          $("#step-3:not(.active):first").removeClass("active");
          $("#step-4:not(.active):first").removeClass("active");
        } else if (result.data.order_status == "Processing") {
          $("#step-1:not(.active):first").addClass("active");
          $("#step-2:not(.active):first").addClass("active");
          $("#step-3:not(.active):first").removeClass("active");
          $("#step-4:not(.active):first").removeClass("active");
        } else if (
          result.data.order_status == "Out for delivery" ||
          result.data.order_status == "Shipped"
        ) {
          $("#step-1:not(.active):first").addClass("active");
          $("#step-2:not(.active):first").addClass("active");
          $("#step-3:not(.active):first").addClass("active");
          $("#step-4:not(.active):first").removeClass("active");
        } else if (
          result.data.order_status == "Delivered" ||
          result.data.order_status == "Reject" ||
          result.data.order_status == "Canceled"
        ) {
          $("#step-1:not(.active):first").addClass("active");
          $("#step-2:not(.active):first").addClass("active");
          $("#step-3:not(.active):first").addClass("active");
          $("#step-4:not(.active):first").addClass("active");
        }
      });
    });
  }, [OrderID]);

  return (
    <>
      <section className="shipping-section ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <hr></hr>
            </div>
            <div className="col-md-12" style={{ marginTop: "2%" }}>
              My Orders/Tracking
            </div>

            <div className="col-md-12" style={{ marginTop: "4%" }}>
              Order #: {OrderID}
              <div className="tracking-bdr-box">
                {orderDetails && (
                  <div className="myorder-content">
                    <div className="tracking-status">
                      <ul>
                        <li>
                          <p className="promise-text">
                            Estimated Delivery Time:
                            <br />
                            {orderDetails.order_date}
                          </p>
                        </li>
                        <li>
                          <p className="promise-text">
                            Shipping By:
                            <br />
                            BLUEDART | +15658963332
                          </p>
                        </li>
                        <li>
                          <p className="promise-text">
                            Status:
                            <br />
                            Picked by the courier
                          </p>
                        </li>
                        <li>
                          <p className="promise-text">
                            Tracking # :
                            <br />
                            BDQ4578521455222
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-12" style={{ marginTop: "4%" }}>
              <div className="progress-container">
                <div className="progress-content">
                  <ul className="progressbar" id="progress-bar">
                    <li id="step-1">New</li>
                    <li id="step-2">Processing</li>
                    <li id="step-3">Shipped/Out for delivery</li>
                    <li id="step-4">Delivered</li>
                  </ul>
                </div>
              </div>
            </div>
            <Link to="/dashboard-my-order" className="btn-org-lg pull-left">
              BACK TO ORDER
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderTracking;
