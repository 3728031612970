import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import MyAccountLinks from "./MyAccountLinks";
import PersonalInfoImage from "./PersonalInfoImage";

function PersonalInfo() {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [gender, setGender] = useState(null);
  const [dob, setDob] = useState(null);
  const [isSubmit, setISSubmit] = useState(false);

  const tokenNo = localStorage.getItem("tokenNo");
  const navigate = useNavigate();

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        token: tokenNo,
        request: "getUserInfo",
      }),
    }).then((response) => {
      response.json().then((result) => {
        //setGetInfo(result.data);
        if (result.success === true) {
          setFirstName(result.data.first_name);
          setLastName(result.data.last_name);
          setEmail(result.data.user_email);
          setPhone(result.data.primary_contact);
          setGender(result.data.gender);
          setDob(result.data.dob);
        } else {
          return navigate("/login");
        }
      });
    });
  }, []);

  const updateInfo = (e) => {
    e.preventDefault();
    //console.log(firstName, lastName, email, phone, gender, dob);

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        token: tokenNo,
        request: "updateProfile",
        first_name: firstName,
        last_name: lastName,
        dob: dob,
        gender: gender,
        mobile: phone,
        email: email,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((response) => {
      response.json().then((result) => {
        console.log(result);
        if (result.success === true) {
          setISSubmit(true);
        }
      });
    });
  };

  if (tokenNo == null) {
    return navigate("/login");
  }

  return (
    <>
      <Header />
      <section className="personal-info-wrap ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <PersonalInfoImage />
              <div className="sidebar-tabs">
                <ul>
                  <MyAccountLinks />
                </ul>
              </div>
            </div>
            <div className="col-md-8">
              <div className="bdr-box">
                {isSubmit && (
                  <div className="alert alert-success" id="success-alert">
                    <strong>Success! </strong> Record Submitted Successfully.
                  </div>
                )}
                <h2 className="heading-black-sm">Personal Information</h2>
                <form className="personal-info-form" onSubmit={updateInfo}>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={firstName || ""}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Last Name</label>
                      <input
                        type="text"
                        name="last_name"
                        className="form-control"
                        value={lastName || ""}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>Email</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        value={email || ""}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Mobile Number</label>
                      <input
                        type="text"
                        name="contact_no"
                        className="form-control"
                        value={phone || ""}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>Gender</label>
                      <select
                        className="form-control"
                        name="gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="">Choose...</option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Birth Date</label>
                      <input
                        type="date"
                        name="dob"
                        className="form-control"
                        value={dob || ""}
                        onChange={(e) => setDob(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6">
                      <button type="submit" className="btn-org-lg">
                        SAVE
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PersonalInfo;
