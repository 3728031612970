import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CustomButton = (props) => {
  const navigate = useNavigate();
  const componentName = props.componentName;

  function reloadComponent() {
    window.location.reload(false);
  }
  if (componentName) {
    return (
      <button
        style={{
          border: "0px",
          color: "red",
          fontWeight: "600",
          backgroundColor: "transparent",
        }}
        onClick={reloadComponent}
      >
        {props.labelText}
      </button>
    );
  } else {
    return (
      <button
        style={{
          border: "0px",
          color: "red",
          fontWeight: "600",
          backgroundColor: "transparent",
        }}
        onClick={() => navigate("/products")}
      >
        {props.labelText}
      </button>
    );
  }
};

export default CustomButton;
