import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Faq() {
  const [getValue, setGetValue] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        token: "OGMwMTc2MWJkZWRmNjUyNTcwMzAxODFmNWYzM2I3ZGM=",
        request: "getFAQ",
        cat_id: "3",
      }),
    }).then((response) => {
      response.json().then((result) => {
        setGetValue(result.data);
      });
    });
  }, []);

  console.log(getValue);

  return (
    <>
      <Header />
      <section
        className="common-banner"
        style={{ backgroundImage: `url("../assets/images/faq.jpg")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12"></div>
          </div>
        </div>
      </section>

      {/* <section className="faq-section ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="faq-title">
                                <h2>FAQ</h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="faq mt-5" id="accordion">
                            

                                {getValue.map((item, i) => ( 
                                                                  
                                    <div className="card" key={i}>
                                        <div className="card-header" id={`faqHeading-${i}`}>
                                            <div className="mb-0">
                                                <h5 className="faq-title" data-toggle="collapse" data-target={`#faqHeading-${i}`} data-aria-expanded={i===0?"true":"false"} data-aria-controls={`faqHeading-${i}`}>
                                                    <span className="badge">{i+1}</span>{item.content_title}
                                                </h5>
                                            </div>
                                        </div>
                                        <div id={`faqCollapse-${i}`} className="collapse" aria-labelledby={`faqHeading-${i}`} data-parent="#accordion">
                                            <div className="card-body">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                    __html: item.content}} >
                                                </p>   
                                            </div>
                                        </div>
                                    </div>
                                ))}    

                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      <section className="faq-section ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="faq-title">
                <h2>FAQ</h2>
              </div>
            </div>
            <div className="col-md-12">
              <div className="faq mt-5" id="accordion">
                <div className="card">
                  {getValue &&
                    getValue.map((item, i) => {
                      return (
                        <>
                          <div className="card-header" id={`faqHeading-${i}`}>
                            <div className="mb-0">
                              <h5
                                className="faq-title"
                                data-toggle="collapse"
                                data-target={`#faqCollapse-${i}`}
                                data-aria-expanded="true"
                                data-aria-controls={`faqCollapse-${i}`}
                              >
                                <span className="badge">{i + 1}</span>
                                {item.content_title}
                              </h5>
                            </div>
                          </div>
                          <div
                            id={`faqCollapse-${i}`}
                            className="collapse"
                            aria-labelledby={`faqHeading-${i}`}
                            data-parent="#accordion"
                          >
                            <div className="card-body">{item.content}</div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Faq;
