import React from "react";

export default function YoulryPromise() {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="promise-content">
              <h2 className="heading-black">Youlry’s Promise</h2>
              <div className="promise-list">
                <ul>
                  <li>
                    <img src="../assets/images/gold.svg" alt="img" />
                    <p className="promise-text">
                      CERTIFIED GOLD
                      <br />
                      AND DIAMONDS
                    </p>
                  </li>
                  <li>
                    <img src="../assets/images/return.svg" alt="img" />
                    <p className="promise-text">
                      15-DAY, NO QUESTIONS
                      <br />
                      ASKED RETURNS
                    </p>
                  </li>
                  <li>
                    <img src="../assets/images/diamond.svg" alt="img" />
                    <p className="promise-text">
                      PRECIOUS SWAROVSKI
                      <br />
                      STONES
                    </p>
                  </li>
                  <li>
                    <img src="../assets/images/lifetime.svg" alt="img" />
                    <p className="promise-text">LIFETIME EXCHANGE</p>
                  </li>
                  <li>
                    <img src="../assets/images/shipping.svg" alt="img" />
                    <p className="promise-text">
                      INSURED AND FREE
                      <br />
                      SHIPPING
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
