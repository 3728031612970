import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function MyAccountLinks() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const navigate = useNavigate();

  const userLogout = () => {
    localStorage.removeItem("tokenNo");
    localStorage.removeItem("userName");
    localStorage.removeItem("orderId");
    localStorage.removeItem("MERCHANT_STATIC_CONFIG");
    localStorage.removeItem("cartAmt");
    localStorage.removeItem("dataid");
    localStorage.removeItem("pin_code");
  };

  return (
    <>
      <li>
        <Link
          to="/dashboard-personal-info"
          className={
            splitLocation[1] === "dashboard-personal-info" ? "active" : ""
          }
        >
          <img src="assets/images/person.svg" alt="img" /> Personal Information
        </Link>
      </li>
      <li>
        <Link
          to="/dashboard-my-order"
          className={splitLocation[1] === "dashboard-my-order" ? "active" : ""}
        >
          <img src="assets/images/order.svg" alt="img" /> My Orders
        </Link>
      </li>
      <li>
        <Link
          to="/dashboard-my-address"
          className={
            splitLocation[1] === "dashboard-my-address" ? "active" : ""
          }
        >
          <img src="assets/images/address.svg" alt="img" /> My Addresses
        </Link>
      </li>
      <li>
        <Link
          to="/dashboard-wishlist"
          className={splitLocation[1] === "dashboard-wishlist" ? "active" : ""}
        >
          <img src="assets/images/wishlist-org.svg" alt="img" /> Wishlist
        </Link>
      </li>
      <li>
        <Link
          to="/login"
          state={{ component: window.location.pathname }}
          onClick={userLogout}
        >
          <img src="assets/images/logout.svg" alt="img" /> Logout
        </Link>
      </li>
    </>
  );
}

export default MyAccountLinks;
