import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ShippingPolicy from "./pages/ShippingPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import OrderStatus from "./pages/OrderStatus";
import PaymentPolicy from "./pages/PaymentPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import Faq from "./pages/Faq";
import ProductDetail from "./pages/ProductDetail";
import ProductList from "./pages/ProductList";
import ProductListByCollection from "./pages/ProductListByCollection";
import ProductListByCategory from "./pages/ProductListByCategory";
import ProductBestSeller from "./pages/ProductBestSeller";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import PersonalInfo from "./pages/PersonalInfo";
import MyAddress from "./pages/MyAddress";
import MyOrder from "./pages/MyOrder";
import Wishlist from "./pages/Wishlist";
import SignupOtp from "./pages/SignupOtp";
import Cart from "./pages/Cart";
import ProductListByPriceRange from "./pages/ProductListByPriceRange";
import ProductListByGender from "./pages/ProductListByGender";
import ProductListByColor from "./pages/ProductListByColor";
import Delivery from "./pages/Delivery";
import Payment from "./pages/Payment";
import SearchResult from "./pages/SearchResult";
import ScrollToTop from "./ScrollToTop";
import ThankYou from "./pages/ThankYou";
import PageNotFound from "./pages/PageNotFound";
import OurProcess from "./pages/OurProcess";
import OurPromise from "./pages/OurPromise";
import OrderTracking from "./pages/OrderTracking";
import Blogs from "./pages/Blogs";

function Routers() {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/order-status" element={<OrderStatus />} />
          <Route path="/payment-policy" element={<PaymentPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/signupotp" element={<SignupOtp />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/product/:id" element={<ProductDetail />} />
          <Route
            path="/collection/:name"
            element={<ProductListByCollection />}
          />
          <Route path="/category/:name" element={<ProductListByCategory />} />
          <Route path="/products/:query" element={<ProductList />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/:name" element={<ProductList />} />
          <Route path="/products/bestseller" element={<ProductBestSeller />} />
          <Route
            path="/price-range/:name"
            element={<ProductListByPriceRange />}
          />
          <Route path="/gender/:name" element={<ProductListByGender />} />
          <Route path="/color/:name" element={<ProductListByColor />} />
          <Route path="/dashboard-personal-info" element={<PersonalInfo />} />
          <Route path="/dashboard-my-address" element={<MyAddress />} />
          <Route path="/ordertracking" element={<OrderTracking />} />
          <Route path="/dashboard-my-order" element={<MyOrder />} />
          <Route path="/dashboard-wishlist" element={<Wishlist />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/search" element={<SearchResult />} />
          <Route path="/thankyou/:name" element={<ThankYou />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/our-process" element={<OurProcess />} />
          <Route path="/our-promise" element={<OurPromise />} />
        </Routes>
      </Router>
    </div>
  );
}

export default Routers;
