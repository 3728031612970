import React, { useRef, useState } from "react";
import { useNavigate, History } from "react-router-dom";
import Search from "@material-ui/icons/Search";

const SearchBar = ({ History }) => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const searchHandler = (e) => {
    e.preventDefault();
    if (value) {
      navigate("/products", (state = { searchValue: value, replace: true }));
    }
  };

  return (
    <form onSubmit={searchHandler}>
      <div className="search-bar" id="search-bar">
        <input
          type="text"
          className="input"
          id="input"
          placeholder="Search here..."
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <button className="btn" id="search-btn">
          <Search style={{ fill: "red", fontSize: "28" }} />
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
