import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Newsletter from './Newsletter'

function OurPromise() {
  return (
    <>
        <Header/>

        <section className="contact-banner-wrap newInnerBanner" style={{ backgroundImage: "url(../assets/images/ourprocessbanner.png)" }}>
         <div className="contact-banner-content">
            <p className="contact-banner-text">
               Our Promise
            </p>
         </div>
      </section>
      
      <section className="ourpromiseTop secSpacing ">
         <div className="Newcontainer">
            <div className="row">
               <div className="col-md-12">
                  <div className="ContentContainer">
                     <p>
                           The promise of youlry is to enhance beauty and to bring joy. We bring your jewelry that
                           feels like it was made just for you. Crafted and designed according to your choices and
                           your personality. To giove you exactly what you like and not something that you to settle
                           for . With the support and assitance of our highly skilled and experienced team, we pledge
                           to provide you with authentic, genuine products and completely seamless experience.
                     </p>
                     <p>
                        At Youlry, we don’t juse sell  jewelry, we create an experience. An experience of contemporary
                        design, pure gold, impeccable quality, utmost safety, and of course, all the love in the world.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
     <section className="promiseImgListing">
        <div className="Newcontainer">
           <div className="row">
              <div className="col-sm-12">
                  <div className="promiseListingArea">
                     <ul>
                        <li>
                           <div className="promiseImgBox">
                              <figure>
                                 <img src="../assets/images/promise.png" alt=""/>
                              </figure>
                           </div>
                           <div className="promiseListingContent">
                              <h5>Authenticity</h5>
                              <p>
                                 All our jewellery is made with the utmost integrity, and
                                 we will provide you with valid proof of purchase.
                              </p>
                           </div>
                        </li>

                        <li>
                           <div className="promiseImgBox">
                              <figure>
                                 <img src="../assets/images/promise.png" alt=""/>
                              </figure>
                           </div>
                           <div className="promiseListingContent">
                              <h5>Purity</h5>
                              <p>
                                 Only the best and the purest go in crafting our jewellery
                                 Be it BIS Hallmarked pure gold of SGL/IGI certified 
                                 diamonds.
                              </p>
                           </div>
                        </li>

                        <li>
                           <div className="promiseImgBox">
                              <figure>
                                 <img src="../assets/images/promise.png" alt=""/>
                              </figure>
                           </div>
                           <div className="promiseListingContent">
                              <h5>Quality</h5>
                              <p>
                                 Our quality is gold standard. And that’s a promise.
                                 Every single one of our jewellery is for life. That’s!
                                 a promise too
                              </p>
                           </div>
                        </li>

                        <li>
                           <div className="promiseImgBox">
                              <figure>
                                 <img src="../assets/images/promise.png" alt=""/>
                              </figure>
                           </div>
                           <div className="promiseListingContent">
                              <h5>Customer Service</h5>
                              <p>
                                 You are at the heart of everything we do. Design to delivery.
                                 Experience to queries. Our teams are always there for you.
                              </p>
                           </div>
                        </li>


                        <li>
                           <div className="promiseImgBox">
                              <figure>
                                 <img src="../assets/images/promise.png" alt=""/>
                              </figure>
                           </div>
                           <div className="promiseListingContent">
                              <h5>Contemporary Designs</h5>
                              <p>
                                 Our collections are inspired by you. We listen closely and watch
                                 keenly. What you love. And then bring it to life. With expertise. 
                                 And love.
                              </p>
                           </div>
                        </li>

                        <li>
                           <div className="promiseImgBox">
                              <figure>
                                 <img src="../assets/images/promise.png" alt=""/>
                              </figure>
                           </div>
                           <div className="promiseListingContent">
                              <h5>Safety & Security</h5>
                              <p>
                                 We will always ensure your favourite jewellery reaches you
                                 safely and securely. With complimentary insurance. 
                                 That’s on us!
                              </p>
                           </div>
                        </li>


                        <li>
                           <div className="promiseImgBox">
                              <figure>
                                 <img src="../assets/images/promise.png" alt=""/>
                              </figure>
                           </div>
                           <div className="promiseListingContent">
                              <h5>Value</h5>
                              <p>
                                 Choose what captures your heart. Leave the rest 
                                 to us. From packing, shipping, insurance and 
                                 caring for your jewellery.
                              </p>
                           </div>
                        </li>

                        <li>
                           <div className="promiseImgBox">
                              <figure>
                                 <img src="../assets/images/promise.png" alt=""/>
                              </figure>
                           </div>
                           <div className="promiseListingContent">
                              <h5>Convenience</h5>
                              <p>
                                 Pure gold jewellery. That you can wear every day. All our
                                 designs are true certified, hallmarked gold. And wearable 
                                always
                              </p>
                           </div>
                        </li>

                        
                     </ul>
                  </div>
              </div>
           </div>
        </div>

     </section>

        <Newsletter/>
        <Footer/>
    </>
  )
}

export default OurPromise