import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

function PageNotFound() {
  return (
    <>

        <Header/>
            <h1 style={{textAlign : "center", margin : "50px"}}>Page Not Found</h1>

        <Footer/>
    </>
  )
}

export default PageNotFound