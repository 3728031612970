import React, { useEffect, useState } from "react";
import axios from "axios";

const HomeVideo = () => {
  const [getVideo, setVideo] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getSettings",
      }),
    }).then((resp) => {
      resp.json().then((res) => {
        if (res.success === true) {
          setVideo(res.data);
        }
      });
    });
  }, []);

  return (
    <>
      <div className="row video-thumb">
        {getVideo &&
          getVideo.yt_video?.map((item, i) => {
            return (
              <div className="col-md-4">
                <a
                  href="#!"
                  data-video_id={item.vid}
                  data-video={item.vurl}
                  className="nextvideo"
                >
                  <img src={item.thumb} data-video={item.vurl} alt="" />
                </a>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default HomeVideo;
