import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Newsletter from "./Newsletter";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
//import {CartContext} from '../Global/CartContext';

function Cart() {
  const tokenNo = localStorage.getItem("tokenNo");
  const CartId = localStorage.getItem("CartId");
  const estimated = localStorage.getItem("estimated");
  const pin_code = localStorage.getItem("pin_code");
  // console.log(CartId);
  const [error, setError] = useState(null);
  const [cartInfo, setCartInfo] = useState([]);
  const [removeMsg, setRemoveMsg] = useState(false);
  const [productLoader, setProductLoader] = useState(true);
  const [productQuantity, setProductQuantity] = useState(1);
  const [cartQuantity, setCartQuantity] = useState(1);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [couponCode, setCouponCode] = useState(null);
  const [couponButton, setCouponButton] = useState(true);
  const [couponData, setCouponData] = useState(null);
  const [couponText, setCouponText] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "GetCartDetails",
        token: tokenNo ? tokenNo : "",
        cart_id: CartId ? CartId : "",
      }),
    }).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          setCartInfo(result.cart_data);
          setProductLoader(false);
          setButtonStatus(true);
        } else {
          setProductLoader(false);
          setButtonStatus(true);
        }
      });
    });
  }, [CartId]);

  const cartTotal = cartInfo.reduce(
    (total, currentItem) =>
      (total =
        parseFloat(total) +
        parseFloat(currentItem.price * currentItem.quantity)),
    0
  );

  //console.log(cartTotal);

  localStorage.setItem("cartAmt", parseFloat(cartTotal).toFixed(2));

  const incrementHandle = (pid, index) => {
    let newArray = cartInfo;
    cartInfo[index].quantity = parseInt(cartInfo[index].quantity) + 1;
    console.log(cartInfo[index].quantity);
    setCartInfo(newArray);
    setCartQuantity(newArray[index].quantity);
    //console.log('plus',newArray[index]);
    setProductQuantity(productQuantity + 1);

    setProductLoader(true);

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "addToCart",
        token: tokenNo ? tokenNo : "",
        cart_id: CartId,
        pdt_id: pid,
        quantity: cartInfo[index].quantity,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((resp) => {
      resp.json().then((res) => {
        // console.log(res);
        if (res.success === true) {
          setProductLoader(false);
          // setTimeout(() => {
          //     window.location.reload(false);
          // }
          // , 700);
        }
      });
    });
  };

  const decrementHandle = (pid, index) => {
    let newArray = cartInfo;
    //console.log(newArray[index].quantity);
    if (parseInt(newArray[index].quantity) === 1) {
      //setButtonDisabled(true);
      return false;
    } else {
      newArray[index].quantity = parseInt(newArray[index].quantity) - 1;
      //setButtonDisabled(false);
    }

    setCartInfo(newArray);
    setCartQuantity(newArray[index].quantity);
    //console.log('minus',newArray[index]);

    setProductLoader(true);

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "addToCart",
        token: tokenNo ? tokenNo : "",
        cart_id: CartId,
        pdt_id: pid,
        quantity: newArray[index].quantity,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((resp) => {
      resp.json().then((res) => {
        //console.log(res);
        if (res.success === true) {
          setProductLoader(false);
          // setTimeout(() => {
          //     window.location.reload(false);
          // }
          // , 700);
        } else {
          //setWishBadge(false)
        }
      });
    });
  };

  const handleCartDelete = (itemId) => {
    //console.log(itemId);

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "RemoveFromCart",
        token: tokenNo ? tokenNo : "",
        cart_id: CartId,
        id: itemId,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((resp) => {
      resp.json().then((res) => {
        //console.log(res);
        if (res.success === true) {
          setRemoveMsg(true);
          const someArray = JSON.parse(localStorage.getItem("dataid"));
          //console.log(typeof(someArray));
          someArray.find((item, index) => {
            if (item.id == itemId) return index;
          });
          someArray.splice(someArray, 1);
          //console.log(someArray);
          localStorage.setItem("dataid", JSON.stringify(someArray));
          setTimeout(() => {
            window.location.reload(false);
            setRemoveMsg(false);
          }, 1000);
        } else {
          //setWishBadge(false)
        }
      });
    });
  };

  const applyCoupon = () => {
    //console.log(couponCode);

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        token: tokenNo ? tokenNo : "",
        request: "ApplyCoupon",
        coupon_code: couponCode,
        cart_total: cartTotal,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((resp) => {
      resp.json().then((res) => {
        console.log(res);
        setError(null);
        if (res.success === true) {
          setCouponData(res.data.discount_amount);
          setCouponButton(false);
          setCouponText(true);
          localStorage.setItem("cartAmt", cartTotal - res.data.discount_amount);
          localStorage.setItem("coupon", couponCode);
          localStorage.setItem("coupoAmt", res.data.discount_amount);
        } else if (res.error === true) {
          console.log(res.error_message);
          setError(res.error_message);
        } else {
          console.log("Something went wrong!");
          setError("Something went wrong, please contact administrator!");
        }
      });
    });
  };

  return (
    <>
      <Header />

      {productLoader ? (
        <div className="preloaderBg" id="preloader">
          <div className="preloader"></div>
          <div className="preloader2"></div>
        </div>
      ) : (
        <>
          <div id="cart_wrap">
            <div className="stepper-custom">
              <ul>
                <li className="active">
                  <p>
                    <span className="icon">
                      <i className="fa fa-circle-thin" aria-hidden="true"></i>
                    </span>
                    Cart
                  </p>
                </li>
                <li>
                  <p>
                    <span className="icon">
                      <i className="fa fa-circle-thin" aria-hidden="true"></i>
                    </span>
                    Delivery
                  </p>
                </li>
                <li>
                  <p>
                    <span className="icon">
                      <i className="fa fa-circle-thin" aria-hidden="true"></i>
                    </span>
                    Payment
                  </p>
                </li>
              </ul>
            </div>
            <div className="container">
              {removeMsg && (
                <div className="alert alert-success" id="success-alert">
                  Item Deleted Successfully.
                </div>
              )}

              {cartInfo.length > 0 ? (
                <div className="row">
                  {productLoader ? (
                    <div className="preloaderBg" id="preloader">
                      <div className="preloader"></div>
                      <div className="preloader2"></div>
                    </div>
                  ) : (
                    <>
                      <div className="col-lg-7 col-md-12">
                        <div className="card">
                          <div className="cart-total-items">
                            <div className="cart-total-items-header d-flex align-items-center justify-content-between">
                              <p className="mb-0">
                                Total {cartInfo.length} items
                              </p>
                              {/* <p className="mb-0">Your pincode 400706 <span className="edit_pincode">(Edit)</span></p> */}
                            </div>
                            <div className="cart-total-items-content">
                              <ul>
                                {cartInfo
                                  ? cartInfo.map((cartItem, i) => {
                                      // console.log(cartItem);
                                      return (
                                        <li
                                          className="cart-total-single-item d-flex"
                                          key={i}
                                        >
                                          <Link
                                            to={`/product/${cartItem.pdt_id}`}
                                          >
                                            <img
                                              className="img-fluid"
                                              src={cartItem.pdt_image}
                                              alt=""
                                            />
                                          </Link>

                                          <div>
                                            <p className="my-0">
                                              <strong>
                                                {cartItem.pdt_name}{" "}
                                              </strong>
                                              <strong>
                                                ₹{" "}
                                                {parseFloat(
                                                  cartItem.price *
                                                    cartItem.quantity
                                                ).toFixed(2)}
                                              </strong>
                                            </p>
                                            <p className="mb-0">
                                              <span>
                                                Product Code:{" "}
                                                {cartItem.pdt_code}
                                              </span>
                                            </p>
                                            <p className="mb-0">
                                              <span>
                                                Metal Color: {cartItem.color}
                                              </span>
                                            </p>
                                            <p className="mb-0">
                                              <span>
                                                Quantity: {cartItem.quantity}
                                              </span>
                                            </p>
                                            <div
                                              className="mb-0"
                                              style={{ marginTop: "-25px" }}
                                            >
                                              {/* <input value={cartItem.quantity} type="number" /> */}
                                              <div className="qty mt-5">
                                                <button
                                                  type="button"
                                                  className="minus bg-dark"
                                                  id={cartItem.quantity}
                                                  onClick={() =>
                                                    decrementHandle(
                                                      cartItem.pdt_id,
                                                      i
                                                    )
                                                  }
                                                >
                                                  -
                                                </button>
                                                <input
                                                  type="number"
                                                  align="middle"
                                                  className="count"
                                                  name="qty"
                                                  value={cartItem.quantity}
                                                  readOnly
                                                />
                                                <button
                                                  type="button"
                                                  className="plus bg-dark"
                                                  onClick={() =>
                                                    incrementHandle(
                                                      cartItem.pdt_id,
                                                      i
                                                    )
                                                  }
                                                >
                                                  +
                                                </button>
                                              </div>
                                            </div>
                                            <p className="mb-0 justify-content-start">
                                              <span className="mr-5">
                                                Karat : {cartItem.karat}
                                              </span>
                                              <span>
                                                Size: {cartItem.size}{" "}
                                              </span>
                                            </p>
                                            <p className="mb-0 w-75">
                                              <span>Est. delivery between</span>
                                              {cartItem.est_delivery_date}
                                            </p>
                                            <p className="mb-0 w-75">
                                              <button className="p-0 d-flex align-items-center">
                                                <span className="material-icons">
                                                  bookmark_border
                                                </span>
                                                <span className="ml-1">
                                                  MOVE TO WISHLIST
                                                </span>
                                              </button>
                                              <button
                                                className="p-0 d-flex align-items-center"
                                                onClick={() =>
                                                  handleCartDelete(cartItem.id)
                                                }
                                              >
                                                <span className="material-icons">
                                                  delete_outline
                                                </span>
                                                <span className="ml-1">
                                                  REMOVE ITEM
                                                </span>
                                              </button>
                                            </p>
                                          </div>
                                        </li>
                                      );
                                    })
                                  : "loading"}
                              </ul>
                            </div>
                          </div>
                          <Link
                            to="/products"
                            className="d-flex align-items-center mt-4 Continue-Shopping "
                          >
                            <span className="continue_shop material-icons">
                              arrow_back_ios
                            </span>
                            <span>Continue Shopping </span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-5 col-md-12">
                        <div className="card">
                          <div className="cart-summary">
                            <h3>CART SUMMARY</h3>
                            <div className="mt-4 d-flex align-items-center justify-content-between">
                              <p>Product total</p>
                              <p>
                                ₹{" "}
                                {parseFloat(cartTotal - couponData).toFixed(2)}{" "}
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Delivery Charges</p>
                              <p>Free</p>
                            </div>
                            {couponText && (
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Coupon Disount</p>
                                <p>{couponData ? couponData : 0}</p>
                              </div>
                            )}
                            <div className="mt-2 d-flex align-items-center justify-content-between">
                              <strong>Total Amounts</strong>
                              <strong>
                                ₹{" "}
                                {parseFloat(cartTotal - couponData).toFixed(2)}{" "}
                              </strong>
                            </div>
                            <p className="mt-2">(Inclusive of all Taxes)</p>
                          </div>

                          {couponButton && (
                            <div className="giftcoupon-form giftFormWrp ">
                              <div
                                className="gift_form"
                                style={{
                                  border: "1px solid #C0C0C0",
                                  marginTop: "25px",
                                  padding: "15px",
                                }}
                              >
                                <span className="material-icons">
                                  <img
                                    src="assets/images/coupon.svg"
                                    alt=""
                                    className="cardGiftPng"
                                  />
                                </span>
                                <input
                                  type="text"
                                  name="coupon_code"
                                  placeholder="Apply Coupon"
                                  onChange={(e) =>
                                    setCouponCode(e.target.value)
                                  }
                                />
                                <button
                                  type="submit"
                                  onClick={applyCoupon}
                                  style={{ float: "right", marginTop: "5px" }}
                                >
                                  APPLY
                                </button>
                              </div>
                              <span
                                style={{ color: "red", fontStyle: "italic" }}
                              >
                                {error}
                              </span>
                              <p style={{ marginTop: "10px" }}>
                                Use "FLAT1000" to get ₹1,000.00 off on your
                                first purchase
                              </p>
                            </div>
                          )}

                          {/* <div className="discount-form">
                                                            <form action="">
                                                                <div className="cupon-input">
                                                                    <input type="text" placeholder="Apply Coupon" />
                                                                    <button type="submit">ADD</button>
                                                                    <span className="cuponimg"><img src="../assets/images/coupon.svg" alt="" /></span>
                                                                </div>
                                                                <label htmlFor="">Use "FLAT1000" to get ₹1,000.00 off on your first purchase</label>
                                                            </form>
                                                        </div> */}
                          {/* <div className="giftcoupon-form giftFormWrp">
                                                            <form action="">
                                                                <span className="material-icons">
                                                                
                                                                    <img src="../assets/images/giftpng.png" alt="img" className="cardGiftPng" />
                                                                </span>
                                                                <input type="text" placeholder="Gift message or gift wrap" />
                                                                <button type="submit">ADD</button>
                                                            </form>
                                                        </div> */}
                          <Link to="/delivery" className="btn-org-lg">
                            CHECKOUT
                          </Link>

                          {/* <button className="btn-org-lg">
                                                        CHECKOUT
                                                        </button> */}
                          <div className="d-flex align-items-center justify-content-around cardPageLinks">
                            <Link to="/return-policy">Return Policy</Link>
                            <Link to="/shipping-policy">Shipping Policy</Link>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <h5 className="cart-empty"> Your Cart is Empty</h5>
                  <div className="btn-wrap col-auto pt-5">
                    <Link to="/products" className="btn white-btn">
                      Return To Shop
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      <section className="youlry-promise-wrap ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="promise-content">
                <h2 className="heading-black">Youlry’s Promise</h2>
                <div className="promise-list">
                  <ul>
                    <li>
                      <img src="assets/images/gold.svg" alt="img" />
                      <p className="promise-text">
                        CERTIFIED GOLD
                        <br />
                        AND DIAMONDS
                      </p>
                    </li>
                    <li>
                      <img src="assets/images/return.svg" alt="img" />
                      <p className="promise-text">
                        15-DAY, NO QUESTIONS
                        <br />
                        ASKED RETURNS
                      </p>
                    </li>
                    <li>
                      <img src="assets/images/diamond.svg" alt="img" />
                      <p className="promise-text">
                        PRECIOUS SWAROVSKI
                        <br />
                        STONES
                      </p>
                    </li>
                    <li>
                      <img src="assets/images/lifetime.svg" alt="img" />
                      <p className="promise-text">LIFETIME EXCHANGE</p>
                    </li>
                    <li>
                      <img src="assets/images/shipping.svg" alt="img" />
                      <p className="promise-text">
                        INSURED AND FREE
                        <br />
                        SHIPPING
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Newsletter />
      <Footer />
    </>
  );
}

export default Cart;
