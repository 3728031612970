import React, { useEffect, useState } from "react";
import axios from "axios";
import dateFormat from "dateformat";

const BlogSection = () => {
  const [getValue, setGetValue] = useState([]);

  useEffect(() => {
    axios
      .get(`https://blog.youlry.com/wp-json/wp/v2/posts?per_page=3`)
      .then((response) => {
        setGetValue(response.data);
      });
  }, []);

  return (
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="post-content text-center pb-60">
            <h2 class="heading-black">LATEST BLOGS</h2>
          </div>
        </div>
        {getValue &&
          getValue.map((item, i) => {
            return (
              <>
                <div class="col-md-4">
                  <div
                    class="post-card"
                    data-aos-offset="-900"
                    data-aos-duration="8000"
                    style={{ opacity: "9000" }}
                  >
                    <div class="post-img">
                      <figure>
                        <img
                          src={item.yoast_head_json.og_image[0].url}
                          alt="post"
                          class="img-fluid"
                        />
                        <div class="post-date">
                          <span>{dateFormat(item.date, "dd mmm")}</span>
                        </div>
                      </figure>
                    </div>
                    <div class="post-card-content">
                      <h2 class="post-title">{item.title.rendered}</h2>
                      <p class="post-desc ptb-30-60">
                        {item.excerpt.rendered
                          .replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "")
                          .replace("[&hellip;]", "")}
                      </p>
                      <div class="readmore-btn-wrap">
                        <a target="_blank" href={item.link}>
                          Read More{" "}
                          <img src="assets/images/arrow.svg" alt="img" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
};

export default BlogSection;
