import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Newsletter from "./Newsletter";
import { Link, Navigate } from "react-router-dom";
import { PaytmButton } from "./paytm-button/paytmButton";

function Payment() {
  const tokenNo = localStorage.getItem("tokenNo");
  const CartId = localStorage.getItem("CartId");
  const orderId = localStorage.getItem("orderId");
  const estimated = localStorage.getItem("estimated");
  const pin_code = localStorage.getItem("pin_code");

  const [cartInfo, setCartInfo] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "GetCartDetails",
        token: tokenNo ? tokenNo : "",
        cart_id: CartId,
      }),
    }).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        if (result.success === true) {
          setCartInfo(result.cart_data);
        } else {
        }
      });
    });
  }, [CartId]);

  const cartTotal = cartInfo.reduce(
    (total, currentItem) =>
      (total = parseFloat(total) + parseFloat(currentItem.price)),
    0
  );

  if (!orderId) {
    return <Navigate to="/cart" />;
  }

  return (
    <>
      <Header />

      <div id="cart_wrap" className="payment">
        <div className="stepper-custom">
          <ul>
            <li className="checked">
              <p>
                <span className="material-icons">check_circle</span>
                Cart
              </p>
            </li>
            <li className="checked">
              <p>
                <span className="material-icons">check_circle</span>
                Delivery
              </p>
            </li>
            <li className="active">
              <p>
                <span className="icon">
                  <i className="fa fa-circle-thin" aria-hidden="true"></i>
                </span>
                Payment
              </p>
            </li>
          </ul>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="card">
                <div className="cart-summary">
                  <h3>CART SUMMARY</h3>
                  <div className="">
                    {cartInfo.length > 0 ? (
                      <ul>
                        {cartInfo.map((cartItem, i) => (
                          <li className="d-flex" key={i}>
                            <img
                              className="img-fluid"
                              src={cartItem.pdt_image}
                              alt=""
                            />
                            <div className="w-100">
                              <p className="my-0 w-100 d-flex align-items-center justify-content-between">
                                <strong>{cartItem.pdt_name} </strong>
                                <strong>₹ {cartItem.price}</strong>
                              </p>
                              <p className="mb-0 mt-2">
                                <span>Product Code: {cartItem.pdt_code}</span>
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <section>
                        <span className="loader-11"> </span>
                      </section>
                    )}
                  </div>
                  <div className="cart-prices d-flex align-items-center justify-content-between">
                    <p>Product total</p>
                    <p>₹ {cartTotal.toFixed(2)}</p>
                  </div>
                  <div className="cart-prices d-flex align-items-center justify-content-between">
                    <p>Delivery Charges</p>
                    <p>Free</p>
                  </div>
                  <div className="cart-prices  d-flex align-items-center justify-content-between">
                    <strong>Total Amounts</strong>
                    <div className="rate">
                      <strong>₹ {cartTotal.toFixed(2)}</strong>
                      <p>(Inclusive of all Taxex)</p>
                    </div>
                  </div>
                </div>
                {/* <div className="giftcoupon-form">
                                    <form action="">
                                        <span className="material-icons">
                                            card_giftcard
                                        </span>
                                        <input type="text" placeholder="Gift message or gift wrap"/>
                                            <button type="submit">ADD</button>
                                    </form>
                                </div> */}
                <PaytmButton />
                {/* <button className="btn btn-org-lg">
                                    PAY ₹ {cartTotal}
                                </button> */}
                <div className="cart-links d-flex align-items-center justify-content-around cardPageLinks">
                  <Link to="/return-policy">Return Policy</Link>
                  <Link to="/shipping-policy">Shipping Policy</Link>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Newsletter />
      <Footer />
    </>
  );
}

export default Payment;
