import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlogSection from "./BlogSection";

const Blogs = () => {
  return (
    <>
      <Header />
      <section
        class="contact-banner-wrap"
        style={{
          backgroundImage: `url("../assets/images/unsplash_TUH4KRKoUEg.png")`,
          marginLeft: "7%",
          marginRight: "7%",
        }}
      ></section>

      <section class="post-wrap ptb-150">
        <BlogSection />
      </section>
      <Footer />
    </>
  );
};

export default Blogs;
