import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";

function ThankYou() {
  //const orderId = localStorage.getItem('orderId');

  const tokenNo = localStorage.getItem("tokenNo");

  const location = useLocation();
  const { pathname } = location;
  const OrderId = pathname.split("/");

  // console.log(splitLocation[2]);

  localStorage.removeItem("CartId");
  localStorage.removeItem("orderId");
  localStorage.removeItem("dataid");
  localStorage.removeItem("cartAmt");

  const [getValue, setGetValue] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        token: tokenNo,
        request: "getOrderDetails",
        order_no: OrderId[2],
      }),
    }).then((response) => {
      response.json().then((result) => {
        setGetValue(result.data);
      });
    });
  }, []);

  //const cartTotal  = getValue ? getValue.cart.reduce((accumulator, current) => accumulator + parseFloat(current.price * current.quantity), 0) : '';

  //const cartTotal = (getValue.cart ? getValue.cart.reduce((total,currentItem) => total = parseFloat(total) + parseFloat(currentItem.cart.price * currentItem.cart.quantity) , 0 ) : '' );

  //console.log(cartTotal.toFixed(2));

  function CommaFormatted(amount) {
    var delimiter = ","; // replace comma if desired
    var a = amount.split(".", 2);
    var d = a[1];
    var i = parseInt(a[0]);
    if (isNaN(i)) {
      return "";
    }
    var minus = "";
    if (i < 0) {
      minus = "-";
    }
    i = Math.abs(i);
    var n = new String(i);
    var a = [];
    while (n.length > 3) {
      var nn = n.substr(n.length - 3);
      a.unshift(nn);
      n = n.substr(0, n.length - 3);
    }
    if (n.length > 0) {
      a.unshift(n);
    }
    n = a.join(delimiter);
    if (d.length < 1) {
      amount = n;
    } else {
      amount = n + "." + d;
    }
    amount = minus + amount;
    return amount;
  }

  var total = 0;

  if (!OrderId[2]) {
    return <Navigate to="/cart" />;
  }

  useEffect(() => {
    toast.info(
      "Hello, As off now this page contains only static contents. It will be live ASAP!",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  }, []);

  return (
    <>
      <Header />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="container-fluid invoice-container">
        <div class="card">
          <div
            class="card-body"
            style={{
              alignItems: "center",
              display: "flex",
              alignContent: "center",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >
            <h5 class="card-title">
              <img title="Subh" src="/assets/images/thankyou.png"></img>
            </h5>
            <p
              class="card-text"
              style={{
                fontWeight: "600",
                fontSize: "200%",
              }}
            >
              THANK YOU
            </p>
            <p class="card-text">
              Payment Has Been Received. Order Placed Successfully{" "}
            </p>
            <p class="card-text">Ref ID: 540</p>
          </div>
        </div>
        <br></br> <br></br>
        <div class="card">
          <div class="card-body">
            <h5
              class="card-title"
              style={{
                fontWeight: "600",
                fontSize: "120%",
              }}
            >
              YOUR ORDER DETAILS
            </h5>
            <div class="row">
              <div class="col-sm-4">
                <div class="card" style={{ border: "0px" }}>
                  <div class="cart-summary">
                    <div class="cart-summary-prdct-list">
                      <ul>
                        {getValue &&
                          getValue.cart.map((cartValue, index) => (
                            <li class="d-flex" key={index}>
                              <img
                                class="img-fluid"
                                style={{ width: "100px", height: "100px" }}
                                src={cartValue.pdt_image}
                                alt=""
                              />
                              <div class="w-100 ml-3">
                                <p
                                  class="my-0 w-100 d-flex align-items-center justify-content-between"
                                  style={{ paddingTop: "12%" }}
                                >
                                  <strong>{cartValue.pdt_name}</strong>
                                  <strong>₹ {cartValue.price}</strong>
                                </p>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div class="my-3 mt-5 d-flex align-items-center justify-content-between">
                      <p>Subtotal</p>
                      <p>
                        ₹ {getValue ? CommaFormatted(getValue.cart_total) : ""}
                      </p>
                    </div>
                    <div class="my-3 d-flex align-items-center justify-content-between">
                      <strong>Total</strong>
                      <strong>
                        ₹ {getValue ? CommaFormatted(getValue.cart_total) : ""}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="card" style={{ border: "0px" }}>
                  <div class="card-body">
                    <h5 class="card-title">Summary</h5>
                    <p class="card-text">Ref ID: 540</p>
                    <p class="card-text">
                      Order Date :{" "}
                      {getValue
                        ? format(new Date(getValue.order_date), "MMMM dd, yyyy")
                        : ""}
                    </p>
                    <p class="card-text">
                      Order Total : ₹{" "}
                      {getValue ? CommaFormatted(getValue.cart_total) : ""}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card" style={{ border: "0px" }}>
                  <div class="card-body">
                    <h5 class="card-title">Shipping Address</h5>
                    <p class="card-text">
                      {getValue ? getValue.delivery_address : ""}
                    </p>
                    <p class="card-text">
                      {getValue ? getValue.delivery_house_no : ""}
                    </p>
                    <p class="card-text">
                      {getValue ? getValue.delivery_land_mark : ""}
                    </p>
                    <p class="card-text">
                      {getValue ? getValue.delivery_city : ""}
                    </p>
                    <p class="card-text">
                      {" "}
                      {getValue ? getValue.delivery_state : ""}
                    </p>
                    <p class="card-text">
                      {getValue ? getValue.delivery_pin : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ThankYou;
