import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Newsletter from "./Newsletter";

function Contact() {
  const initialValues = { name: "", email: "", phone: "", query: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setISSubmit] = useState(false);

  const handleChange = (e) => {
    //console.log(e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    setFormValues({ ...formValues, [name]: value });
    //console.log(formValues);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

    if (!values.name) {
      errors.name = "This Field is Required";
    }
    if (!values.email) {
      errors.email = "This Field is Required";
    } else if (!regex.test(values.email)) {
      errors.email = "Email Format is Wrong";
    }
    if (!values.phone) {
      errors.phone = "This Field is Required";
    }
    if (!values.query) {
      errors.query = "This Field is Required";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "addContact",
        token: "aflkadflkadelkf",
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone,
        query: formValues.query,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          setISSubmit(true);
          e.target.reset();
        } else {
          setISSubmit(false);
        }
      });
    });
  };

  return (
    <>
      <Header />
      <section
        className="contact-banner-wrap"
        style={{
          backgroundImage: `url("${window.location.origin}/assets/images/contact.png")`,
        }}
      >
        <div className="contact-banner-content">
          <p className="contact-banner-text">
            We would like <br />
            to hear from you...
          </p>
        </div>
      </section>

      <section className="contact-form-wrap ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrum-wrap">
                <ul>
                  <li>
                    <Link to="/">HOME</Link>
                  </li>
                  <li>
                    <Link to="/contact">CONTACT</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="customer-delight-wrap">
                <h2 className="heading-org">Customer Delight</h2>
                <p className="text-black-sm">
                  Call us at +800-270-2636 ( 9 AM to 10 PM., 7 Days a Week )
                </p>
                <p className="text-black-sm">Write to us at: cs@youlry.com</p>
                <p className="text-black-sm">Whatsapp: +91 82601 20120</p>
              </div>
              <div className="contact-form">
                <div className="heading-wrap">
                  <h2 className="heading-org">
                    Have a Question? <br></br>
                  </h2>
                </div>
                {isSubmit ? (
                  <div className="alert alert-success" id="success-alert">
                    <strong>Success! </strong> Record Submitted Successfully.
                  </div>
                ) : (
                  false
                )}
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <label className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter Name"
                        onChange={handleChange}
                      />
                      <span className="input_errors">{formErrors.name}</span>
                    </div>
                  </div>

                  <div className="form-row">
                    <label className="col-sm-2 col-form-label">Email</label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="Enter Email"
                        onChange={handleChange}
                      />
                      <span className="input_errors">{formErrors.email}</span>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="col-sm-2 col-form-label">Phone</label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="Enter Phone"
                        onChange={handleChange}
                      />
                      <span className="input_errors">{formErrors.phone}</span>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="col-sm-2 col-form-label">Query</label>
                    <div className="col-sm-10">
                      <textarea
                        className="form-control"
                        name="query"
                        rows="1"
                        onChange={handleChange}
                      ></textarea>
                      <span className="input_errors">{formErrors.query}</span>
                    </div>
                  </div>
                  <div className="form-row align-items-center justify-content-center m-0">
                    <button type="submit" className="btn btn-org">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />

      <Footer />
    </>
  );
}

export default Contact;
