import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Newsletter from './Newsletter'

function OurProcess() {
  return (
    <>
        <Header/>

        <section className="contact-banner-wrap newInnerBanner" style={{ backgroundImage: "url(../assets/images/ourprocessbanner.png)"}}>
         <div className="contact-banner-content">
            <p className="contact-banner-text">
               Our Process
            </p>
         </div>
      </section>

      <section className="ourprocsTop secSpacing ">
         <div className="Newcontainer">
            <div className="row">
               <div className="col-md-12">
                  <div className="ContentContainer">
                     <p>
                        Every single stone and every single piece. Every single design, from the dreaming of it
                        to its coming to life on you …is a promise. Which we intend to keep. For ever. That we will.
                        only and always ensure that your YOULRY is the purest, most authentic and beautifully 
                        crafted piece of art there can be. And it will always, always reach you safely, securely and
                        conveniently.
                     </p>
                     <p>
                        <strong> Like we said. It is a promise. That we intend to make and keep. Always.</strong>
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section className="pillar_Authenticity">
         <div className="pillarImgBox">
            <div className="img_Holder" style={{ backgroundImage: "url(../assets/images/pillarImg.png)" }}></div>
         </div>
         <div className="pillar_AuthContent text-center">
            <div className="NewTopSmallHeading ">
               <h4>
                  Pillar of Authenticity
               </h4>
            </div>
            <div className="ContentContainer">
               <p>
                  Quality, of the highest class, is one of the major pillars of Youlry.
               </p>
               <p>
                  Quality Control is strictly followed at each and every stage from
                  sourcing to crafting, with well-defined guidelines being observed 
                  across the manufacturing and distribution processes and extending
                  to designing, maintaining and upgrading the showrooms.
               </p>
            </div>
         </div>
      </section>

      <section className="theProcessSec secSpacing pb-0">
         <div className="Newcontainer">
             <div className="row">
                 <div className="col-sm-12">
                    <div className="NewTopSmallHeading text-center">
                        <h4>
                            The Process
                        </h4>
                     </div>
                 </div>
             </div>
            <div className="row">
               <div className="col-sm-12">
                  <div className="theProcessMain">
                     <div className="videoContainer">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/tXnEssTzKBU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                     </div>
                     <div className="theProcessListing">
                        <ul>
                           <li>
                              Quality Checks are regularly conducted at three points: Hallmarking Centre, Diamond Certification Centre and Distribution Centres.
                           </li>
                           <li>
                              The last and final check happens at our Distribution Centres, before the products are dispatched. This ensures 100% inspection at every level of the entire customer value chain.
                           </li>
                           <li>
                              Once the product reaches the Distribution Centre, it is verified with respect to metal weights, stones/diamonds, finish and even its overall size. It is also checked thoroughly for any damage from transit.
                           </li>
                           <li>
                              All Hallmarking and Diamond certifications are re-verified here at this stage, including the engraved markings showcasing the certifications.
                           </li>
                           <li>
                              Once everything is verified, each product gets a unique code that has the complete detail of all the certifications  and verifications. 
                           </li>
                           <li>
                              Over and above all these verifications, the Quality Control team regularly conducts random pick-up tests  of 10-20%  the verified products across the retail showrooms. This is done to ensure a thorough checking process that is continuously focused on spotting and eliminating even the most minor faults or blemishes. Regular melting of some of the products in order to check for purity standards is a continuous process as well.
                           </li>
                        </ul>
                     </div>
                     <div className="theProcessMiddleBox">
                        <figure className="theProces_Img_wrp">
                           <img src="assets/images/theProcess.png" alt=""/>
                        </figure>
                        <div className="theProcessMiddlePra">
                            <p>
                                Finally, the jewellery is presented to you. In perfect sync with the youlry ideology  of quality and  craftsmanship. Every single pure gold or bejewelled masterpiece that you hold and love has astory  woven around it and A story of deep dedication  to craft, unshakeable quality and mind  numbingly beautiful design. Welcome to the world of quality and trust Welcome to the world of Youlry.
                             </p>
                             <strong className="happyShoping">Happy Shopping!</strong>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

        <Newsletter/>
        <Footer/>
    </>
  )
}

export default OurProcess