import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import Favorite from "@material-ui/icons/Favorite";
import Wishlist from "../pages/Wishlist";
import SearchBar from "./SearchBar";
import MobileHeader from "./Header/MobileHeader";
import jQuery from "jquery";
import { useRef } from "react";

function Header() {
  // const {qty} = useContext(CartContext);
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  //const [searchState, setSerchState] = useState(false);
  const [getCollectionValue, setGetCollectionValue] = useState([]);
  const [getCategoryValue, setGetCategoryValue] = useState([]);
  const [genSetting, setgenSetting] = useState(null);
  // const [cartInfo, setCartInfo] = useState([]);
  const [cartLength, setCartLength] = useState(0);

  const token = localStorage.getItem("tokenNo");
  const CartId = localStorage.getItem("CartId");
  const dataID = JSON.parse(localStorage.getItem("dataid"));

  const [loggedInName, setLoggedInName] = useState(null);

  const [collection, setCollection] = useState([]);
  const [menuSetting, setMenuSetting] = useState([]);

  const ref = useRef(null);

  useEffect(() => {
    const ele = ref.current;
    const searchbar = document.querySelector("#search-bar");
    const searchbtn = document.querySelector("#search-btn");
    const inputField = document.querySelector("#input");

    searchbtn.addEventListener("click", (event) => {
      searchbar.classList.toggle("active");
      inputField.focus();
    });
  }, []);

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        fetch(process.env.REACT_APP_API_URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            request: "getCollectionsList",
          }),
        }).then((response) => {
          response.json().then((result) => {
            setCollection(result.data);
          });
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    fetchCollection();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        fetch(process.env.REACT_APP_API_URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            request: "getSettings",
          }),
        }).then((response) => {
          response.json().then((result) => {
            setMenuSetting(result.data.filters.categories);
          });
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    cartValue();
  }, [CartId]);

  const cartValue = () => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "GetCartDetails",
        token: token ? token : "",
        cart_id: CartId ? CartId : "",
      }),
    }).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          setCartLength(result.cart_data.length);
        }
        if (
          response.status === "400" &&
          response.statusText === "Bad Request"
        ) {
          setProductLoader(false);
          setButtonStatus(true);
        }
      });
    });
  };

  const dataLen = JSON.parse(cartLength);

  const handleChange = ({ target: { value } }) => {
    setSearchValue(value);
  };

  //const navigate = useNavigateParams();

  const keyPressHandler = (e) => {
    if (e.which === 13) {
      //navigate("/products", { itemId: 86 });
      <Link
        to={"/products"}
        state={{
          catId: "Vivek Singh",
        }}
      />;
    }
  };

  const toggleMenu = () => {
    var mobileMenu = document.getElementById("siteMenu");
    mobileMenu.classList.toggle("OpenNav");
    var body = document.body;
    body.classList.toggle("overflow_hidden");
    var togglemenuIcon = document.getElementById("menuIcon");
    togglemenuIcon.classList.toggle("active");
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getCollectionsList",
      }),
    }).then((response) => {
      response.json().then((result) => {
        setGetCollectionValue(result.data);
      });
    });
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getCategoryList",
        featured: "Yes",
      }),
    }).then((response) => {
      response.json().then((res) => {
        setGetCategoryValue(res.data);
      });
    });
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getSettings",
      }),
    }).then((resp) => {
      resp.json().then((res) => {
        if (res.success === true) {
          setgenSetting(res.data);
        } else {
          setgenSetting(null);
        }
      });
    });
  }, []);

  jQuery(".dropdown-submenu > a").on("click", function (e) {
    var submenu = jQuery(this);
    jQuery(".dropdown-submenu .dropdown-menu").removeClass("show");
    submenu.next(".dropdown-menu").addClass("show");
    e.stopPropagation();
  });

  jQuery(".dropdown").on("hidden.bs.dropdown", function () {
    jQuery(".dropdown-menu.show").removeClass("show");
  });

  function refreshPage() {
    window.location.href = `/category/${items.Slug}`;
  }
  const navigateToLogin = () => {
    navigate("/login", { state: { component: window.location.pathname } });
  };

  return (
    <div className="site-header">
      <div className="header-top">
        <div className="row row-center">
          <div className="col-2 col-md-2">
            <MobileHeader />
            <div className="phone-no-desktop">
              {genSetting && (
                <a href={`tel:${genSetting.site_phone}`} className="phn-link">
                  <img src="../assets/images/phone.png" alt="phone" />
                  {genSetting.site_phone}
                </a>
              )}
            </div>
          </div>
          <div className="col-7 col-md-8">
            {/* <input
                type="text"
                className="input"
                id="input"
                value={searchValue}
                onKeyDown={keyPressHandler}
                onChange={handleChange}
                placeholder="Search here..."
              />
              <button className="btn" id="search-btn">
                <img src="../assets/images/search.svg" alt="search" />
              </button> */}

            <SearchBar />
          </div>
          <div className="col-3 col-md-2">
            <div className="headtop-icons d-flex align-items-center justify-content-end">
              {token ? (
                <div className="user-icon">
                  <Link to="/dashboard-personal-info">
                    <img
                      src="../assets/images/user.svg"
                      alt="user"
                      style={{ cursor: "pointer" }}
                    />
                  </Link>
                </div>
              ) : (
                <div className="user-icon">
                  <Link to="/">
                    <img src="../assets/images/user.svg" alt="user" />
                  </Link>
                  <div className="user-account-detail">
                    <div className="user-account-content">
                      <h2 className="heading-black">Your Account</h2>
                      <p className="text-black">
                        Access account & manage your orders.
                      </p>
                      <div className="btn-wrap">
                        <Link to="/signup" className="org-btn-sm">
                          Sign Up
                        </Link>
                        <button
                          onClick={navigateToLogin}
                          className="orgbdr-btn-sm"
                        >
                          Log In
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="whislist-icon">
                {token != null ? (
                  <Link
                    to="/dashboard-wishlist"
                    state={{ component: window.location.pathname }}
                  >
                    <Favorite
                      style={{
                        fill: "red",
                        stroke: "red",
                        fontSize: "28",
                        border: "0px",
                      }}
                    />
                  </Link>
                ) : (
                  <button
                    onClick={navigateToLogin}
                    style={{ border: "0px", backgroundColor: "transparent" }}
                  >
                    <Favorite
                      style={{
                        fill: "red",
                        stroke: "red",
                        fontSize: "28",
                        border: "0px",
                      }}
                    />
                  </button>
                )}

                {/* <Link
                  to="/dashboard-wishlist"
                  state={{ component: window.location.pathname }}
                >
                  
                  <Favorite
                    style={{ fill: "red", stroke: "red", fontSize: "28" }}
                  />
                </Link> */}
              </div>
              <div className="cart-icon">
                <Link to="/cart">
                  <img src="../assets/images/cart.svg" alt="img" />
                  <span className="counter">{dataLen ? dataLen : "0"}</span>
                </Link>
                <button
                  style={{ display: "none" }}
                  onClick={cartValue}
                  id="hiddenBtn"
                >
                  Hidden Button
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg">
        <Link to="/" className="navbar-brand">
          <img src="../assets/images/logo.png" alt="logo" />
        </Link>
        <button className="toggleMenu" onClick={toggleMenu}>
          <div className="icon">
            <div className="menu-icon move-left close-icon" id="menuIcon">
              <span></span>
            </div>
          </div>
        </button>
        <div className="site-menu-desktop">
          <ul className="navbar-nav">
            <li className="nav-item dropdown-megamenu">
              <Link to="/" className="nav-link">
                COLLECTIONS
              </Link>
              <div className="dropdown-content">
                <div className="container">
                  <div className="row">
                    {getCollectionValue.map((item, i) => {
                      return (
                        <div className="col-md-3" key={i}>
                          <div className="collection-list">
                            <Link
                              to={`/collection/${item.Slug}`}
                              className="collection-list-img"
                            >
                              <figure>
                                <img
                                  src={item.Image}
                                  alt="img"
                                  className="img-fluid"
                                  style={{
                                    width: "257",
                                    height: "227",
                                    borderRadius: "15",
                                  }}
                                />
                              </figure>
                            </Link>
                            <h2 className="collection-title">{item.Name}</h2>
                            {/* <p className="collection-price">
                                                    Starts from ₹4,127.99
                                                </p> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item dropdown-megamenu">
              <Link to="/" className="nav-link">
                JEWELRY
              </Link>
              <div className="dropdown-content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="jewellery-list">
                        <h2 className="list-heading">Shop by Category</h2>
                        <ul>
                          <li>
                            <Link to="/products" replace>
                              All
                            </Link>
                          </li>
                          {getCategoryValue.map((items, i) => {
                            return (
                              <li key={i}>
                                <Link to={`/category/${items.Slug}`}>
                                  {items.CatName}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="jewellery-list pb-15">
                        <h2 className="list-heading">Gender</h2>
                        <ul>
                          <li>
                            <Link to="/gender/Female">Women</Link>
                          </li>
                          <li>
                            <Link to="/gender/Male">Men</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="jewellery-list">
                        <h2 className="list-heading">Metal Color</h2>
                        <ul>
                          <li>
                            <Link to="/color/Yellow" replace>
                              Yellow
                            </Link>
                          </li>
                          <li>
                            <Link to="/color/White" replace>
                              White
                            </Link>
                          </li>
                          <li>
                            <Link to="/color/Rose" replace>
                              Rose
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="jewellery-list">
                        <h2 className="list-heading">Price Band</h2>
                        <ul>
                          <li>
                            <Link to="/price-range/4000-20000">₹4K - ₹20K</Link>
                          </li>
                          <li>
                            <Link to="/price-range/20000-40000">
                              ₹20K - ₹40K
                            </Link>
                          </li>
                          <li>
                            <Link to="/price-range/40000-60000">
                              ₹40K - ₹60
                            </Link>
                          </li>
                          <li>
                            <Link to="/price-range/60000-80000">
                              ₹60K - ₹80K
                            </Link>
                          </li>
                          <li>
                            <Link to="/price-range/80000-100000">
                              ₹80K - ₹1L
                            </Link>
                          </li>
                          <li>
                            <h2 className="txt-bg-color">
                              <Link to="/products/available">
                                Ready to Ship
                              </Link>
                            </h2>
                            <h2
                              className="txt-bg-color"
                              style={{ marginTop: "-12px" }}
                            >
                              <Link to="/products/bestseller">
                                Best Sellers
                              </Link>
                            </h2>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3">
                      {genSetting && (
                        <div className="jewellery-list">
                          <Link to="/" className="jewellery-list-img">
                            <figure>
                              <img
                                src={genSetting.jewellery_menu_image}
                                alt="img"
                                className="img-fluid"
                                style={{
                                  width: "257",
                                  height: "227",
                                  borderRadius: "5px",
                                }}
                              />
                            </figure>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link">
                ABOUT
              </Link>
            </li>
            <li className="nav-item">
              <a
                href="https://blog.youlry.com/"
                target="_blank"
                className="nav-link"
              >
                BLOG
              </a>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link">
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Header;
