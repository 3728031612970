import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Header from "../components/Header";
import { useAuth } from "./context/auth.context";

function SignUp() {
  const [userGoogleToken, setUserGoogleToken] = useState(null);
  const [userFbToken, setUserFbToken] = useState(null);

  const initialValues = {
    signup_name: "",
    sigup_email: "",
    signup_phone: "",
    signup_terms: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setISSubmit] = useState(false);
  const [isError, setISError] = useState(false);
  const [isErrorMsg, setISErrorMsg] = useState("");

  const { setValues } = useAuth();

  const handleChange = (e) => {
    //console.log(e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    setFormValues({ ...formValues, [name]: value });
    //console.log(formValues);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

    if (!values.signup_name) {
      errors.signup_name = "This Field is Required";
    }
    if (!values.sigup_email) {
      errors.sigup_email = "This Field is Required";
    } else if (!regex.test(values.sigup_email)) {
      errors.sigup_email = "Email Format is Wrong";
    }
    if (!values.signup_phone) {
      errors.signup_phone = "This Field is Required";
    }
    if (!values.signup_terms) {
      errors.signup_terms = "Checkbox is Required";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));

    const userDetails = {
      name: formValues.signup_name,
      email: formValues.sigup_email,
      phone: formValues.signup_phone,
      message: "OTP has beed sent to your mobile",
    };

    setValues(userDetails);

    var firstName = formValues.signup_name.split(" ").slice(0, -1).join(" ");
    var lastName = formValues.signup_name.split(" ").slice(-1).join(" ");

    if (firstName === "") {
      firstName = lastName;
      lastName = "";
    }

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "signup",
        first_name: firstName,
        last_name: lastName,
        email: formValues.sigup_email,
        mobile: formValues.signup_phone,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((response) => {
      response.json().then((result) => {
        console.log(result);
        if (result.success === true) {
          setISSubmit(true);
          e.target.reset();
        } else if (result.error === true) {
          setISError(true);
          setISErrorMsg(result.error_message);
        }
      });
    });
  };

  if (isSubmit) {
    return <Navigate to="/signupotp" />;
  }

  const responseGoogle = (response) => {
    //console.log('Google Login info', response);

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "oAuthLogin",
        outh_token: response.accessToken,
        email: response.Du.tv,
        first_name: response.Du.VX,
        last_name: response.Du.iW,
        profile_image: response.Du.SM,
        outh_provider: "Google",
        auth_data: response,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        localStorage.setItem("tokenNo", result.user.token);
        localStorage.setItem("userName", result.user.display_name);
        setUserGoogleToken(result.user.token);
      });
    });
  };

  const responseFacebook = (response) => {
    //console.log('Login info',response);

    const firstName = response.name.split(" ").slice(0, -1).join(" ");
    const lastName = response.name.split(" ").slice(-1).join(" ");

    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "oAuthLogin",
        outh_token: response.accessToken,
        email: response.email,
        first_name: firstName,
        last_name: lastName,
        profile_image:
          "https://graph.facebook.com/" + response.id + "/picture?type=large",
        outh_provider: "Facebook",
        auth_data: response,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        localStorage.setItem("tokenNo", result.user.token);
        localStorage.setItem("userName", result.user.display_name);
        setUserFbToken(result.user.token);
      });
    });
  };

  const componentClicked = (data) => {
    console.log(data);
  };

  if (userGoogleToken || userFbToken) {
    return <Navigate to="/dashboard-personal-info" />;
  }

  const tokenValue = localStorage.getItem("tokenNo");

  if (tokenValue != null) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Header />
      <section className="signup-wrap">
        <div className="flex-container">
          <div
            className="flex-item-left"
            style={{ backgroundImage: `url("../assets/images/login-bg.png")` }}
          >
            <div className="login-content-left">
              <p className="login-text">
                You are at the heart of everything we do.
                <br />
                Design to delivery. Experience to queries. <br />
                Our teams are always there for you.
              </p>
            </div>
          </div>
          <div className="flex-item-right">
            <div className="login-content-right">
              <div className="signup-link">
                <p>
                  Already have an account ? <Link to="/login">Login</Link>
                </p>
              </div>
              <h2 className="login-heading">SIGN UP</h2>
              <div className="login-ways">
                {/* <div className="d-flex">
                 
                  <FacebookLogin
                    appId="610284856927619"
                    autoLoad={false}
                    fields="name,email,picture"
                    onClick={componentClicked}
                    callback={responseFacebook}
                    cssclassName="login-via-bg"
                    textButton="Signup With Facebook"
                    icon="fa-facebook-square"
                  />
                  <GoogleLogin
                    clientId="1063048031200-3fs662j73monci6d228h80vimnaeaqpv.apps.googleusercontent.com"
                    buttonText="Signup With Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                    className="login-via-bg"
                  />
                </div> */}
                <div className="d-flex">
                  <FacebookLogin
                    appId="610284856927619"
                    autoLoad={false}
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <button
                        className="login-via-bg"
                        onClick={renderProps.onClick}
                      >
                        <img src="assets/images/facebook-blue.png" alt="img" />{" "}
                        Signup With Facebook
                      </button>
                    )}
                  />
                  <GoogleLogin
                    clientId="1063048031200-3fs662j73monci6d228h80vimnaeaqpv.apps.googleusercontent.com"
                    buttonText="Signup With Google"
                    style={{ opacity: "0" }}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                    className="login-via-bg"
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <a href="#" className="login-via-bg">
                    <img src="assets/images/apple.svg" alt="img" /> Signup With
                    Apple
                  </a>
                </div>
              </div>
              <div className="seprator">Or</div>
              {isError && (
                <div className="alert alert-danger" id="success-alert">
                  {isErrorMsg}
                </div>
              )}
              <div className="login-form">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      name="signup_name"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <span className="input_errors">
                      {formErrors.signup_name}
                    </span>
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      name="sigup_email"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <span className="input_errors">
                      {formErrors.sigup_email}
                    </span>
                  </div>
                  <div className="form-group">
                    <label>Mobile Number</label>
                    <input
                      type="text"
                      name="signup_phone"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <span className="input_errors">
                      {formErrors.signup_phone}
                    </span>
                  </div>
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      name="signup_terms"
                      className="form-check-input"
                      id="login-checkbox"
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="login-checkbox"
                    >
                      I Accept Youlry <Link to="/">Terms of Service</Link>
                    </label>
                  </div>
                  <span className="input_errors">
                    {formErrors.signup_terms}
                  </span>
                  <div className="form-row align-items-center justify-content-center">
                    <button type="submit" className="btn btn-org">
                      SIGN UP
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SignUp;
