import React, { useState, useContext, createContext } from "react";


// INITIALIZE 1: CREATE AUTH CONTEXT
const AuthContext = createContext();

// CONTEXT WRAPPER: PROVIDES AUTH
function AuthProvider({ children }) {
    // INITIALIZE 2: DEFINE STATES

    const [userDetails, setUserDetails] = useState(null);
    

    const setValues = (payload) => {
        setUserDetails(payload);
    }
    
    return (
        <AuthContext.Provider value={{  setValues, userDetails }}>{children}</AuthContext.Provider>
    );
}
export const useAuth = () => useContext(AuthContext);

// EXPORT CONTEXT
export default AuthProvider;
