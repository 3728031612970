import CartContextProvider from "./Global/CartContext";

import Routers from "./Routes";

function App() {
  return (
    <div className="">
      <CartContextProvider>
        <Routers />
      </CartContextProvider>
    </div>
  );
}

export default App;
