import React, { useState, useEffect } from "react";

const Circle = ({ classname, children }) => {
  const token = localStorage.getItem("tokenNo");

  return (
    <>
      <div className="main_container">
        <div className={classname}>{children}</div>
      </div>
    </>
  );
};

export default Circle;
