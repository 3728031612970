import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Newsletter from "./Newsletter";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <Header />

      <section
        className="contact-banner-wrap newInnerBanner"
        style={{
          backgroundImage: "url(../assets/images/ourprocessbanner.png)",
        }}
      >
        <div className="contact-banner-content">
          <p className="contact-banner-text">Wecome to Youlry!</p>
        </div>
      </section>

      <section className="wlcmYourlySec secSpacing pb-5">
        <div className="Newcontainer">
          <div className="row">
            <div className="col-md-6">
              <figure className="yrlyImgwrp">
                <img src="../assets/images/aboutmain.png" alt="" />
              </figure>
            </div>
            <div className="col-md-6">
              <div className="ContentContainer">
                <p>
                  Wearing Jewelry is like wearing your personality in sparkling
                  designs. That we watch , listen and craft carefully. Bringing
                  Your story to life. We’re your jewelry. We’re your youlry.
                </p>
                <p>
                  Our Collections are young, effortless and chic. Just like you.
                  Versatile. going from delicate , subtle to contemporary and
                  bold. Crafted in white, yellow and rose gold. Matching your
                  every mood and every vibe. After all you are our muse.
                </p>
                <p>So get used to jewelry , that says it all.</p>
                <Link to="/products" className="white-btn">
                  SHOP NOW
                </Link>
                {/* <button className="white-btn">
                                    SHOP NOW
                                </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="accentuateYrShine secSpacing pb-0">
        <div className="Newcontainer">
          <div className="row">
            <div className="col-sm-12">
              <div className="NewTopSmallHeading text-center">
                <h4>Accentuate Your Shine</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="Newcontainer">
          <div className="accentRowListing mt-120">
            <div className="row">
              <div className="col-md-6">
                <div className="accentyrBox">
                  <h3 className="newSubBoxHeading text-center">
                    Only the purest for you{" "}
                  </h3>
                  <figure className="accentimgWrp">
                    <img src="assets/images/about4.png" alt="" />
                  </figure>
                  <div className="accentYrContentBox">
                    <h6>CERTIFIED GOLD AND DIAMONDS</h6>
                    <p>
                      We craft jewellery using only BIS Hallmarked pure gold,
                      SGL & IGI Certified Diamonds
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="accentyrBox">
                  <h3 className="newSubBoxHeading text-center">
                    Only for you. For life
                  </h3>
                  <figure className="accentimgWrp">
                    <img src="assets/images/about3.png" alt="" />
                  </figure>
                  <div className="accentYrContentBox">
                    <h6>LIFETIME JEWELLERY EXCHANGE</h6>
                    <p>
                      Exchange jewellery you buy from us, at any time,
                      everytime. Fair rates guaranteed
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="accentyrBox">
                  <h3 className="newSubBoxHeading text-center">
                    Only for your benefit. always
                  </h3>
                  <figure className="accentimgWrp">
                    <img src="assets/images/about2.png" alt="" />
                  </figure>
                  <div className="accentYrContentBox">
                    <h6>15 DAY GUARANTEED RETURNS</h6>
                    <p>
                      Return jewellery, no questions asked, if you so wish,
                      within 15 days of delivery.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="accentyrBox">
                  <h3 className="newSubBoxHeading text-center">
                    Only the best for you
                  </h3>
                  <figure className="accentimgWrp">
                    <img src="../assets/images/about5.png" alt="" />
                  </figure>
                  <div className="accentYrContentBox">
                    <h6>PRECIOUS SWAROVSKI STONES</h6>
                    <p>
                      We only use the best and most precious Swarovski stones in
                      our jewellery collections
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="accentyrBox">
                  <h3 className="newSubBoxHeading text-center">
                    Only the safest for you
                  </h3>
                  <figure className="accentimgWrp">
                    <img src="../assets/images/about1.png" alt="" />
                  </figure>
                  <div className="accentYrContentBox">
                    <h6>SECURE, INSURED FREE SHIPPING</h6>
                    <p>
                      We pay for the insurance and shipping ensuring your
                      jewellery is delivered safely.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
      <Footer />
    </>
  );
}

export default About;
