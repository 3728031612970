import React, { Component } from "react";
import { Passers } from "prop-passer";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  EmailShareButton,

  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  PinterestIcon,
  EmailIcon,
  
} from "react-share";



class SubShare extends Component {

  render() {

    const {
      url = String(window.location),
      title = "Yourly",
      shareImage = "http://youlry.bizdevtechnologies.in/assets/images/logo.png",
      size = "3.0rem",
    } = this.props;

    const ShareList = Passers({
      url,
      className: "network__share-button",
    })({
      className: "network cursor-pointer hover transition--default",
      title: `You want to Share -> ${String(title)} `,
    })("li");

    return (
     
        <ShareList>
          <FacebookShareButton
            quote={title}
          >
            <FacebookIcon
              size={size} round
            />
          </FacebookShareButton>

          <TwitterShareButton
            title={title}
          >
            <TwitterIcon
              size={size} round
            />
          </TwitterShareButton>

          <WhatsappShareButton
            title={title}
            separator=":: "
          >
            <WhatsappIcon size={size} round />
          </WhatsappShareButton>

          <LinkedinShareButton
            title={title}
            windowWidth={750}
            windowHeight={600}
          >
            <LinkedinIcon
              size={size} round

            />
          </LinkedinShareButton>

          <PinterestShareButton
            url={String(window.location)}
            media={`${shareImage}`}
            windowWidth={1000}
            windowHeight={730}
          >
            <PinterestIcon size={size} round/>
          </PinterestShareButton>

          <EmailShareButton
            title={title}
            windowWidth={750}
            windowHeight={600}
          >
            <EmailIcon
              size={size} round
            />
          </EmailShareButton>

        </ShareList>
     
    );
  }
}

export default SubShare;

