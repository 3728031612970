import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  const [getCategoryValue, setGetCategoryValue] = useState([]);
  const [genSetting, setgenSetting] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getCategoryList",
        featured: "Yes",
      }),
    }).then((response) => {
      response.json().then((res) => {
        setGetCategoryValue(res.data);
      });
    });
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getSettings",
      }),
    }).then((resp) => {
      resp.json().then((res) => {
        if (res.success === true) {
          setgenSetting(res.data);
        } else {
          setgenSetting(null);
        }
      });
    });
  }, []);

  //console.log(props);

  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="footer-top ptb-60-100">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer-logo">
                    <Link to="/">
                      <img src="../assets/images/logo.png" alt="img" />
                    </Link>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className="footer-links">
                    <h2 className="links-title">JEWELRY</h2>
                    <ul>
                      {getCategoryValue.map((items, i) => {
                        return (
                          <li key={i}>
                            <Link to={`/category/${items.Slug}`}>
                              {items.CatName}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className="footer-links">
                    <h2 className="links-title">CUSTOMER SERVICE</h2>
                    <ul>
                      <li>
                        <Link to="/return-policy">Return Policy</Link>
                      </li>
                      <li>
                        <Link to="/order-status">Order Status</Link>
                      </li>
                      <li>
                        <Link to="/shipping-policy">Shipping Policy</Link>
                      </li>
                      <li>
                        <Link to="/payment-policy">Payment Policy</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms-of-use">Terms of Use</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className="footer-links">
                    <h2 className="links-title">ABOUT US</h2>
                    <ul>
                      <li>
                        <Link to="/about">Our Story</Link>
                      </li>
                      <li>
                        <Link to="/our-process">Our Process</Link>
                      </li>
                      <li>
                        <Link to="/our-promise">Our Promise</Link>
                      </li>
                      <li>
                        <Link to="/">Blog</Link>
                      </li>
                      <li>
                        <Link to="/faq">FAQ</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className="footer-links">
                    <h2 className="links-title">CONTACT US</h2>
                    <ul>
                      <li>
                        {genSetting && (
                          <a href={`tel:${genSetting.site_phone}`}>
                            <span className="material-icons">phone</span>
                            {genSetting.site_phone}
                          </a>
                        )}
                      </li>
                      <li>
                        {genSetting && (
                          <a href={`tel:${genSetting.whats_app}`}>
                            <span className="material-icons-outlined">
                              whatsapp
                            </span>
                            {genSetting.whats_app}
                          </a>
                        )}
                      </li>
                      <li>
                        {genSetting && (
                          <a href={`mailto:${genSetting.site_email}`}>
                            <span className="material-icons-outlined">
                              email
                            </span>
                            {genSetting.site_email}
                          </a>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row">
                <div className="col-md-7">
                  <div className="txt-white">
                    <p>
                      All rights reserved Copyright 2021-22 KD Gold and Diamonds
                      Ltd.
                    </p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="txt-white">
                    <p>Designed by: Digital Dogs Content & Media.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
