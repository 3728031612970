import React, { useState } from "react";
import { MobileFilter } from "./MobileFilter";

export const MobileSearch = ({
  searchValue,
  setSearchValue,
  filterByCategory,
  setFilterByCategory,
  filterByColor,
  setFilterByColor,
  filterByWeight,
  setFilterByWeight,
  filterByPrice,
  setFilterByPrice,
}) => {
  const handleSearchInputChanges = (e) => {
    setSearchValue(e.value);
  };
  return (
    <form>
      <MobileFilter
        filterByCategory={filterByCategory}
        setFilterByCategory={setFilterByCategory}
        filterByColor={filterByColor}
        setFilterByColor={setFilterByColor}
        filterByWeight={filterByWeight}
        setFilterByWeight={setFilterByWeight}
        filterByPrice={filterByPrice}
        setFilterByPrice={setFilterByPrice}
      />
    </form>
  );
};
