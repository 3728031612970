import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Newsletter from "./Newsletter";
import {
  Link,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Search } from "./Filter/Search";
import Select from "react-select";
import { Button } from "react-bootstrap";
import CustomButton from "../components/CustomButton";

function ProductListByColor() {
  const tokenNo = localStorage.getItem("tokenNo");

  const [getFeatured, setGetFeatured] = useState([]);
  const [getFeaturedLength, setGetFeaturedLength] = useState(null);
  const [getNoFeatured, setNoFeatured] = useState([]);
  const [productLoader, setProductLoader] = useState(false);
  const [showSideCart, setShowSideCart] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [featuredId, setFeaturedId] = useState(null);
  const [featuredProductOverlay, setFeaturedProductOverlay] = useState(null);
  const [loadMore, setLoadMore] = useState(5);
  const [filterOpen, setFilterOpen] = useState(false);
  const [wishModalOpen, setWishModalOpen] = useState(false);
  const [addWishModalOpen, setAddWishModalOpen] = useState(false);
  const [wishBadge, setWishBadge] = useState(false);
  const [genSetting, setgenSetting] = useState(null);
  // const [filterdProduct, setFilterdProduct] = useState([]);
  // const [productsLists, setProductsLists] = useState([]);
  // const [filterState, setFilterState] = useState(false);
  // const [filterCategory, setFilterCategory] = useState(null);
  // const [filterColor, setFilterColor] = useState(null);
  // const [filterWeight, setFilterWeight] = useState(null);
  // const [filterPrice, setFilterPrice] = useState(null);

  // *** Implemented By Vivek Singh ***** ///
  // *** Implemented For Filter on Listing Page ***** ///

  const [display, setDisplay] = useState([]);
  const [sortedDisplay, setSortedDisplay] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterByCategory, setFilterByCategory] = useState("");
  let [filterByColor, setFilterByColor] = useState("");
  const [filterByWeight, setFilterByWeight] = useState("");
  const [filterByPrice, setFilterByPrice] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [collection, setCollection] = useState(null);
  const [filterBySort, setFilterBySort] = useState([]);

  //console.log(name);

  var { name } = useParams();

  const navigate = useNavigate();

  if (filterByColor === "" || filterByColor === null) {
    filterByColor = name;
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        token: tokenNo ? tokenNo : "",
        request: "getProductList",
        metal_color: filterByColor,
        featured: "Yes",
      }),
    }).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        setGetFeatured(result.data);
        setGetFeaturedLength(result.data.length);
      });
    });
  }, [filterByColor]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        token: tokenNo ? tokenNo : "",
        request: "getProductList",
        metal_color: filterByColor,
        featured: "No",
      }),
    }).then((response) => {
      response.json().then((res) => {
        //console.log(res);
        setNoFeatured(res.data);
        setProductLoader(true);
        setDisplay(res.data);
      });
    });
  }, [filterByColor]);

  React.useEffect(() => {
    let searchPar = searchParams.get("q")?.toLowerCase();
    //console.log('Hello Vivek----' + searchPar);
    setSearchParams(searchPar);
  }, []);

  React.useEffect(() => {
    filterData();
  }, [
    filterByCategory,
    filterByColor,
    filterByWeight,
    filterByPrice,
    collection,
    filterBySort,
  ]);

  const filterData = () => {
    const startWeight = filterByWeight.split(new RegExp("-"))[0];
    const endWeight = filterByWeight.split(new RegExp("-"))[1];
    const startPrice = filterByPrice.split(new RegExp("-"))[0];
    const endPrice = filterByPrice.split(new RegExp("-"))[1]
      ? filterByPrice.split(new RegExp("-"))[1]
      : "";

    const sWeight = +startWeight;
    const eWeight = +endWeight;

    if (filterBySort === "no") {
      display.sort((a, b) => a.Price - b.Price);
    }
    if (filterBySort === "yes") {
      display.sort((a, b) => a.Price - b.Price).reverse();
    }

    const result = display.filter(
      (resp) =>
        (!filterByCategory || resp.CatId === filterByCategory) &&
        (!filterByColor || resp.Color === filterByColor) &&
        (!filterByWeight ||
          (resp.Weight >= sWeight && resp.Weight <= eWeight)) &&
        (!filterByPrice ||
          (parseInt(resp.Price, 10) <= endPrice &&
            parseInt(resp.Price, 10) >= startPrice))
    );
    setFeaturedProductOverlay(
      result.filter((featured) => featured.Featured == "Yes")
    );
    //setGetFeatured(result);
    setNoFeatured(result);
  };

  const chunkSize = 4;
  const groups = getNoFeatured
    .map((e, i) => {
      return i % chunkSize === 0 ? getNoFeatured.slice(i, i + chunkSize) : null;
    })
    .filter((e) => {
      return e;
    });
  //console.log('No featured chunk', groups)

  var li_count = 1;
  var j_mod = 0;
  var f_count = getFeaturedLength;

  const sideOpen = (id) => {
    //alert(id);
    const styleApply = {
      visibility: "visible",
      right: "0",
    };
    const styleOverlay = {
      visibility: "visible",
    };

    setShowSideCart(styleApply);
    setShowOverlay(styleOverlay);

    setFeaturedId(id);

    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        token: tokenNo ? tokenNo : "",
        request: "getRelatedProduct",
        pid: id,
      }),
    }).then((response) => {
      response.json().then((res) => {
        //console.log(res);
        setFeaturedProductOverlay(res.data);
      });
    });
  };

  //console.log(featuredProductOverlay);

  const sideClose = () => {
    const styleRemove = {
      visibility: "hidden",
      right: "-100%",
    };
    const styleOverlayRemove = {
      visibility: "hidden",
    };
    setShowSideCart(styleRemove);
    setShowOverlay(styleOverlayRemove);
  };

  const handleLoadMore = () => {
    if (loadMore >= groups.length) return;
    setLoadMore(() => loadMore + 5);
  };

  const filterMenuOpen = () => {
    setFilterOpen(true);
  };

  const filterMenuRemove = () => {
    setFilterOpen(false);
  };

  const wishModal = () => {
    setWishModalOpen(true);
  };

  const wishModalRemove = () => {
    setWishModalOpen(false);
  };

  const addWishlistModal = (productId) => {
    const request = {
      method: "POST",
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
      body: JSON.stringify({
        request: "addToWishlist",
        token: tokenNo,
        pid: productId,
      }),
    };

    fetch(process.env.REACT_APP_API_URL, request).then((resp) => {
      resp.json().then((res) => {
        console.log(res);
        if (res.success === true) {
          //setWishBadge(true);
          setAddWishModalOpen(true);
        } else {
          //setWishBadge(false)
        }
      });
    });
  };

  const addWishModalRemove = () => {
    setAddWishModalOpen(false);
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getSettings",
      }),
    }).then((resp) => {
      resp.json().then((res) => {
        if (res.success === true) {
          setgenSetting(res.data);
        } else {
          setgenSetting(null);
        }
      });
    });
  }, []);

  const optionsCat = genSetting ? genSetting.filters.categories : "";
  const optionsColor = genSetting ? genSetting.filters.color : "";
  const optionsWeight = genSetting ? genSetting.filters.weight : "";
  const optionsPrice = genSetting ? genSetting.filters.price : "";
  const optionsSort = [
    { value: "price asc", label: "Price Low to High" },
    { value: "price desc", label: "Price High to Low" },
  ];

  const navigateToLogin = () => {
    navigate("/login", { state: { component: window.location.pathname } });
  };

  return (
    <>
      <div className="overlay" style={{ ...showOverlay }}></div>

      <Header />

      <section className="product-list-wrap ptb-60">
        <div className="container">
          <div className="row">
            {!productLoader ? (
              <div className="preloaderBg" id="preloader">
                <div className="preloader"></div>
                <div className="preloader2"></div>
              </div>
            ) : (
              <>
                <div className="col-md-12">
                  <div className="breadcrum-wrap">
                    <ul>
                      <li>
                        <Link to="/">HOME</Link>
                      </li>
                      <li>
                        <Link to="">{name.toUpperCase()}</Link>
                      </li>
                    </ul>
                  </div>
                  <div style={{ float: "right" }}>
                    <CustomButton labelText="Reset" />
                  </div>
                </div>
                <div className="product-list">
                  <div className="col-md-12">
                    <div className="filterViewMobile">
                      <div className="filter-mobile-top">
                        <button
                          className="filterby-btn"
                          onClick={filterMenuOpen}
                        >
                          Filter By
                        </button>

                        <div className="dropdown show">
                          <button
                            className="btn btn-secondary dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Sort by
                          </button>

                          <div
                            className="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <a className="dropdown-item" href="#">
                              Price: High to Low
                            </a>
                            <a className="dropdown-item" href="#">
                              Price: Low to High
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ textDecoration: "none" }}>
                      <Search
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        filterByCategory={filterByCategory}
                        setFilterByCategory={setFilterByCategory}
                        filterByColor={filterByColor}
                        setFilterByColor={setFilterByColor}
                        filterByWeight={filterByWeight}
                        setFilterByWeight={setFilterByWeight}
                        filterByPrice={filterByPrice}
                        setFilterByPrice={setFilterByPrice}
                        filterBySort={filterBySort}
                        setFilterBySort={setFilterBySort}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="product-no-wrap">
                      {/* <p className='pull-left'>Filters : {filterCategory}, {filterColor}, {filterWeight}, {filterPrice}</p> */}
                      {groups.length > 0 && (
                        <p>Showing 1-20 of {getNoFeatured.length}</p>
                      )}
                    </div>
                  </div>

                  <ul>
                    {getNoFeatured ? (
                      groups.map((item, i) => {
                        //console.log(item);

                        if (i + 1 > loadMore) return;

                        li_count++;

                        if (li_count % 3) {
                          return (
                            <li key={i}>
                              {item.map((prdt, k) => {
                                return (
                                  <div className="col-md-3 product-li" key={k}>
                                    <a href="#" className="productlist-img">
                                      <img
                                        src={prdt.Image}
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    </a>
                                    <div className="imgslider">
                                      <div
                                        id={`imgSliderCarousal-${prdt.Id}`}
                                        className="carousel slide imgSliderCarousal"
                                        data-ride="carousel"
                                      >
                                        {tokenNo && prdt.InWishlist === true ? (
                                          <a
                                            href="javascript:void(0)"
                                            className="whislist-img"
                                          >
                                            <span
                                              className={`material-icons-outlined wishlist-red`}
                                            >
                                              favorite
                                            </span>
                                          </a>
                                        ) : tokenNo &&
                                          prdt.InWishlist === false ? (
                                          <a
                                            href="javascript:void(0)"
                                            className="whislist-img"
                                            onClick={() =>
                                              addWishlistModal(prdt.Id)
                                            }
                                          >
                                            <span
                                              className={`material-icons-outlined wishlist-grey`}
                                            >
                                              favorite
                                            </span>
                                          </a>
                                        ) : (
                                          <a
                                            href="javascript:void(0)"
                                            onClick={wishModal}
                                            className="whislist-img"
                                          >
                                            <span className="material-icons-outlined wishlist-grey">
                                              favorite
                                            </span>
                                          </a>
                                        )}

                                        <div className="carousel-inner">
                                          {prdt.ProductGallery.length > 0 ? (
                                            prdt.ProductGallery.map(
                                              (galImg, g) => {
                                                if (
                                                  galImg.media_type === "image"
                                                ) {
                                                  return (
                                                    <div
                                                      className={`carousel-item ${
                                                        g === 0 ? "active" : ""
                                                      }`}
                                                      key={g}
                                                    >
                                                      <Link
                                                        to={`/product/${prdt.Id}`}
                                                        className="product-link"
                                                      >
                                                        <img
                                                          className="d-block w-100"
                                                          src={galImg.img_thumb}
                                                          alt=""
                                                        />
                                                      </Link>
                                                    </div>
                                                  );
                                                }
                                              }
                                            )
                                          ) : (
                                            <div
                                              className={`carousel-item active`}
                                            >
                                              <img
                                                className="d-block w-100"
                                                src={prdt.Image}
                                                alt=""
                                              />
                                            </div>
                                          )}
                                        </div>
                                        <ol className="carousel-indicators">
                                          {prdt.ProductGallery.map(
                                            (galImg, g) => {
                                              if (
                                                galImg.media_type === "image"
                                              ) {
                                                return (
                                                  <li
                                                    data-target={`#imgSliderCarousal-${prdt.Id}`}
                                                    data-slide-to={g}
                                                    className={
                                                      g === 0 ? "active" : ""
                                                    }
                                                    key={g}
                                                  ></li>
                                                );
                                              }
                                            }
                                          )}
                                        </ol>
                                        <div className="imgslider-content">
                                          <p>
                                            <Link
                                              to={`/product/${prdt.Id}`}
                                              className="product-link"
                                            >
                                              {prdt.Name}
                                            </Link>
                                          </p>
                                          <div className="d-flex align-items-center justify-content-between pt-4">
                                            <p className="price">
                                              ₹{prdt.Price}
                                            </p>
                                            <Link to={`/product/${prdt.Id}`}>
                                              <img
                                                src="../assets/images/add-cart.svg"
                                                alt=""
                                              />
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </li>
                          );
                        } else if (f_count > 0 && getFeatured.length > 0) {
                          j_mod++;
                          f_count--;

                          if (j_mod % 2) {
                            let idVal = getFeatured[f_count]?.Id;

                            return (
                              <li key={i}>
                                <div className="col-md-6 singleItemBox">
                                  <a
                                    href="#"
                                    className="productlist-img SingleItem"
                                  >
                                    <img
                                      src={getFeatured[f_count]?.Image}
                                      alt="img"
                                      className="img-fluid"
                                    />
                                    <button
                                      type="button"
                                      className="add-cart-btn"
                                      onClick={() => sideOpen(idVal)}
                                    >
                                      <img
                                        src="http://youlry.bizdevtechnologies.in/html/assets/images/add-cart-white.svg"
                                        alt="img"
                                        className="img-fluid"
                                      />
                                      <p>&nbsp; Shop the Image</p>
                                    </button>
                                  </a>
                                </div>
                                <div className="col-md-6">
                                  <div className="row sub-colomn-wrp">
                                    {item.map((prdt, k) => {
                                      return (
                                        <div className="col-md-6" key={k}>
                                          <div className="product-li">
                                            <a
                                              href="#"
                                              className="productlist-img"
                                            >
                                              <img
                                                src={prdt.Image}
                                                alt="img"
                                                className="img-fluid"
                                              />
                                            </a>
                                            <div className="imgslider">
                                              <div
                                                id={`imgSliderCarousal-${prdt.Id}`}
                                                className="carousel slide imgSliderCarousal"
                                                data-ride="carousel"
                                              >
                                                {tokenNo &&
                                                prdt.InWishlist === true ? (
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="whislist-img"
                                                  >
                                                    <span
                                                      className={`material-icons-outlined wishlist-red`}
                                                    >
                                                      favorite
                                                    </span>
                                                  </a>
                                                ) : tokenNo &&
                                                  prdt.InWishlist === false ? (
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="whislist-img"
                                                    onClick={() =>
                                                      addWishlistModal(prdt.Id)
                                                    }
                                                  >
                                                    <span
                                                      className={`material-icons-outlined wishlist-grey`}
                                                    >
                                                      favorite
                                                    </span>
                                                  </a>
                                                ) : (
                                                  <a
                                                    href="javascript:void(0)"
                                                    onClick={wishModal}
                                                    className="whislist-img"
                                                  >
                                                    <span className="material-icons-outlined wishlist-grey">
                                                      favorite
                                                    </span>
                                                  </a>
                                                )}
                                                <div className="carousel-inner">
                                                  {prdt.ProductGallery.length >
                                                  0 ? (
                                                    prdt.ProductGallery.map(
                                                      (galImg, g) => {
                                                        if (
                                                          galImg.media_type ===
                                                          "image"
                                                        ) {
                                                          return (
                                                            <div
                                                              className={`carousel-item ${
                                                                g === 0
                                                                  ? "active"
                                                                  : ""
                                                              }`}
                                                              key={g}
                                                            >
                                                              <Link
                                                                to={`/product/${prdt.Id}`}
                                                                className="product-link"
                                                              >
                                                                <img
                                                                  className="d-block w-100"
                                                                  src={
                                                                    galImg.img_thumb
                                                                  }
                                                                  alt=""
                                                                />
                                                              </Link>
                                                            </div>
                                                          );
                                                        }
                                                      }
                                                    )
                                                  ) : (
                                                    <div
                                                      className={`carousel-item active`}
                                                    >
                                                      <img
                                                        className="d-block w-100"
                                                        src={prdt.Image}
                                                        alt=""
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                                <ol className="carousel-indicators">
                                                  {prdt.ProductGallery.map(
                                                    (galImg, g) => {
                                                      if (
                                                        galImg.media_type ===
                                                        "image"
                                                      ) {
                                                        return (
                                                          <li
                                                            data-target={`#imgSliderCarousal-${prdt.Id}`}
                                                            data-slide-to={g}
                                                            className={
                                                              g === 0
                                                                ? "active"
                                                                : ""
                                                            }
                                                            key={g}
                                                          ></li>
                                                        );
                                                      }
                                                    }
                                                  )}
                                                </ol>
                                                <div className="imgslider-content">
                                                  <p>
                                                    <Link
                                                      to={`/product/${prdt.Id}`}
                                                      className="product-link"
                                                    >
                                                      {prdt.Name}
                                                    </Link>
                                                  </p>
                                                  <div className="d-flex align-items-center justify-content-between pt-4">
                                                    <p className="price">
                                                      ₹{prdt.Price}
                                                    </p>
                                                    <Link
                                                      to={`/product/${prdt.Id}`}
                                                    >
                                                      <img
                                                        src="../assets/images/add-cart.svg"
                                                        alt=""
                                                      />
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </li>
                            );
                          } else {
                            let idVal2 = getFeatured[f_count]?.Id;

                            return (
                              <li key={i}>
                                <div className="col-md-6">
                                  <div className="row sub-colomn-wrp">
                                    {item.map((prdt, k) => {
                                      return (
                                        <div className="col-md-6" key={k}>
                                          <div className="product-li">
                                            <a
                                              href="#"
                                              className="productlist-img"
                                            >
                                              <img
                                                src={prdt.Image}
                                                alt="img"
                                                className="img-fluid"
                                              />
                                            </a>
                                            <div className="imgslider">
                                              <div
                                                id={`imgSliderCarousal-${prdt.Id}`}
                                                className="carousel slide imgSliderCarousal"
                                                data-ride="carousel"
                                              >
                                                {tokenNo &&
                                                prdt.InWishlist === true ? (
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="whislist-img"
                                                  >
                                                    <span
                                                      className={`material-icons-outlined wishlist-red`}
                                                    >
                                                      favorite
                                                    </span>
                                                  </a>
                                                ) : tokenNo &&
                                                  prdt.InWishlist === false ? (
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="whislist-img"
                                                    onClick={() =>
                                                      addWishlistModal(prdt.Id)
                                                    }
                                                  >
                                                    <span
                                                      className={`material-icons-outlined wishlist-grey`}
                                                    >
                                                      favorite
                                                    </span>
                                                  </a>
                                                ) : (
                                                  <a
                                                    href="javascript:void(0)"
                                                    onClick={wishModal}
                                                    className="whislist-img"
                                                  >
                                                    <span className="material-icons-outlined wishlist-grey">
                                                      favorite
                                                    </span>
                                                  </a>
                                                )}
                                                <div className="carousel-inner">
                                                  {prdt.ProductGallery.length >
                                                  0 ? (
                                                    prdt.ProductGallery.map(
                                                      (galImg, g) => {
                                                        if (
                                                          galImg.media_type ===
                                                          "image"
                                                        ) {
                                                          return (
                                                            <div
                                                              className={`carousel-item ${
                                                                g === 0
                                                                  ? "active"
                                                                  : ""
                                                              }`}
                                                              key={g}
                                                            >
                                                              <Link
                                                                to={`/product/${prdt.Id}`}
                                                                className="product-link"
                                                              >
                                                                <img
                                                                  className="d-block w-100"
                                                                  src={
                                                                    galImg.img_thumb
                                                                  }
                                                                  alt=""
                                                                />
                                                              </Link>
                                                            </div>
                                                          );
                                                        }
                                                      }
                                                    )
                                                  ) : (
                                                    <div
                                                      className={`carousel-item active`}
                                                    >
                                                      <img
                                                        className="d-block w-100"
                                                        src={prdt.Image}
                                                        alt=""
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                                <ol className="carousel-indicators">
                                                  {prdt.ProductGallery.map(
                                                    (galImg, g) => {
                                                      if (
                                                        galImg.media_type ===
                                                        "image"
                                                      ) {
                                                        return (
                                                          <li
                                                            data-target={`#imgSliderCarousal-${prdt.Id}`}
                                                            data-slide-to={g}
                                                            className={
                                                              g === 0
                                                                ? "active"
                                                                : ""
                                                            }
                                                            key={g}
                                                          ></li>
                                                        );
                                                      }
                                                    }
                                                  )}
                                                </ol>
                                                <div className="imgslider-content">
                                                  <p>
                                                    <Link
                                                      to={`/product/${prdt.Id}`}
                                                      className="product-link"
                                                    >
                                                      {prdt.Name}
                                                    </Link>
                                                  </p>
                                                  <div className="d-flex align-items-center justify-content-between pt-4">
                                                    <p className="price">
                                                      ₹{prdt.Price}
                                                    </p>
                                                    <Link
                                                      to={`/product/${prdt.Id}`}
                                                    >
                                                      <img
                                                        src="../assets/images/add-cart.svg"
                                                        alt=""
                                                      />
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div className="col-md-6 singleItemBox">
                                  <a
                                    href="#"
                                    className="productlist-img SingleItem"
                                  >
                                    <img
                                      src={getFeatured[f_count]?.Image}
                                      alt="img"
                                      className="img-fluid"
                                    />
                                    <button
                                      className="add-cart-btn"
                                      onClick={() => sideOpen(idVal2)}
                                    >
                                      <img
                                        src="http://youlry.bizdevtechnologies.in/html/assets/images/add-cart-white.svg"
                                        alt="img"
                                        className="img-fluid"
                                      />
                                      <p>&nbsp; Shop the Image</p>
                                    </button>
                                  </a>
                                </div>
                              </li>
                            );
                          }
                        } else {
                          return (
                            <li key={i}>
                              {item.map((prdt, k) => {
                                return (
                                  <div className="col-md-3 product-li" key={k}>
                                    <a href="#" className="productlist-img">
                                      <img
                                        src={prdt.Image}
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    </a>
                                    <div className="imgslider">
                                      <div
                                        id={`imgSliderCarousal-${prdt.Id}`}
                                        className="carousel slide imgSliderCarousal"
                                        data-ride="carousel"
                                      >
                                        {tokenNo && prdt.InWishlist === true ? (
                                          <a
                                            href="javascript:void(0)"
                                            className="whislist-img"
                                          >
                                            <span
                                              className={`material-icons-outlined wishlist-red`}
                                            >
                                              favorite
                                            </span>
                                          </a>
                                        ) : tokenNo &&
                                          prdt.InWishlist === false ? (
                                          <a
                                            href="javascript:void(0)"
                                            className="whislist-img"
                                            onClick={() =>
                                              addWishlistModal(prdt.Id)
                                            }
                                          >
                                            <span
                                              className={`material-icons-outlined wishlist-grey`}
                                            >
                                              favorite
                                            </span>
                                          </a>
                                        ) : (
                                          <a
                                            href="javascript:void(0)"
                                            onClick={wishModal}
                                            className="whislist-img"
                                          >
                                            <span className="material-icons-outlined wishlist-grey">
                                              favorite
                                            </span>
                                          </a>
                                        )}
                                        <div className="carousel-inner">
                                          {prdt.ProductGallery.length > 0 ? (
                                            prdt.ProductGallery.map(
                                              (galImg, g) => {
                                                if (
                                                  galImg.media_type === "image"
                                                ) {
                                                  return (
                                                    <div
                                                      className={`carousel-item ${
                                                        g === "0"
                                                          ? "active"
                                                          : ""
                                                      }`}
                                                      key={g}
                                                    >
                                                      <Link
                                                        to={`/product/${prdt.Id}`}
                                                        className="product-link"
                                                      >
                                                        <img
                                                          className="d-block w-100"
                                                          src={galImg.img_thumb}
                                                          alt=""
                                                        />
                                                      </Link>
                                                    </div>
                                                  );
                                                }
                                              }
                                            )
                                          ) : (
                                            <div
                                              className={`carousel-item active`}
                                            >
                                              <img
                                                className="d-block w-100"
                                                src={prdt.Image}
                                                alt=""
                                              />
                                            </div>
                                          )}
                                        </div>
                                        <ol className="carousel-indicators">
                                          {prdt.ProductGallery.map(
                                            (galImg, g) => {
                                              if (
                                                galImg.media_type === "image"
                                              ) {
                                                return (
                                                  <li
                                                    data-target={`#imgSliderCarousal-${prdt.Id}`}
                                                    data-slide-to={g}
                                                    className={
                                                      g === 0 ? "active" : ""
                                                    }
                                                    key={g}
                                                  ></li>
                                                );
                                              }
                                            }
                                          )}
                                        </ol>
                                        <div className="imgslider-content">
                                          <p>
                                            <Link
                                              to={`/product/${prdt.Id}`}
                                              className="product-link"
                                            >
                                              {prdt.Name}
                                            </Link>
                                          </p>
                                          <div className="d-flex align-items-center justify-content-between pt-4">
                                            <p className="price">
                                              ₹{prdt.Price}
                                            </p>
                                            <Link to={`/product/${prdt.Id}`}>
                                              <img
                                                src="../assets/images/add-cart.svg"
                                                alt=""
                                              />
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </li>
                          );
                        }
                      })
                    ) : (
                      <div className="spinner">
                        <div className="spinner spinner2">
                          <div className="spinner spinner3"></div>
                        </div>
                      </div>
                    )}
                  </ul>

                  {groups.length > 0 && (
                    <div className="btn-wrap col-auto pt-5">
                      <button
                        type="button"
                        onClick={handleLoadMore}
                        className="btn white-btn"
                      >
                        VIEW MORE
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      <Newsletter />
      <Footer />

      <div
        className="side-cart-wrap"
        style={{ ...(showSideCart ? showSideCart : "") }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="close-btn-wrap">
              <button className="close-btn" onClick={sideClose}>
                <span className="material-icons-round">close</span>
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <h2 className="heading-black pb-3">Featured Products</h2>
          </div>

          {featuredProductOverlay
            ? featuredProductOverlay.map((prdtItems, i) => (
                <div className="col-6 col-md-6" key={i}>
                  <div className="item">
                    <div className="item-img">
                      <figure>
                        <img src={prdtItems.Image} alt={prdtItems.Name} />
                      </figure>
                      <Link to="/login" className="whislist-img">
                        <span className="material-icons-outlined wishlist-grey">
                          favorite
                        </span>
                      </Link>
                    </div>
                    <div className="slider-content">
                      <h2 className="item-title">
                        <Link
                          to={`/product/${prdtItems.Id}`}
                          className="product-link"
                        >
                          {prdtItems.Name}
                        </Link>
                      </h2>
                      <h3 className="item-price">₹{prdtItems.Price}</h3>
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>

      <div
        className={`filterAreaMobile ${filterOpen && "filterAreaMobileOpen"}`}
      >
        <button className="closeFilterBtn" onClick={filterMenuRemove}>
          <i className="material-icons">close</i>
        </button>
        <div className="middle">
          <div className="filter-wrap">
            <div className="form-group">
              <Select options={optionsCat} />
              {/* <select className="form-control" id='category_value' onChange={filterHandle}>
                                                    <option value=''>Category</option>
                                                    {genSetting ? genSetting.filters.categories.map( (categoryItem, i) => (
                                                        <option value={categoryItem.CatId} key={i}>{categoryItem.CatName}</option>
                                                    )) : ''} 
                                                </select>
                                                <span className="material-icons-outlined"> arrow_drop_down </span> */}
            </div>
            <div className="form-group">
              <Select options={optionsColor} />
              {/* <select className="form-control" id='color_value' onChange={filterHandle}>
                                                    <option value=''>Metal Color</option>
                                                    {genSetting ? genSetting.filters.color.map( (colorItem, i) => (
                                                        <option value={colorItem} key={i}>{colorItem}</option>
                                                    )) : ''}  
                                                </select> */}
              {/* <span className="material-icons-outlined"> arrow_drop_down </span> */}
            </div>
            <div className="form-group">
              <Select options={optionsWeight} />
              {/* <select className="form-control" id='weight_value' onChange={filterHandle}>
                                                    <option value=''>Weight</option>
                                                    {genSetting ? genSetting.filters.weight.map( (weightItem, i) => (
                                                        <option value={weightItem.value} key={i}>{weightItem.label}</option>
                                                    )) : ''}
                                                </select>
                                                <span className="material-icons-outlined"> arrow_drop_down </span> */}
            </div>
            <div className="form-group">
              <Select options={optionsPrice} />
              {/* <select className="form-control" id='price_value' onChange={filterHandle}>
                                                    <option value=''>Price</option>
                                                    {genSetting ? genSetting.filters.price.map( (priceItem, i) => (
                                                        <option value={priceItem.value} key={i}>{priceItem.label}</option>
                                                    )) : ''}
                                                </select>
                                                <span className="material-icons-outlined"> arrow_drop_down </span> */}
            </div>
            <div className="form-group">
              <label className="shortby">Sort by :</label>
              <Select options={optionsSort} />
              {/* <select className="form-control" id='price_sort' onChange={filterHandle}>
                                                    <option value=''>Recommended</option>
                                                    <option value='price asc'>Price Low to High</option>
                                                    <option value='price desc'>Price High to Low</option>
                                                </select>
                                                <span className="material-icons-outlined"> arrow_drop_down </span> */}
            </div>
          </div>
        </div>
      </div>

      <div className={`wishModal ${wishModalOpen && "is-visible"}`}>
        <div className="wishModal-overlay wishModal-toggle"></div>
        <div className="wishModal-wrapper wishModal-transition">
          <div className="wishModal-header">
            <button className="wishModal-close wishModal-toggle">
              {/* <span aria-hidden="true">&times;</span> */}
            </button>
            <h2 className="wishModal-heading">Notice</h2>
          </div>

          <div className="wishModal-body">
            <div className="wishModal-content">
              <p className="text-center">
                Please login first to add this product in your wishlist
              </p>
              <button
                onClick={navigateToLogin}
                className="btn-org-lg pull-left"
              >
                Go To Login
              </button>
              <button
                className="btn-org-lg pull-right mb-30"
                onClick={wishModalRemove}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`wishModal ${addWishModalOpen && "is-visible"}`}>
        <div className="wishModal-overlay wishModal-toggle"></div>
        <div className="wishModal-wrapper wishModal-transition">
          <div className="wishModal-header">
            <button className="wishModal-close wishModal-toggle">
              {/* <span aria-hidden="true">&times;</span> */}
            </button>
            <h2 className="wishModal-heading">Notice</h2>
          </div>

          <div className="wishModal-body">
            <div className="wishModal-content">
              <p className="text-center">
                This product is added in your wishlist
              </p>
              <Link to="/dashboard-wishlist" className="btn-org-lg pull-left">
                Go To Wishlist
              </Link>
              <button
                className="btn-org-lg pull-right mb-30"
                onClick={addWishModalRemove}
                style={{ marginRight: "15%" }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductListByColor;
