import React from "react";

export default function ProductDetailsConstant() {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="promise-content">
              <h2 className="heading-black">Care Instructions</h2>
            </div>
          </div>
        </div>
        <div className="careins-main-wrp">
          <div className="row one">
            <div className="col-md-6">
              <div className="img__wrp_bx ">
                <figure>
                  <img src="../assets/images/unsplash_UBeNYvk6ED0.png" />
                </figure>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ins__text">
                <ul>
                  <li>
                    <span className="no__count">1</span>
                    <p>
                      Store your exquisite Youlry pieces in a muslin pouch or
                      handy container before participating in any strenous
                      physical activity.
                    </p>
                  </li>
                  <li>
                    <span className="no__count">2</span>
                    <p>
                      Although your gold youlry isn’t likely to tarnish, its
                      best to use perfume prior to wearing your pieces to keep
                      them looking timeless.
                    </p>
                  </li>
                  <li>
                    <span className="no__count">3</span>
                    <p>
                      To maintain the sheen and brilliance of your favourite
                      Youlry, please avoid all contact with household bleach or
                      cleaning products. This will also make your Youlry live
                      longer.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row two">
            <div className="col-md-6">
              <div className="img__wrp_bx">
                <figure>
                  <img src="../assets/images/unsplash_fo83GD_AARE.png" />
                </figure>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ins__text">
                <ul>
                  <li>
                    <span className="no__count">4</span>
                    <p>
                      Your youlry is made to last. However, to maintain its
                      beautiful shape and structure, remove and store it
                      carefully before going to bed.
                    </p>
                  </li>
                  <li>
                    <span className="no__count">5</span>
                    <p>
                      Make your well-worn Youlry come alive by gently cleaning
                      it after use, giving it an occasional wipe down with a dry
                      , soft cloth.
                      <em>
                        <strong> Try it! Feels therapeutic!</strong>
                      </em>
                    </p>
                  </li>
                  <li>
                    <span className="no__count">6</span>
                    <p>
                      Youlry’s beautiful gem-work can put you in a trance. But
                      before that , clean its more delicate stone with a soft
                      brush and mild soap-water solution.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
