import React, { useEffect, useState } from "react";
const https = require("https");
const PaytmChecksum = require("./paytmChecksum").default;
import { useNavigate } from "react-router-dom";
import "./pay-tm.css";
import { useLocation } from "react-router-dom";

export function PaytmButton() {
  const navigate = useNavigate();

  var OrderId = localStorage.getItem("orderId");
  const cartAmt = localStorage.getItem("cartAmt");

  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [cartTotal, setCartTotal] = useState(cartAmt);
  const [ordersId, setOrdersId] = useState(OrderId);
  const [thankYou, setThankYou] = useState(false);
  const [paymentData, setPaymentData] = useState({
    token: "",
    order: "",
    mid: "",
    amount: "",
  });

  useEffect(() => {
    initialize();
  }, []);

  const initialize = () => {
    let orderId = `${OrderId}_${new Date().getTime()}`;

    let mid = "QQddVg46026853639100";
    let mkey = "v0BOxrSyCFOaNm8i";
    var paytmParams = {};

    paytmParams.body = {
      requestType: "Payment",
      mid: mid,
      websiteName: "WEBSTAGING",
      orderId: orderId,
      callbackUrl: "https://merchant.com/callback",
      txnAmount: {
        value: cartAmt,
        currency: "INR",
      },
      userInfo: {
        custId: "1001",
      },
    };

    PaytmChecksum.generateSignature(
      JSON.stringify(paytmParams.body),
      mkey
    ).then(function (checksum) {
      console.log(checksum);
      paytmParams.head = {
        signature: checksum,
      };

      var post_data = JSON.stringify(paytmParams);

      var options = {
        /* for Staging */
        hostname: "securegw-stage.paytm.in",

        /* for Production */
        // hostname: 'securegw.paytm.in',

        port: 443,
        path: `/theia/api/v1/initiateTransaction?mid=${mid}&orderId=${orderId}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Content-Length": post_data.length,
        },
      };

      var response = "";
      var post_req = https.request(options, function (post_res) {
        post_res.on("data", function (chunk) {
          response += chunk;
        });
        post_res.on("end", function () {
          console.log("Response: ", response);
          // res.json({data: JSON.parse(response), orderId: orderId, mid: mid, amount: amount});
          setPaymentData({
            ...paymentData,
            token: JSON.parse(response).body.txnToken,
            order: orderId,
            mid: mid,
            amount: cartAmt,
          });
        });
      });

      post_req.write(post_data);
      post_req.end();
    });
  };

  const makePayment = () => {
    setLoading(true);
    var config = {
      root: "",
      style: {
        bodyBackgroundColor: "#f8f3f3",
        bodyColor: "",
        themeBackgroundColor: "#0FB8C9",
        themeColor: "#ff3d00",
        headerBackgroundColor: "#f8f3f3",
        headerColor: "#ff3d00",
        errorColor: "",
        successColor: "",
        card: {
          padding: "80%",
          backgroundColor: "",
        },
      },
      data: {
        orderId: paymentData.order,
        token: paymentData.token,
        tokenType: "TXN_TOKEN",
        amount: paymentData.amount /* update amount */,
      },
      payMode: {
        labels: {},
        filter: {
          exclude: [],
        },
        order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
      },
      website: "WEBSTAGING",
      flow: "DEFAULT",
      merchant: {
        mid: paymentData.mid,
        redirect: false,
      },
      handler: {
        transactionStatus: function transactionStatus(paymentStatus) {
          console.log("paymentStatus => ", paymentStatus);
          if (paymentStatus.STATUS == "TXN_SUCCESS") {
            navigate(`/thankyou/${OrderId}`, { replace: true });
            window.location.reload(false);
          }
          setLoading(false);
        },
        notifyMerchant: function notifyMerchant(eventName, data) {
          console.log("Closed");
          setLoading(false);
        },
      },
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      // initialze configuration using init method
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          console.log("Before JS Checkout invoke");
          // after successfully update configuration invoke checkoutjs
          window.Paytm.CheckoutJS.invoke();
        })
        .catch(function onError(error) {
          console.log("Error => ", error);
        });
    }
  };
  return (
    <div>
      {loading ? (
        <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" />
      ) : (
        <button className="btn btn-org-lg" onClick={makePayment}>
          Pay Now
        </button>
      )}
    </div>
  );
}
