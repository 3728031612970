import React, {useState, useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function ReturnPolicy() {

    const [getValue, setGetValue] = useState("");
  
    
    useEffect(() => {
          fetch(
              process.env.REACT_APP_API_URL, 
              {
                  method: "POST",
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin' : '*'
                  },
                  body: JSON.stringify({
                      "token":"OGMwMTc2MWJkZWRmNjUyNTcwMzAxODFmNWYzM2I3ZGM=",
                      "request":"getContentByName",
                      "page":"return-refund-policy-1"
                  })
  
             }).then( (response)  => {
                response.json().then( result => {
                    setGetValue(result.data);
                })
              
             })
           
        }, [])

    return (
        <>
            <Header />
            <section className="common-banner" style={{ backgroundImage: `url("../assets/images/Return-Policy.jpg")` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                        </div>
                    </div>
                </div>
            </section>
            <section className="shipping-section ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="shipping-content-wrap">
                                <div className="shipping-content">  
                                {getValue ? 
                                    <p className="desc-grey"
                                        dangerouslySetInnerHTML={{
                                        __html: getValue.content}} >
                                    </p> 
                                : 
                                    <div className="preloaderBg" id="preloader">
                                        <div className="preloader"></div>
                                        <div className="preloader2"></div>
                                    </div>
                                }                                    
                                </div>                             
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ReturnPolicy;