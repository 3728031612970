import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlogSection from "./BlogSection";
import Newsletter from "./Newsletter";
import HomeVideo from "../components/Marketing/HomeVideo";
import Aos from "aos";
import "aos/dist/aos.css";
import Banner from "../components/Marketing/Banner";

const options = {
  loop: true,
  nav: false,
  dots: true,
  animateOut: "fadeOut",
  animateIn: "fadeIn",
  autoplay: true,
  autoplayTimeout: 10000,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const bestSellerOptions = {
  loop: true,
  margin: 35,
  dots: false,
  nav: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
};

function Home() {
  const [getValue, setGetValue] = useState([]);
  const [selerBest, setSelerBest] = useState([]);
  const [productLoader, setProductLoader] = useState(false);
  const [genSetting, setgenSetting] = useState(null);
  const [instaFeed, setInstaFeed] = useState([]);
  const [getCollection, setGetCollection] = useState([]);
  const [getCollectionState, setGetCollectionState] = useState(false);

  useEffect(() => {
    Aos.init({
      duration: 1100,
      easing: "ease-in-out-back",
      disable: "mobile",
      once: true,
    });
  });

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getCategoryList",
        featured: "Yes",
      }),
    }).then((response) => {
      response.json().then((result) => {
        setGetValue(result.data);
      });
    });
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "BestSellers",
        count: "10",
      }),
    }).then((respo) => {
      respo.json().then((resu) => {
        setSelerBest(resu.data);
        setProductLoader(true);
      });
    });
  }, []);

  //console.log(selerBest);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "InstagramFeeds",
      }),
    }).then((resp) => {
      resp.json().then((res) => {
        //console.log(res);
        setInstaFeed(res.data);
      });
    });
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getSettings",
      }),
    }).then((resp) => {
      resp.json().then((res) => {
        if (res.success === true) {
          setgenSetting(res.data);
        } else {
          setgenSetting(null);
        }
      });
    });
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        request: "getCollectionsList",
      }),
    }).then((resp) => {
      resp.json().then((res) => {
        //console.log(res);
        if (res.success === true) {
          setGetCollection(res.data);
          setGetCollectionState(true);
        } else {
          setGetCollection(null);
          setGetCollectionState(false);
        }
      });
    });
  }, []);

  const enchantGate = getCollection.find((e) => e.Id === "5");
  const newBloom = getCollection.find((e) => e.Id === "1");
  const luminiscent = getCollection.find((e) => e.Id === "3");
  const menCollection = getCollection.find((e) => e.Id === "6");

  //console.log( enchantGate.Desc )

  //console.log(getCollection);

  return (
    <div className="HomePageMain">
      <Header headerSeting={genSetting} />

      <Banner />

      <section className="img-content-wrap ptb-150">
        <div className="img-content">
          <div
            className="img-content-left"
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            {getCollectionState && (
              <img
                src={enchantGate.DescImage}
                alt="img"
                className="img-fluid"
              />
            )}
          </div>
          <div
            className="img-content-right"
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            <h2
              className="heading-black"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              ENCHANTED GATES
            </h2>
            {getCollectionState && (
              <p
                className="title-black ptb-50"
                data-aos="fade-up"
                data-aos-duration="5000"
                dangerouslySetInnerHTML={{ __html: enchantGate.Desc }}
              ></p>
            )}
            <div
              className="btn-wrap "
              data-aos="fade-down"
              data-aos-offset="-500"
              data-aos-mirror="true"
              data-aos-anchor-placement="top-center"
              data-aos-duration="7000"
            >
              <Link to="/collection/enchanted-gates" className="org-bdr-btn">
                EXPLORE
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="bloom-wrap bg-white">
        <div className="container-fluid">
          <div className="row col-reverse-sm">
            <div className="col-md-6 p-0">
              <div className="bloom-content-left">
                <h2
                  className="heading-black"
                  data-aos="fade-down"
                  data-aos-duration="3000"
                >
                  NEW BLOOM
                </h2>
                {getCollectionState && (
                  <p
                    className="title-black ptb-50"
                    data-aos="fade-up"
                    data-aos-duration="5000"
                    dangerouslySetInnerHTML={{ __html: newBloom.Desc }}
                  ></p>
                )}
                <div
                  className="btn-wrap"
                  data-aos="fade-up"
                  data-aos-offset="-500"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                  data-aos-duration="5000"
                >
                  <Link to="/collection/new-bloom" className="org-bdr-btn">
                    EXPLORE
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div
                className="bloom-content-right"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                {getCollectionState && (
                  <img
                    src={newBloom.DescImage}
                    alt="img"
                    className="img-fluid"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="img-content-wrap ptb-150">
        <div className="img-content">
          <div
            className="img-content-left"
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            {getCollectionState && (
              <img
                src={luminiscent.DescImage}
                alt="img"
                className="img-fluid"
              />
            )}
          </div>
          <div
            className="img-content-right"
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            <h2
              className="heading-black"
              data-aos="fade-down"
              data-aos-duration="3000"
            >
              LUMINESCENT
            </h2>
            {getCollectionState && (
              <p
                className="title-black ptb-50"
                data-aos="fade-up"
                data-aos-duration="5000"
                dangerouslySetInnerHTML={{ __html: luminiscent.Desc }}
              ></p>
            )}
            <div
              className="btn-wrap"
              data-aos="fade-up"
              data-aos-offset="-500"
              data-aos-mirror="true"
              data-aos-anchor-placement="top-center"
              data-aos-duration="5000"
            >
              <Link to="/collection/luminescent" className="org-bdr-btn">
                EXPLORE
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="seller-wrap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 p-0">
              <div className="collection-content text-center pb-60">
                <h2 className="heading-black">BEST SELLERS</h2>
              </div>

              {!productLoader ? (
                <div className="preloaderBg" id="preloader">
                  <div className="preloader"></div>
                  <div className="preloader2"></div>
                </div>
              ) : (
                <div className="seller-slider best__seller__container">
                  <OwlCarousel
                    className="owl-carousel owl-theme seller-carousel"
                    {...bestSellerOptions}
                  >
                    {selerBest
                      ? selerBest.map((bestItem) => {
                          return (
                            <div
                              className="item"
                              data-aos="fade-up"
                              data-aos-offset="-300"
                              data-aos-mirror="true"
                              data-aos-anchor-placement="top-center"
                              data-aos-duration="13000"
                              key={bestItem.Id}
                            >
                              <div className="item-img">
                                <div className="flip-container">
                                  <div className="flipper">
                                    <div className="front">
                                      <Link
                                        to={`/product/${bestItem.Id}`}
                                        className="product-link"
                                      >
                                        <figure>
                                          {" "}
                                          <img
                                            src={
                                              bestItem.ProductGallery[0]
                                                .img_medium
                                            }
                                            alt="img"
                                          />
                                        </figure>
                                      </Link>
                                    </div>
                                    <div className="back">
                                      <Link
                                        to={`/product/${bestItem.Id}`}
                                        className="product-link"
                                      >
                                        <figure>
                                          <img
                                            src={
                                              bestItem.ProductGallery[1]
                                                .img_medium
                                            }
                                            alt="img"
                                          />
                                        </figure>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <Link to="/login" className="whislist-img">
                                  <span className="material-icons-outlined wishlist-grey">
                                    favorite
                                  </span>
                                </Link>
                              </div>
                              <div className="slider-content">
                                <h2 className="item-title">
                                  <Link
                                    to={`/product/${bestItem.Id}`}
                                    className="product-link"
                                  >
                                    {" "}
                                    {bestItem.Name}{" "}
                                  </Link>
                                </h2>
                                <h3 className="item-price">
                                  ₹{bestItem.Price}
                                </h3>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </OwlCarousel>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section
        className="design-wrap"
        style={{
          backgroundImage: `url("../assets/images/design-bg.png")`,
          marginTop: `150px`,
        }}
      >
        <div className="design-content">
          <h2
            className="heading-white"
            data-aos="fade-down"
            data-aos-offset="-500"
            data-aos-mirror="true"
            data-aos-anchor-placement="top-center"
            data-aos-duration="5000"
          >
            CONTEMPORARY DESIGNS
          </h2>
          <p
            className="desc-white ptb-50"
            data-aos="fade-down"
            data-aos-offset="-400"
            data-aos-mirror="true"
            data-aos-anchor-placement="top-center"
            data-aos-duration="7000"
          >
            Our collections are inspired by you. We
            <br />
            listen closely and watch keenly. What you
            <br />
            love. And then bring it to life. With expertise
            <br />
            and love
          </p>
          <div
            className="btn-wrap"
            data-aos="fade-down"
            data-aos-offset="-400"
            data-aos-mirror="true"
            data-aos-anchor-placement="top-center"
            data-aos-duration="11000"
          >
            <a href="/" className="white-trans-btn">
              DISCOVER
            </a>
          </div>
        </div>
      </section>

      <section className="category-wrap  ptb-150">
        <div className="container">
          <div className="row align-items-end justify-content-center category-wrap-row">
            {getValue.map((item, i) => (
              <div className="col-6 col-md-4" key={i}>
                <div
                  className={`category-img ${i === 0 ? "pb-60" : ""} ${
                    i === 4 ? "pb-60" : ""
                  }`}
                  data-aos="fade-down"
                  data-aos-offset="-500"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                  data-aos-duration="6000"
                >
                  <figure>
                    <Link
                      to={`/category/${item.Slug}`}
                      className="product-link"
                    >
                      <img
                        src={item.CatImage}
                        className="img-fluid"
                        alt={item.CatName}
                      />
                    </Link>
                  </figure>
                  <h2 className="category-name">
                    <Link
                      to={`/category/${item.Slug}`}
                      className="product-link"
                    >
                      {item.CatName}
                    </Link>
                  </h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="collection-wrap ptb-150 pb-0 bg-grey pt-0">
        <div className="container down-botom_positopn">
          <div
            className="row justify-content-center"
            data-aos="fade-down"
            data-aos-offset="-300"
            data-aos-mirror="true"
            data-aos-anchor-placement="top-center"
            data-aos-duration="13000"
          >
            <div className="col-md-10 p-0">
              <div className="collection-content text-center">
                <h2 className="heading-black">YOULRY 2021 COLLECTION</h2>
                <p className="title-black ptb-30">
                  We create fine, head-turning jewellery in pure gold and
                  precious stones.
                </p>
              </div>
              <div className="yourlyMainVideoRow">
                <div id="playvideo" className="collection-video"></div>
              </div>
            </div>
          </div>
          <div className="row video-thumb">
            <HomeVideo />
          </div>
        </div>
      </section>

      <section className="post-wrap ptb-150 pb-0 ">
        <div className="container margin-top_90">
          <BlogSection />
        </div>
      </section>

      <section className="insta-wrap ptb-150 pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 p-0">
              <div
                className="insta-content text-center"
                data-aos="fade-up"
                data-aos-offset="-300"
                data-aos-mirror="true"
                data-aos-anchor-placement="top-center"
                data-aos-duration="13000"
              >
                <h2 className="heading-black">OUR INSTAGRAM</h2>
              </div>
              <div className="insta-img-grid">
                <div className="grid-wrapper">
                  <div className="hasttag ptb-70-30">
                    <h2
                      className="red-title "
                      data-aos="fade-down"
                      data-aos-offset="-500"
                      data-aos-mirror="true"
                      data-aos-anchor-placement="top-center"
                      data-aos-duration="13000"
                    >
                      <span>#</span> Youlry
                    </h2>
                  </div>
                  <div
                    className="grid-container"
                    data-toggle="modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    data-target="#instaModal"
                  >
                    {instaFeed
                      ? instaFeed.slice(0, 8).map((feeds, index) => {
                          return (
                            <div
                              className="grid-item"
                              data-target="#carouselExample"
                              data-slide-to={index}
                              key={index}
                            >
                              <img src={feeds.media_url} alt="" />
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter socialSeting={genSetting} />

      <Footer />

      <div
        className="modal fade"
        id="instaModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="reviewModalTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg insta-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <a
                className="insta-prev right carousel-control"
                href="#carouselExample"
                role="button"
                data-slide="next"
              ></a>
              <a
                className="insta-next left carousel-control"
                href="#carouselExample"
                role="button"
                data-slide="prev"
              ></a>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div
                id="carouselExample"
                className="carousel slide"
                data-ride="carousel"
                data-interval="false"
              >
                <div className="carousel-inner">
                  {instaFeed.slice(0, 8).map((feeds, index) => {
                    return (
                      <div
                        className={`carousel-item ${
                          index === 0 ? "active" : ""
                        }`}
                        key={index}
                      >
                        <div className="row">
                          <div className="col-md-5">
                            <a
                              alt="item0"
                              href={feeds.permalink}
                              target="_blank"
                            >
                              <img src={feeds.media_url} />
                            </a>
                          </div>
                          <div className="col-md-7">
                            <div className="insta-caption">
                              <p>{feeds.caption}</p>
                            </div>
                            <i className="fa fa-instagram"></i>&nbsp;&nbsp;
                            <a
                              alt="item0"
                              href={feeds.permalink}
                              target="_blank"
                            >
                              YOULRY // INSTAGRAM // {feeds.post_date}
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
